import { useEffect, ReactNode, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { media, zIndex } from 'app/styles/variables';

export const BODY_OVERLAY_CLASS = `overlay-${Math.random()
  .toString(36)
  .substr(7)}`;

export const BODY_OVERLAY_LOCK_CLASS = 'overlay-lock';

interface OverlayStProps {
  mode: string;
  isShown: boolean;
  isTransparent: boolean;
  onClick: () => void;
  onTouchEnd: () => void;
  ref: any;
}

export const OverlaySt = styled.div.attrs(
  ({ isShown }: { isShown: boolean }) => ({
    role: 'overlay',
    hidden: !isShown,
  }),
)<OverlayStProps>`
  position: fixed;
  z-index: ${({ mode }) => (mode === 'sidebar' ? 999 : zIndex.overlay)};

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: ${({ isShown, mode }) =>
    isShown || mode === 'sidebar' ? 'block' : 'none'};
  background-color: ${props =>
    `rgba(0, 0, 0, ${props.isTransparent ? '0' : '0.8'})`};

  ${media.lg(
    css`
      display: ${({ isShown }: { isShown: boolean }) =>
        isShown ? 'block' : 'none'};
    `,
  )};
`;

const updateBodyClass = (show, shouldLock = false) => {
  if (!CLIENT) return;
  const bodyClasses = document.body.classList;

  if (show) {
    const classList = shouldLock
      ? [BODY_OVERLAY_CLASS, BODY_OVERLAY_LOCK_CLASS]
      : [BODY_OVERLAY_CLASS];
    bodyClasses.add(...classList);
  } else {
    bodyClasses.remove(BODY_OVERLAY_CLASS, BODY_OVERLAY_LOCK_CLASS);
  }
};

const handleUnmount = () => {
  updateBodyClass(false);
};

interface Props {
  children?: ReactNode;
  className?: string;
  isTransparent?: boolean;
  show?: boolean;
  onClose?: () => void;
  mode?: string;
}

const Overlay = forwardRef((props: Props, ref) => {
  const { children, className, show, onClose, isTransparent, mode } = props;
  const shouldLock = show && !isTransparent;

  useEffect(() => {
    updateBodyClass(show, shouldLock);
    return handleUnmount;
  }, [show, isTransparent]);

  return (
    <OverlaySt
      ref={ref}
      className={className}
      isShown={show}
      mode={mode}
      isTransparent={isTransparent}
      onClick={onClose}
      onTouchEnd={onClose}
    >
      {children}
    </OverlaySt>
  );
});

export { Overlay };
