import React, { FC } from 'react';
import {
  DesktopTypographySt,
  MobileTypographySt,
  TypographySt,
} from 'app/shared/components/Typography/Typography.css';

export type Variant =
  | 'display1'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subheading1'
  | 'subheading2'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'body4'
  | 'caption1'
  | 'caption2'
  | 'caption3'
  | 'caption4'
  | 'caption5'
  | 'overline1'
  | 'overline2';

interface ITypographyProps {
  className?: string;
  variant?: Variant;
  dataTestId?: string;
  Component?: React.ElementType;
  variantDesktop?: Variant;
  variantMobile?: Variant;
  isBranded?: boolean;
}

const Typography: FC<ITypographyProps> = ({
  children,
  variant,
  variantDesktop: defaultVariantDesktop,
  variantMobile: defaultVariantMobile,
  className,
  dataTestId,
  Component,
  isBranded,
}) => {
  const props = { className, dataTestId, isBranded, Component };

  if (defaultVariantDesktop || defaultVariantMobile) {
    const variantDesktop = defaultVariantDesktop || variant;
    const variantMobile = defaultVariantMobile || variant;
    return (
      <>
        <DesktopTypographySt variant={variantDesktop} {...props}>
          {children}
        </DesktopTypographySt>
        <MobileTypographySt variant={variantMobile} {...props}>
          {children}
        </MobileTypographySt>
      </>
    );
  }

  return (
    <TypographySt variant={variant} {...props}>
      {children}
    </TypographySt>
  );
};

export { Typography };
