import {
  ANALYTICS_ID,
  API_URL,
  AUTH_PASS,
  AUTH_USER,
  BASE_URL,
  CDN_URL,
  COMMON_API_URL,
  IAM_URL,
  IMAGE_PROXY_URL,
  KENTICO_HCC_LOCALE,
  KENTICO_HCC_PROJECT_ID,
  KENTICO_OTP_LOCALE,
  KENTICO_OTP_PROJECT_ID,
  MFE_INSURANCE_HUB_URL,
  MOST_RECENT_DAYS,
  PHRASE_LOCALE_ID_EN,
  PHRASE_LOCALE_ID_ES,
  PHRASE_WEB_APP_PROJECT_ID,
  RECAPTCHA_SITE_TOKEN,
  SENTRY_DSN,
} from './app/config';

const translationsConfig = {
  localDraftCacheDuration: 10,
  localCacheDuration: 300,
  localeCookieName: 'hey-locale',
  defaultLocale: 'es',
  supportedLanguages: ['es', 'en'],
};

const sentry = {
  url: SENTRY_DSN,
};

const auth = {
  user: AUTH_USER,
  pass: AUTH_PASS,
};

const clientData = {
  apiUrl: API_URL,
  commonApiUrl: COMMON_API_URL,
  baseUrl: BASE_URL,
  cdnUrl: CDN_URL,
  imageProxyUrl: IMAGE_PROXY_URL,
  mfeInsuranceHubUrl: MFE_INSURANCE_HUB_URL,
  recaptchaToken: RECAPTCHA_SITE_TOKEN,
  analyticsId: ANALYTICS_ID,
  iamUrl: IAM_URL,
  kenticoHCCProjectId: KENTICO_HCC_PROJECT_ID,
  kenticoHCCLocale: KENTICO_HCC_LOCALE,
  kenticoOTPProjectId: KENTICO_OTP_PROJECT_ID,
  kenticoOTPLocale: KENTICO_OTP_LOCALE,
  phraseLocaleIdEs: PHRASE_LOCALE_ID_ES,
  phraseLocaleIdEn: PHRASE_LOCALE_ID_EN,
  phraseProjectId: PHRASE_WEB_APP_PROJECT_ID,
  mostRecentDays: MOST_RECENT_DAYS,
};

export { translationsConfig, sentry, auth, clientData };
