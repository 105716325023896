import {
  createContext,
  useContext,
  ReactNode,
  ReactElement,
  useState,
  useEffect,
} from 'react';
import { useQuery } from 'react-query';
import { get } from 'js-cookie';
import isEqual from 'lodash.isequal';
import flagrDataQuery from 'app/providers/FeatureToggleProvider/FeatureToggleProvider.dataQuery';
import { guidCookieName } from 'next-app/server/middleware/guid';
import { EvaluatedFlagDataMap } from 'app/apiCalls/flagr';
import { useRouter } from 'next/router';
import {
  getClearedFlagEntriesFromQuery,
  getForcedFlagEntriesFromQuery,
} from './utils';
import { useCookieFlagEntries } from 'app/providers/FeatureToggleProvider/hooks/useCookieFlagEntries/useCookieFlagEntries';

export type ForcedFlagMap = Record<number, string>;

interface ContextType {
  staticFeatures?: Record<string, Record<string, string | number | boolean>>;
  flagMap?: EvaluatedFlagDataMap;
  forcedFlagMap?: ForcedFlagMap;
}

export const FeatureToggleContext = createContext<ContextType>({});

const staticFeatures = {
  financingCalculator: {
    //@todo these toggles should really be removed and be returned from the API!
    enableMockDisclaimers: true,
  },
};

interface FeatureToggleProviderProps extends ContextType {
  children?: ReactNode;
}

const FeatureToggleProvider = ({
  children,
}: FeatureToggleProviderProps): ReactElement => {
  const [userId, setUserId] = useState();
  const { query } = useRouter();
  const [forcedFlagData, setForcedFlagData] = useState<ForcedFlagMap>({});
  const { cookieFlagEntries, updateCookieFlagEntries } = useCookieFlagEntries();

  useEffect(() => {
    const guid = get(guidCookieName);
    setUserId(guid);
  }, []);

  useEffect(() => {
    // Get the forced entries from the query object
    const forcedFlagEntries = getForcedFlagEntriesFromQuery(query);
    const clearedFlagEntries = getClearedFlagEntriesFromQuery(query);

    // Combine existing data, cookie data and new query params data
    const updatedEntries = {
      ...forcedFlagData,
      ...cookieFlagEntries,
      ...forcedFlagEntries,
    };

    // Clean up the cleared entries from the cookie and app
    clearedFlagEntries.forEach(flagId => {
      delete updatedEntries[flagId];
    });

    // updated stored data if changed
    if (!isEqual(updatedEntries, forcedFlagData)) {
      setForcedFlagData(updatedEntries);
    }

    // update cookieData if changed
    if (!isEqual(updatedEntries, cookieFlagEntries)) {
      updateCookieFlagEntries(updatedEntries);
    }
  }, [cookieFlagEntries, query]);

  const { data } = useQuery(
    flagrDataQuery.name,
    () => flagrDataQuery.fn({ userId }),
    flagrDataQuery.options,
  );
  return (
    <FeatureToggleContext.Provider
      value={{
        flagMap: data || {},
        staticFeatures,
        forcedFlagMap: forcedFlagData,
      }}
    >
      {children}
    </FeatureToggleContext.Provider>
  );
};

const useFeatureToggle = (): ContextType => useContext(FeatureToggleContext);

export { FeatureToggleProvider, useFeatureToggle };
