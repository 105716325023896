import { DetectedEnvs } from './detectEnv';
import { getEnv } from './utils';

const ENV_TEST = 'es-test';
const ENV_DEV = 'es-dev';
const ENV_PR_STAGING = 'es-pr';
const ENV_STAGING = 'es-staging';
const ENV_PRODUCTION = 'es-prod';

const ENVIRONMENT = getEnv('ENVIRONMENT') as DetectedEnvs;

// BASE_URL for the e2e tests and for SSR for the PR environments
const DEV_BASE_URL = 'http://localhost:3000';

const PROD_BASE_URL = 'https://heycar.com';
const PROD_API_URL = 'https://api.heycar.com';
const PROD_COMMON_API_URL = 'https://api.common.prod.group-mobility-trader.com';
const PROD_CDN_URL = 'https://cdn.heycar.com';
const PROD_IMAGE_PROXY_URL =
  'https://img.common.prod.group-mobility-trader.com';
const PROD_IAM_URL = 'https://iam.common.prod.group-mobility-trader.com';

const STAGING_BASE_URL = 'https://staging.heycar.com';
const STAGING_API_URL = 'https://api.staging.heycar.com';
const STAGING_COMMON_API_URL =
  'https://api.common.staging.group-mobility-trader.de';
const STAGING_CDN_URL = 'https://cdn.staging.heycar.com';
const STAGING_IMAGE_PROXY_URL =
  'https://img.common.staging.group-mobility-trader.de';
const SEARCH_API_ENDPOINT = '/search';
const DEFAULT_LOCALE = 'es-ES';

const MFE_INSURANCE_HUB_URL = getEnv('MFE_INSURANCE_HUB_URL');
const STAGING_IAM_URL = 'https://iam.common.staging.group-mobility-trader.de';

const KENTICO_OTP_PROJECT_ID = getEnv('KENTICO_OTP_PROJECT_ID');
const KENTICO_OTP_LOCALE = getEnv('KENTICO_OTP_LOCALE');

const KENTICO_HCC_PROJECT_ID = getEnv('KENTICO_HCC_PROJECT_ID');
const KENTICO_HCC_LOCALE = getEnv('KENTICO_HCC_LOCALE');

const PHRASE_LOCALE_ID_ES = getEnv('PHRASE_LOCALE_ID_ES');
const PHRASE_LOCALE_ID_EN = getEnv('PHRASE_LOCALE_ID_EN');
const PHRASE_WEB_APP_PROJECT_ID = getEnv('PHRASE_WEB_APP_PROJECT_ID');
const MOST_RECENT_DAYS = getEnv('MOST_RECENT_DAYS');

const BASE_URL =
  ENVIRONMENT === ENV_TEST ? DEV_BASE_URL : getEnv('BASE_URL') || PROD_BASE_URL;
const API_URL =
  ENVIRONMENT === ENV_TEST
    ? STAGING_API_URL
    : getEnv('API_URL') || PROD_API_URL;
const COMMON_API_URL =
  ENVIRONMENT === ENV_TEST
    ? STAGING_COMMON_API_URL
    : getEnv('COMMON_API_URL') || PROD_COMMON_API_URL;
const CDN_URL =
  ENVIRONMENT === ENV_TEST
    ? STAGING_CDN_URL
    : getEnv('CDN_URL') || PROD_CDN_URL;
const IMAGE_PROXY_URL =
  ENVIRONMENT === ENV_TEST
    ? STAGING_IMAGE_PROXY_URL
    : getEnv('IMAGE_PROXY_URL') || PROD_IMAGE_PROXY_URL;
const IAM_URL =
  ENVIRONMENT === ENV_TEST
    ? STAGING_IAM_URL
    : getEnv('IAM_URL') || PROD_IAM_URL;

const TRACKING_PREFIX = 'es.';

const D2C_DATASOURCE = 'VGED';
const DEALER_ECOMMERCE_DATASOURCE = 'DEALER_COMMERCE';

const CLASSIFIED_DATASOURCE = 'TSP';

const SENTRY_DSN = getEnv('SENTRY_DSN') || '';
const AUTH_USER = getEnv('AUTH_USER') || null;
const AUTH_PASS = getEnv('AUTH_PASS') || null;
const RECAPTCHA_SITE_TOKEN =
  getEnv('RECAPTCHA_SITE_TOKEN') || '6Ld2WpYaAAAAAG6ltxbwpiEnQaKkNuJfNFmMrlhN';
const PAGINATION_SIZE = 18;

// All numbers below are in days
const COOKIE_PREFERENCE_EXPIRES = 30; // for storing the user's cookie preferences
const FAVORITES_EXPIRES = 30; // for storing the user's favourite cars preferences
const GUID_EXPIRES = 30; // for storing the user's tracking ID

// Facebook content
const FB_CONTENT = '9sco0klh2qzqlb2edbm0rz9t1839ys';

const ANALYTICS_ID =
  ENVIRONMENT === ENV_PRODUCTION
    ? 'ckRGOYC5Y1LGIYtpGSCCJYJDHzkyMkil'
    : 'FyqRWq0VCjaDNaO2wa6TCdqvM2cEhQxA';

export {
  ENV_DEV,
  ENV_PRODUCTION,
  ENV_PR_STAGING,
  ENV_STAGING,
  ENV_TEST,
  ENVIRONMENT,
  PROD_BASE_URL,
  STAGING_BASE_URL,
  DEV_BASE_URL,
  BASE_URL,
  API_URL,
  COMMON_API_URL,
  CDN_URL,
  IMAGE_PROXY_URL,
  IAM_URL,
  TRACKING_PREFIX,
  SENTRY_DSN,
  AUTH_USER,
  AUTH_PASS,
  PAGINATION_SIZE,
  COOKIE_PREFERENCE_EXPIRES,
  FAVORITES_EXPIRES,
  GUID_EXPIRES,
  RECAPTCHA_SITE_TOKEN,
  D2C_DATASOURCE,
  DEALER_ECOMMERCE_DATASOURCE,
  CLASSIFIED_DATASOURCE,
  FB_CONTENT,
  ANALYTICS_ID,
  MFE_INSURANCE_HUB_URL,
  SEARCH_API_ENDPOINT,
  DEFAULT_LOCALE,
  KENTICO_OTP_PROJECT_ID,
  KENTICO_OTP_LOCALE,
  KENTICO_HCC_PROJECT_ID,
  KENTICO_HCC_LOCALE,
  PHRASE_LOCALE_ID_ES,
  PHRASE_LOCALE_ID_EN,
  PHRASE_WEB_APP_PROJECT_ID,
  MOST_RECENT_DAYS,
};
