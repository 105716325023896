import { createContext, ReactElement, ReactNode, useContext } from 'react';
import {
  PageId,
  usePageId,
} from 'app/shared/components/PageWrapper/providers/PageProvider/hooks/usePageId/usePageId';

interface IPageContext {
  pageId?: PageId | string;
}

export const PageContext = createContext<IPageContext>({
  pageId: PageId.UNKNOWN,
});

interface IPageProviderProps {
  children?: ReactNode;
  defaultPageId?: string;
}

const PageProvider = ({
  children,
  defaultPageId,
}: IPageProviderProps): ReactElement => {
  const { pageId } = usePageId({ defaultPageId });
  return (
    <PageContext.Provider value={{ pageId }}>{children}</PageContext.Provider>
  );
};

const usePageContext = (): IPageContext => useContext(PageContext);

export { PageProvider, usePageContext };
