import {
  DEV_BASE_URL,
  ENV_DEV,
  ENV_PRODUCTION,
  ENV_PR_STAGING,
  ENV_STAGING,
  ENV_TEST,
  PROD_BASE_URL,
  STAGING_BASE_URL,
} from './config';
import { getEnv } from './utils';

export const detectedEnvs = [
  ENV_DEV,
  ENV_PRODUCTION,
  ENV_PR_STAGING,
  ENV_STAGING,
  ENV_TEST,
] as const;

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;

export type DetectedEnvs = ElementType<typeof detectedEnvs>;

const PR_STAGING_REGEXP =
  /https:\/\/(?:.*:.*@)?es-web-app-\d{0,5}.es.staging.group-mobility-trader.de/;

export const detectEnv = (): DetectedEnvs => {
  // Check if dotEnv has valid value
  const dotEnvValue = detectedEnvs.find(item => item === getEnv('ENVIRONMENT'));
  const dotEnvBaseUrl = getEnv('BASE_URL');
  if (dotEnvValue) {
    return dotEnvValue;
  }

  // Fallback to BASE_URL to identify env
  if (dotEnvBaseUrl === PROD_BASE_URL) {
    return ENV_PRODUCTION;
  } else if (dotEnvBaseUrl === STAGING_BASE_URL) {
    return ENV_STAGING;
  } else if (PR_STAGING_REGEXP.test(dotEnvBaseUrl)) {
    return ENV_PR_STAGING;
  } else if (dotEnvBaseUrl === DEV_BASE_URL) {
    return ENV_DEV;
  }

  // No valid BASE_URL or dotEnv found
  return null;
};
