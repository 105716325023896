import axios from 'axios';
import {
  COMMON_API_URL,
  PHRASE_LOCALE_ID_ES,
  PHRASE_LOCALE_ID_EN,
  PHRASE_WEB_APP_PROJECT_ID,
} from 'next-app/app/config';

export interface LocaleIntlData {
  locale?: string;
  messages?: Record<string, string>;
}

export interface ClientData {
  apiUrl?: string;
  commonApiUrl?: string;
  baseUrl?: string;
  cdnUrl?: string;
  imageProxyUrl?: string;
  recaptchaToken?: string;
  analyticsId?: string;
  iamUrl?: string;
  mfeInsuranceHubUrl?: string;
  kenticoHCCProjectId?: string;
  kenticoHCCLocale?: string;
  kenticoOTPProjectId?: string;
  kenticoOTPLocale?: string;
  phraseLocaleIdEs?: string;
  phraseLocaleIdEn?: string;
  phraseProjectId?: string;
  mostRecentDays?: string;
}

export interface SentryData {
  url?: string;
  version?: string;
}

interface IGetIntlLocaleContentProps {
  locale: string;
}

interface IGetIntlContentResult {
  intl: LocaleIntlData;
}

const locales = {
  es: {
    locale: PHRASE_LOCALE_ID_ES,
  },
  en: {
    locale: PHRASE_LOCALE_ID_EN,
  },
};

export const getIntlContent = ({
  locale,
}: IGetIntlLocaleContentProps): Promise<IGetIntlContentResult> =>
  axios
    .get(
      `${COMMON_API_URL}/web-content/phrase/${PHRASE_WEB_APP_PROJECT_ID}/${locales[locale].locale}/translations`,
    )
    .then(({ data }) => ({
      intl: {
        locale,
        messages: data,
      },
    }));
