import { ParsedUrlQuery } from 'querystring';
import { ForcedFlagMap } from './FeatureToggleProvider';

const FORCE_FLAG_QUERY_PARAM_REGEX = /^forceFlagID(\d{1,4})$/;
const CLEAR_FLAG_QUERY_PARAM_REGEX = /^clearFlagID(\d{1,4})$/;

/**
 * Here we make sure that we extract the `forceFlagID{0-9999}` parameter values from the
 * query, so we can inject them into the app and store them in a cookie.
 */
export const getForcedFlagEntriesFromQuery = (
  query: ParsedUrlQuery,
): ForcedFlagMap => {
  return Object.keys(query || {}).reduce((acc, item) => {
    const matches = item.match(FORCE_FLAG_QUERY_PARAM_REGEX);
    if (matches) {
      const [, flagID] = matches;
      acc[flagID] = query[item];
    }

    return acc;
  }, {});
};

/**
 * Here we make sure that we extract the `clearFlagID{0-9999}` parameter values from the
 * query, so we can clear them from the cookies and app.
 */
export const getClearedFlagEntriesFromQuery = (
  query: ParsedUrlQuery,
): number[] => {
  return Object.keys(query || {}).reduce((acc, item) => {
    const clearMatches = item.match(CLEAR_FLAG_QUERY_PARAM_REGEX);
    if (clearMatches) {
      const [, flagID] = clearMatches;
      acc.push(parseInt(flagID, 10));
    }

    return acc;
  }, []);
};
