import {
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback,
  ReactNode,
  ReactElement,
} from 'react';
import { get, set, remove } from 'js-cookie';
import { COOKIE_PREFERENCE_EXPIRES } from 'next-app/app/config';

interface CookieSettingsContextProps {
  essential: boolean;
  hasSavedCookieSettings: boolean;
  saveCookieSettings: (newValue: boolean) => void;
  personal: boolean;
  setPersonal: (newValue: boolean) => void;
  marketing: boolean;
  setMarketing: (newValue: boolean) => void;
  agreeToAllCookies: () => void;
  hasSeenCookieConsent: boolean;
  setHasSeenCookieConsent: (newValue: boolean) => void;
  agreeOnlyPersonalCookies: () => void;
  openCookieComplaintBanner: boolean;
  setOpenCookieComplaintBanner: (newValue: boolean) => void;
}

const CookiesSettingsContext = createContext<CookieSettingsContextProps>({
  essential: true,
  hasSavedCookieSettings: false,
  saveCookieSettings: () => {},
  personal: false,
  setPersonal: () => {},
  marketing: false,
  setMarketing: () => {},
  agreeToAllCookies: () => {},
  hasSeenCookieConsent: false,
  setHasSeenCookieConsent: () => {},
  agreeOnlyPersonalCookies: () => {},
  openCookieComplaintBanner: false,
  setOpenCookieComplaintBanner: () => {},
});

export const SettingsType = {
  essential: 'essential',
  personal: 'personal',
  marketing: 'marketing',
};

const CookiesSettingsProvider = ({
  children,
  defaultOpenCookieComplaintBanner = false,
}: {
  children: ReactNode;
  defaultOpenCookieComplaintBanner?: boolean;
}): ReactElement => {
  const essential = true;
  const [hasSavedCookieSettings, saveCookieSettings] = useState(false);
  const [personal, setPersonal] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [hasSeenCookieConsent, setHasSeenCookieConsent] = useState(false);
  const [openCookieComplaintBanner, setOpenCookieComplaintBanner] = useState(
    defaultOpenCookieComplaintBanner,
  );

  useEffect(() => {
    saveCookieSettings(get('hasSavedCookieSettings') === 'true');
    setPersonal(get(SettingsType.personal) === 'true');
    setMarketing(get(SettingsType.marketing) === 'true');

    if (get(SettingsType.personal) === 'false') {
      remove('personalInfo');
      remove('favoriteList');
      remove('d2c_PersonalInfo');
      remove('lastViewed');
    }
  }, []);

  useEffect(() => {
    set(SettingsType.personal, personal, {
      expires: COOKIE_PREFERENCE_EXPIRES,
    });

    set(SettingsType.marketing, marketing, {
      expires: COOKIE_PREFERENCE_EXPIRES,
    });

    set('hasSavedCookieSettings', hasSavedCookieSettings, {
      expires: COOKIE_PREFERENCE_EXPIRES,
    });
  }, [hasSavedCookieSettings, personal, marketing]);

  const agreeToAllCookies = useCallback(() => {
    setPersonal(true);
    setMarketing(true);
    saveCookieSettings(true);
    setOpenCookieComplaintBanner(false);
  }, []);

  const agreeOnlyPersonalCookies = useCallback(() => {
    setPersonal(true);
    setOpenCookieComplaintBanner(false);
    saveCookieSettings(true);
  }, []);

  return (
    <CookiesSettingsContext.Provider
      value={{
        hasSavedCookieSettings,
        personal,
        marketing,
        saveCookieSettings,
        agreeToAllCookies,
        setPersonal,
        setMarketing,
        essential,
        hasSeenCookieConsent,
        setHasSeenCookieConsent,
        agreeOnlyPersonalCookies,
        openCookieComplaintBanner,
        setOpenCookieComplaintBanner,
      }}
    >
      {children}
    </CookiesSettingsContext.Provider>
  );
};

const useCookiesSettings = () => useContext(CookiesSettingsContext);

export { CookiesSettingsProvider, useCookiesSettings };
