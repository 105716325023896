import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'app/shared/components/Link/Link';
import { BASE_ROUTES, useUrl } from 'app/hooks/useUrl/useUrl';
import { useCookiesSettings } from 'app/providers/CookiesSettingsProvider';
import Translation from 'app/shared/components/Translation/Translation';
import {
  BottomBarSt,
  ConsentButtonSt,
  ContentSt,
  CookieBannerWrapperSt,
  CustomizeSettingsLinkSt,
  ViewMoreLinkSt,
} from './CookieBanner.css';
import { Modal } from 'app/shared/components/Modal/Modal';

const CookieBanner = (): ReactElement => {
  const router = useRouter();
  const { hasSavedCookieSettings, agreeToAllCookies, hasSeenCookieConsent } =
    useCookiesSettings();
  const [isVisibleOverlay, toggleOverlay] = useState(false);

  const dashboardUrl = useUrl('cookiedashboard');
  const cookiePolicyUrl = useUrl('cookiepolicy');

  const shouldShow = useMemo(() => {
    const isOnCookiePolicyPage = router.asPath.includes(
      BASE_ROUTES.cookiepolicy,
    );
    return (
      !isOnCookiePolicyPage && !hasSeenCookieConsent && !hasSavedCookieSettings
    );
  }, [router, hasSeenCookieConsent, hasSavedCookieSettings]);

  useEffect(() => {
    toggleOverlay(!hasSavedCookieSettings);
  }, [hasSavedCookieSettings]);

  const handleAgreeClick = useCallback(() => {
    agreeToAllCookies();
  }, []);

  if (!shouldShow) {
    return null;
  }

  return (
    <Modal show={isVisibleOverlay} direction="bottom" zIndex={999999}>
      <CookieBannerWrapperSt>
        <ContentSt>
          <Translation
            id="containers.cookieDashboard.cookieBanner.body"
            values={{
              link: (
                <Link href={cookiePolicyUrl}>
                  <Translation id="containers.cookieDashboard.cookieBanner.cookiePolicyLink" />
                </Link>
              ),
            }}
          />
        </ContentSt>
        <BottomBarSt>
          <ConsentButtonSt qaID="accept-cookies-btn" onClick={handleAgreeClick}>
            <Translation id="containers.cookieDashboard.cookieBanner.accept" />
          </ConsentButtonSt>
          <ViewMoreLinkSt href={dashboardUrl} qaID="view-dashboard-btn">
            <CustomizeSettingsLinkSt data-qa="customize-settings-link">
              <Translation id="containers.cookieDashboard.cookieBanner.customizeSettingsLink" />
            </CustomizeSettingsLinkSt>
          </ViewMoreLinkSt>
        </BottomBarSt>
      </CookieBannerWrapperSt>
    </Modal>
  );
};

export { CookieBanner };
