import { RefObject, useEffect, useState } from 'react';
import { useOnScreen } from './useOnScreen';

export function useLazy(
  ref: RefObject<Element>,
  callback?: () => void,
  rootMargin?: string,
  rootRef?: RefObject<Element>,
): boolean {
  const [loaded, setLoaded] = useState(false);

  const isOnScreenViewport = useOnScreen(ref, rootMargin, false, null);

  let isOnRootViewport = true;
  if (rootRef) {
    isOnRootViewport = useOnScreen(ref, rootMargin, false, rootRef);
  }

  // For older browsers without IO, we will disable lazy loading
  // and set loaded to true immediately
  useEffect(() => {
    const ioAvailable = 'IntersectionObserver' in window;
    if (!ioAvailable) {
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isOnScreenViewport && isOnRootViewport) {
      setLoaded(true);
    }
  }, [isOnRootViewport, isOnScreenViewport]);

  useEffect(() => {
    if (loaded && typeof callback === 'function') {
      callback();
    }
  }, [loaded]);

  return loaded;
}
