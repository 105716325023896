export const colors = {
  primary: 'var(--color-mica-blue)',

  primary50: 'var(--color-primary-50)',
  primary100: 'var(--color-primary-100)',
  primary200: 'var(--color-primary-200)',
  primary300: 'var(--color-primary-300)',
  primary400: 'var(--color-primary-400)',
  primary500: 'var(--color-primary-500)',
  primary600: 'var(--color-primary-600)',
  primary700: 'var(--color-primary-700)',
  primary800: 'var(--color-primary-800)',
  primary900: 'var(--color-primary-900)',

  secondary: 'var(--color-heycar-mint)',

  secondary50: 'var(--color-secondary-50)',
  secondary100: 'var(--color-secondary-100)',
  secondary200: 'var(--color-secondary-200)',
  secondary300: 'var(--color-secondary-300)',
  secondary400: 'var(--color-secondary-400)',
  secondary500: 'var(--color-secondary-500)',
  secondary600: 'var(--color-secondary-600)',
  secondary700: 'var(--color-secondary-700)',
  secondary800: 'var(--color-secondary-800)',
  secondary900: 'var(--color-secondary-900)',

  tertiary: 'var(--color-sunbeam-blue)',

  tertiary50: 'var(--color-tertiary-50)',
  tertiary100: 'var(--color-tertiary-100)',
  tertiary200: 'var(--color-tertiary-200)',
  tertiary300: 'var(--color-tertiary-300)',
  tertiary400: 'var(--color-tertiary-400)',
  tertiary500: 'var(--color-tertiary-500)',
  tertiary600: 'var(--color-tertiary-600)',
  tertiary700: 'var(--color-tertiary-700)',
  tertiary800: 'var(--color-tertiary-800)',
  tertiary900: 'var(--color-tertiary-900)',

  highlight50: 'var(--color-highlight-50)',
  highlight100: 'var(--color-highlight-100)',
  highlight200: 'var(--color-highlight-200)',
  highlight300: 'var(--color-highlight-400)',
  highlight500: 'var(--color-highlight-500)',
  highlight600: 'var(--color-highlight-600)',
  highlight700: 'var(--color-highlight-700)',
  highlight800: 'var(--color-highlight-800)',
  highlight900: 'var(--color-highlight-900)',

  warning50: 'var(--color-warning-50)',
  warning100: 'var(--color-warning-100)',
  warning200: 'var(--color-warning-200)',
  warning300: 'var(--color-warning-300)',
  warning400: 'var(--color-warning-400)',
  warning500: 'var(--color-warning-500)',
  warning600: 'var(--color-warning-600)',
  warning700: 'var(--color-warning-700)',
  warning800: 'var(--color-warning-800)',
  warning900: 'var(--color-warning-900)',

  neutral50: 'var(--color-neutral-50)',
  neutral100: 'var(--color-neutral-100)',
  neutral200: 'var(--color-neutral-200)',
  neutral300: 'var(--color-neutral-300)',
  neutral400: 'var(--color-neutral-400)',
  neutral500: 'var(--color-neutral-500)',
  neutral600: 'var(--color-neutral-600)',
  neutral700: 'var(--color-neutral-700)',
  neutral800: 'var(--color-neutral-800)',
  neutral900: 'var(--color-neutral-900)',

  success50: 'var(--color-success-50)',
  success100: 'var(--color-success-100)',
  success200: 'var(--color-success-200)',
  success300: 'var(--color-success-300)',
  success400: 'var(--color-success-400)',
  success500: 'var(--color-success-500)',
  success600: 'var(--color-success-600)',
  success700: 'var(--color-success-700)',
  success800: 'var(--color-success-800)',
  success900: 'var(--color-success-900)',

  error50: 'var(--color-error-50)',
  error100: 'var(--color-error-100)',
  error200: 'var(--color-error-200)',
  error300: 'var(--color-error-300)',
  error400: 'var(--color-error-400)',
  error500: 'var(--color-error-500)',
  error600: 'var(--color-error-600)',
  error700: 'var(--color-error-700)',
  error800: 'var(--color-error-800)',
  error900: 'var(--color-error-900)',

  white: '#FFFFFF',

  black: '#000000',

  transparent: 'transparent',

  whatsappGreen: '#25D366',
  whatsappDarkGreen: '#2BB927',
} as const;

export type colorKey = keyof typeof colors;
export type colorValue = typeof colors[colorKey];
