import { ReactNode, useEffect, useRef, ReactElement, useMemo } from 'react';
import {
  BackgroundSt,
  DialogSt,
  TitleSt,
  CloseButtonSt,
  ContentSt,
  GlobalStyle,
  FullPageCloseButton,
  FullPageContentSt,
  FullPageTitleSt,
  FullPageDialogSt,
  FullPageBackgroundSt,
} from './Modal.css';
import { ModalProvider, useModalContext } from 'app/providers/ModalProvider';
import Portal from 'app/shared/components/Portal/Portal';
import { NavBanner } from 'app/shared/components/PageWrapper/components/PageTemplate/components/NavBar/components/NavBarContact/NavBanner';
import { ModalAnimatedContainer } from 'app/shared/components/Modal/ModalAnimatedContainer/ModalAnimatedContainer';
import { useDelayUnmount } from 'app/hooks/useDelayUnmount';
import { ChevronLeft, Close } from '@heycar-uikit/icons';
import { colors } from 'app/styles/colors';

interface ICommonModalProps {
  id?: string;
  testId?: string;
  show?: boolean;
  title?: ReactNode;
  minHeight?: string;
  onClose?: (e: any) => void;
  children?: any;
  className?: string;
  animated?: boolean;
}

interface IFullPageModalProps extends ICommonModalProps {
  fullPage?: any;
  containerStyles?: any;
  includeNavigation?: boolean;
  removePadding?: boolean;
}

const ANIMATION_DURATION_MS = 250;

// See https://github.com/facebook/react/issues/11387
const preventBubbleUp = e => e.stopPropagation();

const FullPageModal = ({
  children,
  fullPage,
  onClose,
  id,
  testId,
  title,
  containerStyles,
  className,
  includeNavigation,
  removePadding,
}: IFullPageModalProps): ReactElement => {
  const defaultFirstElement =
    typeof fullPage === 'object' ? fullPage?.firstElement : null;
  const secondElement =
    typeof fullPage === 'object' ? fullPage?.secondElement : null;

  const firstElement = defaultFirstElement ? (
    defaultFirstElement
  ) : (
    <FullPageCloseButton onClick={onClose} color={colors.white}>
      <ChevronLeft fontSize={28} />
    </FullPageCloseButton>
  );

  return (
    <FullPageBackgroundSt
      id={id}
      data-testid={testId}
      className={className}
      onClick={preventBubbleUp}
    >
      {includeNavigation && (
        <NavBanner firstElement={firstElement} secondElement={secondElement} />
      )}
      <GlobalStyle />
      <FullPageDialogSt
        id={`${id}-dialog`}
        includeNavigation={includeNavigation}
        removePadding={removePadding}
      >
        {!!title && <FullPageTitleSt>{title}</FullPageTitleSt>}
        <FullPageContentSt containerStyles={containerStyles}>
          {children}
        </FullPageContentSt>
      </FullPageDialogSt>
    </FullPageBackgroundSt>
  );
};

interface IStandardModalProps extends ICommonModalProps {
  disableShadow?: boolean;
  direction?: 'left' | 'right' | 'bottom';
  width?: number;
  height?: number;
  modalEl?: any;
  el?: any;
  maxHeight?: string;
  cropped?: boolean;
  mobileAutoHeight?: boolean;
  zIndex?: number;
}

const StandardModal = ({
  children,
  onClose,
  id,
  testId,
  show,
  title,
  minHeight,
  el,
  disableShadow,
  direction,
  width,
  height,
  maxHeight,
  cropped,
  className,
  animated,
  mobileAutoHeight,
  zIndex,
}: IStandardModalProps): ReactElement => {
  const { modalElementRef } = useModalContext();

  return (
    <BackgroundSt
      id={id}
      data-testid={testId}
      show={show}
      ref={el}
      disableShadow={disableShadow}
      direction={direction}
      className={className}
      animated={animated}
      animationDuration={ANIMATION_DURATION_MS}
      zIndex={zIndex}
      onClick={preventBubbleUp}
    >
      {show && <GlobalStyle />}
      <ModalAnimatedContainer
        direction={direction}
        animated={animated}
        animationDuration={ANIMATION_DURATION_MS}
        show={show}
      >
        <DialogSt
          ref={modalElementRef}
          width={width}
          height={height}
          minHeight={minHeight}
          maxHeight={maxHeight}
          direction={direction}
          mobileAutoHeight={mobileAutoHeight}
        >
          {!!title && <TitleSt>{title}</TitleSt>}
          {!!onClose && (
            <CloseButtonSt
              onClick={onClose}
              data-qa={direction ? 'modal-header-close-btn' : 'modal-close-btn'}
            >
              <Close />
            </CloseButtonSt>
          )}
          <ContentSt cropped={cropped} className="modal-content">
            {children}
          </ContentSt>
        </DialogSt>
      </ModalAnimatedContainer>
    </BackgroundSt>
  );
};

interface IModalProps extends IFullPageModalProps, IStandardModalProps {
  onModalElementChange?: (val) => void;
  zIndex?: number;
}

const BETTER_DATEINPUT_TAGNAME = 'DATEINPUT-PICKER';
const ESC = 27;

const Modal = ({
  show = false,
  children,
  title,
  onClose,
  disableShadow,
  width,
  height,
  maxHeight,
  minHeight,
  cropped,
  direction,
  id,
  fullPage = false,
  includeNavigation = true,
  removePadding = false,
  containerStyles,
  testId,
  className,
  animated,
  mobileAutoHeight = false,
  zIndex = 9999,
}: IModalProps) => {
  const el = useRef(null);

  useEffect(() => {
    const keyHandler = e => e.keyCode === ESC && onClose && onClose(e);
    const mouseHandler = e => {
      // unfortunately we cannot handle it inside better-dateinput-polyfill.min.js
      if (e.target.tagName === BETTER_DATEINPUT_TAGNAME) {
        return;
      }
      e.target === el.current && onClose && onClose(e);
    };

    const { body } = document;

    body.addEventListener('keyup', keyHandler);
    body.addEventListener('mousedown', mouseHandler);

    return () => {
      body.removeEventListener('keyup', keyHandler);
      body.removeEventListener('mousedown', mouseHandler);
    };
  }, [onClose]);

  const commonProps = useMemo(
    () => ({ onClose, id, testId, title, el, className }),
    [onClose, id, testId, title, el, className],
  );

  return (
    <>
      {!!fullPage ? (
        <FullPageModal
          {...commonProps}
          fullPage={fullPage}
          containerStyles={containerStyles}
          includeNavigation={includeNavigation}
          removePadding={removePadding}
        >
          {children}
        </FullPageModal>
      ) : (
        <StandardModal
          {...commonProps}
          show={show}
          animated={animated}
          disableShadow={disableShadow}
          direction={direction}
          width={width}
          height={height}
          maxHeight={maxHeight}
          minHeight={minHeight}
          cropped={cropped}
          mobileAutoHeight={mobileAutoHeight}
          zIndex={zIndex}
        >
          {children}
        </StandardModal>
      )}
    </>
  );
};

const ModalWithProviders = (props: IModalProps) => {
  const { show, animated, onClose, children } = props;

  const shouldRender = useDelayUnmount(
    show,
    animated ? ANIMATION_DURATION_MS : 0,
  );

  return (
    shouldRender && (
      <Portal containerId="modals">
        <ModalProvider closeModal={onClose}>
          <Modal {...props}>{children}</Modal>
        </ModalProvider>
      </Portal>
    )
  );
};

export { ModalWithProviders as Modal };
