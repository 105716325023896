import { useEffect, useState } from 'react';

const useDelayUnmount = (isMounted: boolean, delayTime: number) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (needsToBeShown()) {
      immediateShow();
    } else if (needsToBeHidden()) {
      return delayedHide();
    }
  }, [isMounted, delayTime, shouldRender]);

  return shouldRender;

  function needsToBeShown() {
    return isMounted && !shouldRender;
  }

  function needsToBeHidden() {
    return !isMounted && shouldRender;
  }

  function immediateShow() {
    setShouldRender(true);
  }

  function delayedHide() {
    const timerId = setTimeout(() => setShouldRender(false), delayTime);
    return () => clearTimeout(timerId);
  }
};

export { useDelayUnmount };
