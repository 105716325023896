const provinces = [
  'Granada',
  'Huesca',
  'Lleida',
  'Girona',
  'León',
  'Asturias',
  'Cantabria',
  'Almería',
  'Barcelona',
  'Ávila',
  'Palencia',
  'Navarra',
  'Salamanca',
  'Madrid',
  'Segovia',
  'Cáceres',
  'Soria',
  'Zaragoza',
  'Guadalajara',
  'La Rioja',
  'Jaén',
  'Burgos',
  'Ourense',
  'Zamora',
  'Albacete',
  'Málaga',
  'Teruel',
  'Murcia',
  'Lugo',
  'Cuenca',
  'Valencia',
  'Castellón',
  'Cádiz',
  'Córdoba',
  'Alicante',
  'Guipúzcoa',
  'Álava',
  'Bizkaia',
  'Toledo',
  'Tarragona',
  'Islas Baleares',
  'Ciudad Real',
  'Pontevedra',
  'Sevilla',
  'Badajoz',
  'Huelva',
  'Valladolid',
  'A Coruña',
  'Ceuta',
  'Melilla',
  'Las Palmas',
  'Tenerife',
].sort();

const duplicatedProvinces = [
  'Alacant',
  'Araba',
  'Castelló',
  'Gerona',
  'Gipuzkoa',
  'Illes Balears',
  'La Coruña',
  'Lérida',
  'Orense',
  'València',
  'Vizcaya',
].sort();

export const sluggify = (str: string): string => {
  const map = {
    a: 'á|à|ã|â|À|Á|Ã|Â',
    e: 'é|è|ê|É|È|Ê',
    i: 'í|ì|î|Í|Ì|Î',
    o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ',
  };

  for (const pattern in map) {
    str = str.replace(new RegExp(map[pattern], 'g'), pattern);
  }

  return str.toLowerCase();
};

function provincesOptions(includeDuplicates = false) {
  const options = includeDuplicates
    ? provinces.concat(duplicatedProvinces)
    : provinces;
  return options.map(province => ({
    key: sluggify(province),
    value: province,
  }));
}

export const getProvinces = (
  q,
  options?: { size?: number; includeDuplicates?: boolean },
) => {
  const size = options?.size || 5;
  const includeDuplicates = options?.includeDuplicates || false;
  return !q.trim()
    ? []
    : provincesOptions(includeDuplicates)
        .filter(
          option =>
            option.key.includes(q.toLowerCase()) ||
            option.value.toLowerCase().includes(q.toLowerCase()),
        )
        .map(option => option.value)
        .slice(0, size);
};

export const getAllProvinces = (): { key: string; value: string }[] =>
  provinces.map(province => ({
    key: sluggify(province),
    value: province,
  }));
