import { AppSt, GlobalStyle } from 'app/App.css';
import { TrackingProvider } from 'app/providers/TrackingProvider/TrackingProvider';
import { TranslateInspectionProvider } from 'app/providers/TranslateInspectionProvider';
import { CookieComplaintBanner } from 'app/shared/components/CookieComplaintBanner/CookieComplaintBanner';
import { ErrorBoundary } from 'app/shared/components/ErrorBoundary/ErrorBoundary';
import theme from 'app/styles/theme';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import 'react-phone-number-input/style.css';
import { RECAPTCHA_SITE_TOKEN } from 'src/next/app/config';
import { ThemeProvider } from 'styled-components';
import { BASE_ROUTES } from './hooks/useUrl/useUrl';
import { CookiesSettingsProvider } from './providers/CookiesSettingsProvider';
import { FeatureToggleProvider } from './providers/FeatureToggleProvider/FeatureToggleProvider';
import { GeneralSettingsProvider } from './providers/GeneralSettingsProvider';
import { PDPNavigationProvider } from './providers/PDPNavigationProvider';
import { ServicesProvider } from './providers/ServicesProvider';
import { CookieBanner } from './shared/components/CookieBanner/CookieBanner';

interface IAppProps {
  children: ReactNode;
  isPreview?: boolean;
  statusCode?: number;
}

const App = ({ children, statusCode, isPreview }: IAppProps): ReactElement => {
  const router = useRouter();

  if (shouldRedirect(statusCode)) {
    router.replace(getRedirectPathForStatusCode(statusCode));
    return null;
  }

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <FeatureToggleProvider>
          <TranslateInspectionProvider>
            <GeneralSettingsProvider isPreview={isPreview}>
              <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_TOKEN}>
                <CookiesSettingsProvider>
                  {/* <CampaignProvider> */}
                  <PDPNavigationProvider>
                    <ServicesProvider>
                      <TrackingProvider>
                        <AppSt>
                          <CookieBanner />
                          <CookieComplaintBanner />
                          {children}
                          <GlobalStyle />
                        </AppSt>
                      </TrackingProvider>
                    </ServicesProvider>
                  </PDPNavigationProvider>
                  {/* </CampaignProvider> */}
                </CookiesSettingsProvider>
              </GoogleReCaptchaProvider>
            </GeneralSettingsProvider>
          </TranslateInspectionProvider>
        </FeatureToggleProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );

  function shouldRedirect(statusCode) {
    return (
      typeof window !== 'undefined' &&
      statusCode > 200 &&
      ![BASE_ROUTES.downtime, BASE_ROUTES.page404].includes(router.pathname)
    );
  }

  function getRedirectPathForStatusCode(statusCode) {
    return statusCode === 404 ? BASE_ROUTES.page404 : BASE_ROUTES.downtime;
  }
};

export { App };
