import {
  useState,
  useEffect,
  createContext,
  useContext,
  ReactNode,
  ReactElement,
} from 'react';
import { useRouter } from 'next/router';
import { FinancingData, useFinancingData } from 'app/hooks/useFinancingData';
import { VehicleListingData } from 'app/apiCalls/vehicle';
import { FinancingPrecalculationData } from 'app/apiCalls/financingProxy';
import { isMobileScreen } from 'app/utils/utils';
import { BASE_ROUTES } from 'app/hooks/useUrl/useUrl';

interface ContextType {
  prevUrl?: string;
  isPreview?: boolean;
  buildFinancingDataWithCalculatorData?: (
    vehicle: VehicleListingData,
    calculatorData: FinancingPrecalculationData,
  ) => FinancingData | null;
  getLastFinancingDataByVehicle?: (
    vehicle: VehicleListingData,
  ) => FinancingData | null;
  setFinancingData?: (data: FinancingData) => void;
  isMobile?: boolean;
}

export const GeneralSettingsContext = createContext<ContextType>({
  prevUrl: '',
});

interface GeneralSettingsProviderProps extends ContextType {
  children?: ReactNode;
}

const GeneralSettingsProvider = ({
  isPreview,
  children,
}: GeneralSettingsProviderProps): ReactElement => {
  const { events } = useRouter();

  const [prevUrl, setPrevUrl] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  const {
    getLastFinancingDataByVehicle,
    buildFinancingDataWithCalculatorData,
    setFinancingData,
  } = useFinancingData();

  useEffect(() => {
    const resizeHandler = ({ target }) => {
      if (isMobileScreen(target.innerWidth)) {
        setIsMobile(isMobile => (!isMobile ? true : isMobile));
      } else if (!isMobileScreen(target.innerWidth)) {
        setIsMobile(isMobile => (isMobile ? false : isMobile));
      }
    };
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [setIsMobile]);

  useEffect(() => {
    setIsMobile(isMobileScreen(window.innerWidth));
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (
        url.includes(BASE_ROUTES.clp) ||
        url.includes(BASE_ROUTES.km0) ||
        url.includes(BASE_ROUTES.Favorites)
      ) {
        setPrevUrl(url);
      }
    };

    events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [events, prevUrl]);

  return (
    <GeneralSettingsContext.Provider
      value={{
        prevUrl,
        isPreview,
        getLastFinancingDataByVehicle,
        buildFinancingDataWithCalculatorData,
        setFinancingData,
        isMobile,
      }}
    >
      {children}
    </GeneralSettingsContext.Provider>
  );
};

const useGeneralSettings = () => useContext(GeneralSettingsContext);

export { GeneralSettingsProvider, useGeneralSettings };
