import {
  useEffect,
  useContext,
  createContext,
  ReactNode,
  ReactElement,
} from 'react';
import { useRouter } from 'next/router';
import { trackPage, IUserData } from 'app/utils/tracking';
import { useTrackingPage } from 'app/shared/components/PageWrapper/providers/TrackingPageProvider/hooks/useTrackingPage/useTrackingPage';
import { useTrackingContext } from 'app/providers/TrackingProvider/TrackingProvider';

interface ITrackingPageContext {
  trackAction: (
    eventName: string,
    basicProperties?: any,
    additionalProperties?: any,
    userData?: IUserData,
    logTracker?: boolean,
  ) => void;
}

export const TrackingPageContext = createContext<ITrackingPageContext>({
  trackAction: () => {},
});

interface ITrackingPageProviderProps {
  children?: ReactNode;
}

const TrackingPageProvider = ({
  children,
}: ITrackingPageProviderProps): ReactElement => {
  const { events } = useRouter();
  const { trackAction } = useTrackingPage();

  const { guid } = useTrackingContext();

  useEffect(() => {
    const handleRouteChange = () => {
      setTimeout(() => trackPage(guid));
    };

    events.on('routeChangeComplete', handleRouteChange);

    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events, guid]);

  return (
    <TrackingPageContext.Provider value={{ trackAction }}>
      {children}
    </TrackingPageContext.Provider>
  );
};

const useTrackingPageContext = () => useContext(TrackingPageContext);

export { TrackingPageProvider, useTrackingPageContext };
