import { useCallback, useState } from 'react';
import { VehicleListingData } from 'app/apiCalls/vehicle';
import {
  FinancingPrecalculationData,
  ProductData,
} from 'app/apiCalls/financingProxy';
import { get } from 'react-hook-form';
import {
  defaultFinancingMessage,
  getProviderId,
} from 'app/shared/components/FinancingCalculator/providers';
import { dateWithZeroPadding } from 'app/utils/utils';
import { useIntl } from 'react-intl';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';

interface Financing {
  financingProvider: string;
  deposit: number;
  duration: number;
  apr: number;
  nir: number;
  monthlyRate: number;
  discount: number;
  openingFee: number;
  totalFinancedAmount: number;
  offerValidityDate: string;
  product: string;
}

export interface FinancingData {
  listingId: string;
  message: string;
  financing: Financing;
}

const useFinancingData = () => {
  const MissingProviderTranslation = '|MISSING_PROVIDER_TRANSLATION|';

  const { formatNumber } = useIntl();
  const plainTranslate = usePlainTranslate();

  const [financingData, setFinancingData] = useState(null);

  const getLastFinancingDataByVehicle = useCallback(
    (vehicle: VehicleListingData): FinancingData | null => {
      if (financingData?.listingId === vehicle?.id) {
        return financingData;
      }
      return buildFinancingDataWithCalculatorData(
        vehicle,
        vehicle?.financingPrecalc,
      );
    },
    [financingData],
  );

  const buildFinancingDataWithCalculatorData = useCallback(
    (
      vehicle: VehicleListingData,
      calculatorData: FinancingPrecalculationData,
    ): FinancingData => {
      if (!calculatorData) {
        return null;
      }

      const productTranslationKey = getProductTranslationKey(calculatorData);
      const providerId = getProviderId(calculatorData);
      const calculationResult = get(calculatorData, 'calculationResult', {});
      const productName = plainTranslate({
        id: `financingProvider.${providerId}.product.${productTranslationKey}.name`,
      });

      return {
        listingId: vehicle?.id,
        message: getMessage(
          calculationResult,
          calculatorData,
          providerId,
          productTranslationKey,
          productName,
        ),
        financing: getFinancing(
          calculationResult,
          calculatorData,
          providerId,
          productTranslationKey,
          productName,
        ),
      };
    },
    [],
  );

  const getProductTranslationKey = (calculatorData): string => {
    const product =
      get(calculatorData, 'products', []).find(
        (item: ProductData) => item.usedForCalculation,
      ) || {};
    return product.offerId || product.productId;
  };

  const getFinancing = (
    calculationResult,
    calculatorData,
    providerId,
    productTranslationKey,
    productName,
  ): Financing => {
    const legalDisclaimer = get(
      calculatorData,
      'disclaimers',
      {},
    ).legalDisclaimer;
    return {
      financingProvider: providerId,
      deposit: calculatorData?.deposit?.value,
      duration: calculatorData?.months?.value,
      apr: calculationResult?.annualInterestRate,
      nir: calculationResult?.nominalInterestRate,
      monthlyRate: calculationResult?.monthlyRate,
      discount: calculationResult?.offerDiscount,
      openingFee: legalDisclaimer?.financedOpening,
      totalFinancedAmount: calculationResult?.netLoanAmount,
      offerValidityDate: dateWithZeroPadding(),
      product: productName,
    };
  };

  const getMessage = (
    calculationResult,
    calculatorData,
    providerId,
    productTranslationKey,
    productName,
  ): string => {
    const messageData = {
      ...calculationResult,
      monthlyRate: formatNumber(calculationResult?.monthlyRate, {
        style: 'currency',
        currency: 'EUR',
      }),
      productName,
      depositValue: calculatorData?.deposit?.value,
      monthsValue: calculatorData?.months?.value,
      nominalInterestRate: calculationResult?.nominalInterestRate || 0,
    };
    const providerSpecificMessage = plainTranslate({
      id: `financingProvider.${providerId}.product.${productTranslationKey}.financingEmailMessage`,
      defaultMessage: MissingProviderTranslation,
      values: messageData,
    });
    const financingMessage = plainTranslate({
      id: 'shared.components.financingCalculator.defaultFinancingEmailMessage',
      defaultMessage: defaultFinancingMessage,
      values: messageData,
    });
    return providerSpecificMessage === MissingProviderTranslation
      ? financingMessage
      : providerSpecificMessage;
  };

  return {
    buildFinancingDataWithCalculatorData,
    getLastFinancingDataByVehicle,
    setFinancingData,
  };
};

export { useFinancingData };
