import { ReactNode, PureComponent } from 'react';
import * as Sentry from '@sentry/nextjs';
import Translation from 'app/shared/components/Translation/Translation';
import {
  ErrorBoundarySt,
  ErrorIconSt,
  ErrorMessageSt,
} from './ErrorBoundary.css';

interface IErrorBoundaryProps {
  onError?: Function;
  children?: ReactNode;
  FallbackComponent?: Function;
}

interface IErrorBoundaryState {
  error: string;
  info: {
    componentStack: string;
  };
}

export class ErrorBoundary extends PureComponent<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    const { onError } = this.props;

    if (typeof onError === 'function') {
      try {
        onError(this, error, info ? info.componentStack : '');
      } catch {}
    }

    this.setState({ error, info });

    Sentry.captureException(error, { extra: info });
  }

  render() {
    const { children, FallbackComponent } = this.props;
    const { error, info } = this.state;

    if (!error) {
      return children || null;
    }

    if (FallbackComponent) {
      return (
        <FallbackComponent
          componentStack={info ? info.componentStack : ''}
          error={error}
        />
      );
    }

    return (
      <ErrorBoundarySt>
        <ErrorIconSt>
          <i className="if-icon-tools" />
        </ErrorIconSt>
        <ErrorMessageSt>
          <Translation
            id="shared.components.errorBoundary.error"
            defaultMessage="Unfortunately an error occurred."
          />
        </ErrorMessageSt>
      </ErrorBoundarySt>
    );
  }
}
