import styled from 'styled-components';
import { colorValue } from 'app/styles/colors';

interface IIconWrapperStProps {
  color?: colorValue;
}

export const IconWrapperSt = styled.span<IIconWrapperStProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => (color ? color : 'inherit')};
`;
