import styled, { css } from 'styled-components';
import { media, zIndex } from 'app/styles/variables';

export const NavBarInnerSt = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${zIndex.navbar};
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 56px;
  padding: 1rem;

  background-color: ${({ theme }) => theme.colors.primary};

  ${media.lg(css`
    height: 64px;
  `)};
`;
