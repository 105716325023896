import styled, { css } from 'styled-components';
import { media } from 'app/styles/variables';

interface IAnimatedContainerStProps {
  show: boolean;
  direction: 'left' | 'right' | 'bottom';
  animated: boolean;
  animationDuration: number;
}

export const AnimatedContainerSt = styled.div<IAnimatedContainerStProps>`
  display: flex;
  position: absolute;
  top: ${props => getTopPosition(props)};
  right: ${props => getRightPosition(props)};
  bottom: ${props => getBottomPosition(props)};
  left: ${props => getLeftPosition(props)};
  overflow: auto;
  height: ${({ direction }) => (direction === 'bottom' ? 'auto' : '100%')};
  width: ${({ direction }) =>
    !direction || direction === 'bottom' ? '100%' : 'auto'};
  ${props => props.animated && getAnimation(props)}

  ${() => getAnimationKeyframes()}
  
  ${media.sm(css`
    width: auto;
    height: auto;
  `)};
`;

function getTopPosition({ direction }) {
  return !direction || direction === 'bottom' ? 'unset' : '0';
}

function getRightPosition({ direction }) {
  return !direction || isLeftOrBottom(direction) ? 'unset' : '0';
}

function getBottomPosition({ direction }) {
  return !direction ? 'unset' : '0';
}

function getLeftPosition({ direction }) {
  return !direction || isRightOrBottom(direction) ? 'unset' : '0';
}

function getAnimation({ show, animationDuration, direction }) {
  return `animation: ${
    show
      ? `${direction}ModalSlideInAnimation ${animationDuration}ms ease-out;`
      : `${direction}ModalSlideOutAnimation ${
          animationDuration + 10
        }ms ease-in;`
  }`;
}

function getAnimationKeyframes() {
  return ['bottom', 'left', 'right']
    .map(
      direction => `@keyframes ${direction}ModalSlideInAnimation {
        0% { ${direction}: -50px; }
        100% { ${direction}: 0; }
      }
      @keyframes ${direction}ModalSlideOutAnimation {
        0% { ${direction}: 0; }
        100% { ${direction}: -50px; }
      }`,
    )
    .join('\n');
}

function isLeftOrBottom(direction) {
  return ['left', 'bottom'].includes(direction);
}

function isRightOrBottom(direction) {
  return ['right', 'bottom'].includes(direction);
}
