import get from 'lodash.get';

import { generateSEOUrl } from 'app/containers/PDP/utils';

import apiClient from 'app/utils/apiClient';
import { PAGINATION_SIZE } from 'next-app/app/config';

import {
  AggregationData,
  AggregationsListDataItem,
  generateMakeAndModelData,
} from './utils';
import { DealerData } from './dealer';
import { FinancingPrecalculationData } from './financingProxy';
import {
  SortDirection,
  SortBy,
} from 'app/containers/CLP/components/SortControl/SortControl';

export const ERROR_GENERIC = 'Error fetching vehicle';
export const ERROR_NOT_FOUND = 'Vehicle with ID could not be found';

export const MAKER_MAP = {
  volkswagen: 'vw',
};

export enum VEHICLE_STATE {
  PUBLISHED = 'PUBLISHED',
}

export interface MakeData {
  id: string;
  displayName: string;
}

export interface ModelData {
  id: string;
  displayName: string;
  source?: string;
  trim?: string;
}

export interface MarkerData {
  id: string;
  type: string;
  pdpCategory: string;
}

export interface EmissionsFuelConsumptionInfo {
  nefzEmissionsCombined?: number;
  nefzEmissionsCombinedUnit?: string;
  nefzFuelConsumptionCombined?: number;
  nefzFuelConsumptionCombinedUnit?: string;
  wltpEmissionsCombined?: number;
  wltpEmissionsCombinedUnit?: string;
  wltpFuelConsumptionCombined?: number;
  wltpFuelConsumptionCombinedUnit?: string;
}

export enum TSP {
  DEKRA = 'dekra',
  QUITER = 'quiter',
  INVENTARIO = 'inventario',
  PEREZRUMBAO = 'perezrumbao',
  MULTIPOST = 'multipost',
  MAXTERAUTO = 'maxterauto',
  IMAWEB = 'imaweb',
  MOTORFLASH = 'motorflash',
  MODIX = 'modix',
  MOTORK = 'motork',
  DAPDA = 'dapda',
}

export interface VehicleListingData {
  id: string;
  gearBox?: string;
  fuelType?: string;
  categories?: string[];
  features?: string[];
  dealer?: DealerData;
  mileage?: number;
  price?: number;
  oldPrice?: number;
  firstRegistrationDate?: string;
  year?: number;
  model?: ModelData;
  make?: MakeData;
  interiorType?: string;
  interiorDescription?: string;
  bodyColor?: string;
  doors?: number;
  seats?: number;
  cubicCapacity?: number;
  warranty?: boolean;
  guarantee?: number;
  serviceBook?: boolean;
  observations?: string;
  lastUse?: string;
  fuelConsumptionCombined?: number;
  emissionPerKm?: number;
  fuelConsumptionInner?: number;
  fuelConsumptionOuter?: number;
  emissionSticker?: string;
  performance?: number;
  motDate?: string;
  remarks?: string;
  images?: {
    url: string;
    alt?: string;
  }[];
  financingPrecalc?: FinancingPrecalculationData;
  state?: VEHICLE_STATE | string;
  markers?: MarkerData[];
  // added by our FE
  seoUrl?: string;
  dataSource?: string;
  booked?: boolean;
  live?: boolean;
  emissionsFuelConsumptionInfo?: EmissionsFuelConsumptionInfo;
  isLastViewedCar?: boolean;
  tsp?: TSP;
  dealerId?: string;
  createdAt?: string;
  vin?: string;
}

export const searchById = (
  id: string,
  reducedContent = false,
): Promise<VehicleListingData> =>
  apiClient({
    path: '/search',
    params: { id, reducedContent },
    method: 'GET',
  }).then(data => {
    const vehicle = get(data, 'content[0]');
    if (!vehicle) {
      throw new Error(
        data && data.total === 0 ? ERROR_NOT_FOUND : ERROR_GENERIC,
      );
    }

    return vehicle;
  });

export const searchByIds = (
  ids: string[],
  reducedContent = false,
): Promise<VehicleListingData[]> =>
  apiClient({
    path: '/search',
    params: { id: ids.join(), reducedContent, size: ids.length },
    method: 'GET',
  }).then(data => {
    const content = get(data, 'content', []);
    return content?.reduce((acc, item) => {
      if (item.id) {
        const seoUrl = generateSEOUrl(item);
        acc.push({
          ...item,
          seoUrl,
        });
      }
      return acc;
    }, []);
  });

export const searchByIdsRespectSort = (
  ids: string[],
  reducedContent = false,
): Promise<VehicleListingData[]> =>
  searchByIds(ids, reducedContent).then(results =>
    ids.map(id => results.find(v => v.id === id)).filter(v => !!v),
  );

export type PaginationType = {
  page: number;
  size: number;
  total: number;
  totalPages: number;
};

export interface SearchByParamsResult {
  aggregations?: AggregationData;
  vehicles: VehicleListingData[];
  pagination?: PaginationType;
}

export interface SearchFilterQueryData {
  make?: string | string[];
  model?: string | string[];
  modelFamily?: string | string[];
  gearBox?: string | string[];
  fuelType?: string | string[];
  featureText?: string | string[];
  bodyColor?: string | string[];
  category?: string | string[];
  mileageRange?: string;
  monthlyInstallmentRange?: string;
  lat?: string | number;
  lon?: string | number;
  radius?: string;
  priceRange?: string;
  yearRange?: string;
  cubicCapacityRange?: string;
  nuts3?: string | string[];
  dealerId?: string;
  emissionSticker?: string | string[];
  receiveVideoLead?: boolean;
  performanceRange?: string;
  markerId?: string;
  hasFinancingOffer?: boolean;
  campaignId?: string | string[];
  excludedDealerId?: string;
  distinctDealers?: boolean;
  dataSource?: string | string[];
  booked?: boolean;
  seats?: string | string[];
}

export interface SearchByParamsProps {
  page: number;
  size?: number;
  filters: SearchFilterQueryData;
  sortDirection?: SortDirection;
  sortBy?: SortBy;
  reducedContent?: boolean;
}

export interface CountByParamsProps {
  page: number;
  size?: number;
  filters: SearchFilterQueryData;
  sortDirection?: SortDirection;
  sortBy?: SortBy;
}

export interface CountByParamsResult {
  aggregations?: AggregationData;
  pagination?: PaginationType;
}

export const sortAggregatedData = (
  data: AggregationsListDataItem[],
): AggregationsListDataItem[] => {
  return data.sort((a, b) => {
    if (a.displayName > b.displayName) {
      return 1;
    }
    if (a.displayName < b.displayName) {
      return -1;
    }
    return 0;
  });
};

interface IRecommendationsParamsProps {
  listingId: string;
  disableWiderRange?: boolean;
}

export const getOneClickRecommendations = ({
  listingId,
  disableWiderRange,
}: IRecommendationsParamsProps): Promise<SearchByParamsResult> =>
  apiClient({
    path: '/search/differentdealersmlt',
    params: {
      listingId,
      disableWiderRange,
      maxChargeableLeadCount: 7,
    },
    method: 'GET',
  }).then(({ content }) => ({
    vehicles: content,
  }));

export const searchByParams = ({
  page,
  size = PAGINATION_SIZE,
  filters,
  sortDirection,
  sortBy,
  reducedContent,
}: SearchByParamsProps): Promise<SearchByParamsResult> => {
  const make = getMakeFilter(filters);

  return apiClient({
    path: '/search',
    params: {
      page: page - 1,
      size,
      sortDirection,
      sortBy,
      reducedContent,
      ...filters,
      make,
    },
    method: 'GET',
  }).then(data => {
    const { aggregations, content, size, number, total, totalPages } = data;

    const {
      gearBox,
      bodyColor,
      price,
      category: bodyType,
      fuelType,
      emissionSticker,
      performance,
      seats,
    } = aggregations;

    const makeAndModelData = generateMakeAndModelData(aggregations);
    const dealerName = get(content, '[0].dealer.name');

    // Here we pre-generate all the SEOUrls and just extend the existing vehicle item
    // with it, so we don't have to do this elsewhere.
    const vehiclesWithSEOUrls = content?.reduce((acc, item) => {
      if (item.id) {
        const seoUrl = generateSEOUrl(item);
        acc.push({
          ...item,
          seoUrl,
        });
      }
      return acc;
    }, []);

    return {
      vehicles: vehiclesWithSEOUrls,
      pagination: {
        page: number,
        size,
        total,
        totalPages,
      },
      aggregations: {
        makeAndModelData,
        gearBoxData: sortAggregatedData(gearBox),
        bodyColorData: bodyColor,
        bodyTypeData: bodyType,
        priceData: price,
        fuelTypeData: sortAggregatedData(fuelType),
        dealerName,
        emissionStickerData: emissionSticker,
        enginePowerData: performance,
        seatsCountData: seats,
      },
    };
  });
};

export const getAggregations = () =>
  apiClient({
    path: '/search/count',
    params: {
      page: 0,
      size: 0,
    },
    method: 'GET',
  }).then(({ aggregations }) => aggregations);

export function getMakeFilter(filters) {
  if (Array.isArray(filters.make)) {
    return filters.make.map(item => MAKER_MAP[item] || item);
  }
  return MAKER_MAP[filters.make] || filters.make;
}

export const getKeyForMakeValue = (makerKey: string): string => {
  let res = makerKey;
  Object.keys(MAKER_MAP).forEach(mapKey => {
    if (MAKER_MAP[mapKey] === makerKey) {
      res = mapKey;
    }
  });
  return res;
};

export const countByParams = ({
  page,
  size = PAGINATION_SIZE,
  filters,
  sortDirection,
  sortBy,
}: CountByParamsProps): Promise<CountByParamsResult> => {
  const make = getMakeFilter(filters);

  return apiClient({
    path: '/search/count',
    params: {
      page: page - 1,
      size,
      sortDirection,
      sortBy,
      ...filters,
      make,
    },
    method: 'GET',
  }).then(data => {
    const { aggregations, content, size, number, total, totalPages } = data;

    const {
      gearBox,
      bodyColor,
      price,
      category: bodyType,
      fuelType,
      emissionSticker,
      performance,
      seats,
    } = aggregations;

    const makeAndModelData = generateMakeAndModelData(aggregations);
    const dealerName = get(content, '[0].dealer.name');

    return {
      pagination: {
        page: number,
        size,
        total,
        totalPages,
      },
      aggregations: {
        makeAndModelData,
        gearBoxData: sortAggregatedData(gearBox),
        bodyColorData: bodyColor,
        bodyTypeData: bodyType,
        priceData: price,
        fuelTypeData: sortAggregatedData(fuelType),
        dealerName,
        emissionStickerData: emissionSticker,
        enginePowerData: performance,
        seatsCountData: seats,
      },
    };
  });
};

export const getNumberOfCampaignVehicles = (tags: string) =>
  countByParams({
    page: 1,
    size: 1,
    filters: {
      markerId: tags,
    },
  })
    .then(data => data.pagination.total)
    .catch(() => 0);
