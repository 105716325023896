import get from 'lodash.get';
import { FinancingPrecalculationData } from 'app/apiCalls/financingProxy';
import { CalculationElements } from './components/CalculationElements/CalculationElements';

export const VWFS_PROVIDER_ID = 'vwfs_es';
export const SFS_PROVIDER_ID = 'santander_es';
export const MBFS_PROVIDER_ID = 'mbb_es';

export type FinancingProviderIDs =
  | typeof VWFS_PROVIDER_ID
  | typeof MBFS_PROVIDER_ID
  | typeof SFS_PROVIDER_ID
  | 'default'
  | 'test';

export const getProviderId = (
  financingPrecalc: FinancingPrecalculationData,
): string => get(financingPrecalc, 'searchFilters.captiveId', '');

export const isVWFS = (providerID: string): boolean =>
  providerID === VWFS_PROVIDER_ID;
export const isMBFS = (providerID: string): boolean =>
  providerID === MBFS_PROVIDER_ID;
export const isSFS = (providerID: string): boolean =>
  providerID === SFS_PROVIDER_ID;

export type CalculationElementsMap = Record<
  FinancingProviderIDs,
  CalculationElements[]
>;

export const defaultElements: CalculationElements[] = [
  'price',
  'deposit',
  'netLoanAmount',
  'annualInterestRate',
  'nominalInterestRate',
];

export const defaultFinancingMessage = `{productName}
€{depositValue} deposit
Duration: {monthsValue} months
APR: {annualInterestRate}%
NIR: {nominalInterestRate}%
Monthly rate: €{monthlyRate}`;

export const calculationElements: CalculationElementsMap = {
  test: [],
  default: defaultElements,
  [VWFS_PROVIDER_ID]: [
    'price',
    'offerDiscount',
    'deposit',
    'netLoanAmount',
    'annualInterestRate',
    'nominalInterestRate',
  ],
  [MBFS_PROVIDER_ID]: defaultElements,
  [SFS_PROVIDER_ID]: [
    'price',
    'deposit',
    'netLoanAmount',
    'annualInterestRate',
    'nominalInterestRate',
  ],
};
