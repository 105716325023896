import { ENVIRONMENT, ENV_TEST } from 'next-app/app/config';
import { EvaluatedFlagDataMap } from 'app/apiCalls/flagr';

const QUERY_NAME = 'flagr-data';

const DataQueryFn = ({}: { userId: string }): Promise<EvaluatedFlagDataMap> => {
  return new Promise(res => setTimeout(() => res([]), 1000));
};

const DataQueryOptions = {
  staleTime: 60000,
  cacheTime: 120000,
  refetchOnWindowFocus: false,
  // disable the query for e2e tests
  enabled: ENVIRONMENT !== ENV_TEST,
};

export default {
  name: QUERY_NAME,
  fn: DataQueryFn,
  options: DataQueryOptions,
};
