// polyfills
const client = (locale = '') => {
  if (typeof window !== 'undefined') {
    // For browsers that do not have Intl.PluralRules (e.g IE11 & Safari 12-)
    if (!Intl.PluralRules) {
      require('@formatjs/intl-pluralrules/polyfill');
      if (locale === 'es') {
        require('@formatjs/intl-pluralrules/dist/locale-data/es');
      } else if (locale === 'en') {
        require('@formatjs/intl-pluralrules/dist/locale-data/en');
      }
    }

    if (window.Intl) {
      // chrome, ie , edge, opera etc
      /*
      we cannot use something like const requiredLocaleData = `intl/locale-data/jsonp/${locale}.js`;
      because webpack cannot handle expressions during build time
     */
      if (locale === 'es') {
        require.ensure(['intl/locale-data/jsonp/es.js'], require => {
          require('intl/locale-data/jsonp/es.js');
        });
      } else if (locale === 'en') {
        require.ensure(['intl/locale-data/jsonp/en.js'], require => {
          require('intl/locale-data/jsonp/en.js');
        });
      }
    } else {
      // really old browsers
      require.ensure(
        [
          'intl',
          'intl/locale-data/jsonp/es.js',
          'intl/locale-data/jsonp/en.js',
        ],
        require => {
          require('intl');
          require('intl/locale-data/jsonp/en.js');
          require('intl/locale-data/jsonp/es.js');
        },
      );
    }
  }
};

export default client;
