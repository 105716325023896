import { getPromotionsData } from 'app/apiCalls/kentico';
import { getNumberOfCampaignVehicles } from 'app/apiCalls/vehicle';
import {
  transformList,
  transformToBoolean,
  transformToDropdownValue,
  transformToImageURL,
  transformToJSON,
  transformToString,
} from 'app/utils/kentico';

const QUERY_NAME = 'campaigns-from-cms';

const DataQueryFn = () => {
  return getPromotionsData().then(async data => {
    if (!data) return [];
    const clpData = extractDataFromCLPItem(data);
    const pdpData = extractDataFromPDPItem(data);

    const result = [];

    for (let i = 0; i < clpData.length; i++) {
      const clpItem = clpData[i];
      const pdpItem = pdpData[i];

      const item = {
        id: buildCampaignId(clpItem),
        order: i,
        markerIds: buildMarkerIdsFromCMS(clpItem),
        clpTitle: buildCLPTitleFromCMS(clpItem),
        banner: buildBannerFromCMS(clpItem),
        filter: buildFilterFromCMS(clpItem),
        badge: buildBadgeFromCMS(clpItem),
        specialOffer: buildSpecialOfferFromCMS(pdpItem),
        specialCampaign: buildSpecialCampaignFromCMS(pdpItem),
      };

      const numberOfCars = await getNumberOfCampaignVehicles(
        item?.markerIds.join(','),
      );

      result.push({ ...item, numberOfCars: `${numberOfCars}` });
    }

    return result;
  });
};

function buildCampaignId(clpItem) {
  return transformToString(clpItem.id);
}

function buildMarkerIdsFromCMS(clpItem) {
  return transformToString(clpItem.markerIds).split(',');
}

function buildCLPTitleFromCMS(clpItem) {
  return transformToString(clpItem.clpTitle, { removeParagraphs: true });
}

function buildSpecialCampaignFromCMS(pdpItem) {
  return {
    priority: transformToString(pdpItem.specialCampaign.priority),
    remarksHeader: transformToString(pdpItem.specialCampaign.remarksHeader),
    trackAction: transformToJSON(pdpItem.specialCampaign.trackAction),
    texts: transformList(pdpItem.specialCampaign.texts, item => ({
      id: transformToString(item.elements.marker_id),
      details: transformToString(item.elements.details),
    })),
  };
}

function buildSpecialOfferFromCMS(pdpItem) {
  return {
    priority: transformToString(pdpItem.specialOffer.priority),
    text: transformToString(pdpItem.specialOffer.text),
    trackAction: transformToJSON(pdpItem.specialOffer.trackAction),
    moreInfo: transformToBoolean(pdpItem.specialOffer.moreInfo),
  };
}

function buildFilterFromCMS(clpItem) {
  if (!clpItem?.filter) {
    return null;
  }
  return {
    text: transformToString(clpItem.filter.text),
    trackAction: transformToJSON(clpItem.filter.trackAction),
    promoText: transformToString(clpItem.filter.promoText),
    promoTheme: transformToDropdownValue(clpItem.filter.promoTheme)?.name,
    infoText: transformToString(clpItem.filter.popUpText),
  };
}

function buildBadgeFromCMS(clpItem) {
  return {
    theme: transformToDropdownValue(clpItem.badge.theme)?.name,
    priority: transformToString(clpItem.badge.priority),
    iconName: transformToDropdownValue(clpItem.badge.iconName)?.name,
    text: transformToString(clpItem.badge.text),
    popUpText: transformToString(clpItem.badge.popUpText),
    trackAction: transformToJSON(clpItem.badge.trackAction),
  };
}

function buildBannerFromCMS(clpItem) {
  return {
    modalText: transformToString(clpItem.banner.informationText),
    text: transformToString(clpItem.banner.description, {
      removeParagraphs: true,
    }),
    header: transformToString(clpItem.banner.title, {
      removeParagraphs: true,
    }),
    linkText: transformToString(
      clpItem.banner.cta.linkedItems[0].elements.label,
    ),
    theme: transformToDropdownValue(clpItem.banner.theme).name,
    trackAction: transformToJSON(clpItem.banner.trackAction),
    replacements: transformToJSON(clpItem.banner.replacements),
    image: { file: { url: transformToImageURL(clpItem.banner.image) } },
    flipImage: transformToBoolean(clpItem.banner.flipImage),
  };
}

function extractDataFromPDPItem(item) {
  return transformList(item.elements.pdp_promotions_list, linkedItem => {
    return {
      specialOffer: {
        priority: linkedItem.elements.special_offer___priority,
        text: linkedItem.elements.special_offer___text,
        trackAction: linkedItem.elements.special_offer___track_action,
        moreInfo: linkedItem.elements.special_offer___more_info,
      },

      specialCampaign: {
        priority: linkedItem.elements.special_campaign___priority,
        texts: linkedItem.elements.special_campaign___texts,
        trackAction: linkedItem.elements.special_campaign___track_action,
        remarksHeader: linkedItem.elements.special_campaign___remarks_header,
      },
    };
  });
}

function extractDataFromCLPItem(item) {
  return transformList(item.elements.clp_promotions_list, linkedItem => {
    return {
      id: linkedItem.elements.id,
      clpTitle: linkedItem.elements.clp_title,
      markerIds: linkedItem.elements.marker_id,
      banner: extractBannerFromCLPItem(linkedItem),
      badge: extractBadgeFromCLPItem(linkedItem),
      filter: extractFilterFromCLPItem(linkedItem),
    };
  });
}

function extractBannerFromCLPItem(linkedItem) {
  return {
    title: linkedItem.elements.title,
    description: linkedItem.elements.description,
    cta: linkedItem.elements.primary_cta,
    image: linkedItem.elements.image,
    trackAction: linkedItem.elements.banner___track_action,
    informationText: linkedItem.elements.banner___information_text,
    flipImage: linkedItem.elements.banner___flip_image,
    replacements: linkedItem.elements.banner___replacements,
    theme: linkedItem.elements.banner___theme_variant,
  };
}

function extractBadgeFromCLPItem(linkedItem) {
  const badge = linkedItem.elements.badge.linkedItems[0].elements;

  if (!badge) {
    return null;
  }

  return {
    theme: badge.badge___theme_variant,
    priority: badge.badge___priority,
    iconName: badge.badge___icon_name,
    text: badge.label,
    popUpText: badge.badge___pop_up_text,
    trackAction: badge.badge___track_action,
  };
}

function extractFilterFromCLPItem(linkedItem) {
  const filter = linkedItem.elements?.filter?.linkedItems?.[0]?.elements;

  if (!filter) {
    return null;
  }

  return {
    text: filter.label,
    popUpText: filter.filter___pop_up_text,
    trackAction: filter.filter___track_action,
    promoText: filter.filter___promo_text,
    promoTheme: filter.filter___promo_theme,
  };
}

const DataQueryOptions = {
  staleTime: 300000,
  cacheTime: 600000,
  refetchOnWindowFocus: false,
};

export default {
  name: QUERY_NAME,
  fn: DataQueryFn,
  options: DataQueryOptions,
};
