import styled, { css } from 'styled-components';
import { media } from 'app/styles/variables';

interface ILinkStProps {
  isBlock?: boolean;
  alwaysFullWidth?: boolean;
  mobileFullWidth?: boolean;
  noHover?: boolean;
}

export const LinkSt = styled.a<ILinkStProps>`
  text-decoration: none;

  &,
  &:active,
  &:visited {
    color: ${({ theme }) => theme.colors.primary500};
  }

  &:hover {
    color: ${({ noHover, theme }) => (!noHover ? theme.colors.primary700 : '')};
    text-decoration: ${({ noHover }) => (!noHover ? 'underline' : '')};
  }

  ${({ isBlock }) => (isBlock ? 'display: block;' : '')}
  width: ${({ mobileFullWidth, alwaysFullWidth }) =>
    mobileFullWidth || alwaysFullWidth ? '100%' : 'auto'};

  ${media.sm(css`
    width: ${({ alwaysFullWidth }: ILinkStProps) =>
      alwaysFullWidth ? '100%' : 'auto'};
  `)};
`;
