export const BASE_ROUTES = {
  clp: '/coches-segunda-mano',
  km0: '/coches-km0',
  pdp: '/vehicle',
  checkout: '/checkout',
  about: '/sobre-heycar',
  faq: '/preguntas-frecuentes',
  homepage: '/',
  terms: '/terms',
  d2cterms: '/condiciones-generales-reserva-compra',
  imprint: '/imprint',
  privacy: '/privacy',
  contact: '/contact',
  modelpage: '/model',
  deal: '/deal',
  downtime: '/temp-unavailable',
  page404: '/404',
  Favorites: '/favorites',
  cookiedashboard: '/cookie-dashboard',
  cookiepolicy: '/politica-de-cookies',
  blog: '/blog',
  blogReviews: '/blog/opiniones',
  promotions: '/promociones',
  clppromotions: '/coches-segunda-mano/promociones',
  favorites: '/favorites',
  settings: '/settings',
  continueHoca: '/hoca',
  startHoca: '/vehicle',
  news: '/noticias',
  guides: '/guias',
};

export const useUrl = (route: string): string => {
  if (!route) {
    return '/';
  }

  const lcRoute = route.toLowerCase();

  const finalRoute = BASE_ROUTES[`page.${lcRoute}`] || BASE_ROUTES[lcRoute];

  if (typeof finalRoute === 'undefined') {
    throw new TypeError(`${route} does not exist in ROUTES.`);
  }

  return finalRoute;
};
