import {
  useEffect,
  useState,
  useContext,
  createContext,
  ReactNode,
  ReactElement,
} from 'react';
import { get, set } from 'js-cookie';
import { GUID_EXPIRES } from 'next-app/app/config';
import {
  setTrackingVersion,
  trackConnectionQuality,
  trackIdentify,
  trackTimeToInteraction,
  trackPage,
} from 'app/utils/tracking';
import { useCookiesSettings } from 'app/providers/CookiesSettingsProvider';
import { guidCookieName } from 'next-app/server/middleware/guid';

interface ITrackingContext {
  guid: string;
}

export const TrackingContext = createContext<ITrackingContext>({
  guid: '',
});

interface ITrackingProviderProps {
  children?: ReactNode;
}

const TrackingProvider = ({
  children,
}: ITrackingProviderProps): ReactElement => {
  const { marketing } = useCookiesSettings();

  const [guid] = useState(get(guidCookieName));

  useEffect(() => {
    set(guidCookieName, guid, { expires: GUID_EXPIRES });
  }, []);

  useEffect(() => {
    if (!PRODUCTION || !marketing || !guid) {
      return;
    }

    window.loadSegment();
    trackIdentify(guid);
    setTrackingVersion();
    trackConnectionQuality();
    trackTimeToInteraction();
    trackPage(guid);
  }, [marketing]);

  return (
    <TrackingContext.Provider value={{ guid }}>
      {children}
    </TrackingContext.Provider>
  );
};

const useTrackingContext = () => useContext(TrackingContext);

export { TrackingProvider, useTrackingContext };
