import { ReactNode } from 'react';
import { NavBarInnerSt } from './NavBanner.css';

interface NavBannerProps {
  firstElement: ReactNode;
  secondElement: ReactNode;
}

const NavBanner = ({ firstElement, secondElement }: NavBannerProps) => {
  return (
    <NavBarInnerSt>
      {firstElement}
      {secondElement}
    </NavBarInnerSt>
  );
};

export { NavBanner };
