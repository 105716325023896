import { IUserData, trackAction as trackActionFn } from 'app/utils/tracking';
import { usePageContext } from 'app/shared/components/PageWrapper/providers/PageProvider/PageProvider';
import { useCallback } from 'react';

interface IUseTrackingResult {
  trackAction?: (
    eventName: string,
    basicProperties?: any,
    additionalProperties?: any,
    userData?: IUserData,
    logTracker?: boolean,
  ) => void;
}

const useTrackingPage = (): IUseTrackingResult => {
  const { pageId } = usePageContext();

  const trackAction = useCallback(
    (
      eventName,
      basicProperties: any = {},
      additionalProperties: any = {},
      userData: IUserData = null,
      logTracker = false,
    ) => {
      const { category, ...props } = basicProperties;
      return trackActionFn(
        eventName,
        { category: category || pageId, ...props },
        additionalProperties,
        userData,
        logTracker,
      );
    },
    [pageId],
  );

  return { trackAction };
};

export { useTrackingPage };
