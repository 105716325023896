import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export enum PageId {
  CLP = 'CLP',
  PDP = 'PDP',
  FAV = 'FAV',
  HOME_PAGE = 'HOME_PAGE',
  LOGIN_PAGE = 'LOGIN_PAGE',
  LANDING_PAGE = 'LANDING_PAGE',
  BLOG_PAGE = 'BLOG_PAGE',
  GUIDE_PAGE = 'GUIDE_PAGE',
  GUIDES_HUB_PAGE = 'GUIDES_HUB_PAGE',
  NEWS_HUB_PAGE = 'NEWS_HUB_PAGE',
  NEWS_PAGE = 'NEWS_PAGE',
  REVIEWS_HUB_PAGE = 'REVIEWS_HUB_PAGE',
  INSURANCE_LP = 'INSURANCEPAGE',
  INSURANCE_ORDER = 'insurance_hub',
  COOKIE_DASHBOARD = 'cookieDashboard',
  UNKNOWN = 'UNKNOWN',
  TEMP_UNAVAILABLE = 'TEMPUNAVAILABLE',
  NOT_FOUND = '404',
  SETTINGS = 'settings',
  LOGIN = 'login',
  CONTINUE_HOCA = 'CONTINUE_HOCA',
  START_HOCA = 'START_HOCA',
}

interface IUsePageIdProps {
  defaultPageId?: string;
}

export interface IUsePageIdResult {
  pageId?: PageId | string;
}

const usePageId = ({ defaultPageId }: IUsePageIdProps): IUsePageIdResult => {
  const router = useRouter();

  const [pageId, setPageId] = useState<PageId | string>(
    defaultPageId || PageId.UNKNOWN,
  );

  useEffect(() => {
    setPageId(defaultPageId || PageId.UNKNOWN);
  }, [defaultPageId, router.asPath]);

  return { pageId };
};

export { usePageId };
