import { NextRouter } from 'next/router';
import qs from 'query-string';
import get from 'lodash.get';
import { buildCanonicalForm } from 'app/containers/CLP/utils';
import bodyTypeList from 'app/containers/CLP/components/SideBarFilters/BodyTypeFilter/bodyTypes.json';
import fuelTypeList from 'app/containers/CLP/components/SideBarFilters/FuelBoxFilter/fuelTypes.json';
import gearBoxList from 'app/containers/CLP/components/SideBarFilters/GearBoxFilter/gearBoxes.json';
import seatsCountList from 'app/containers/CLP/components/SideBarFilters/SeatsFilter/seatCount.json';
import { getAllProvinces } from 'app/utils/getProvinces';
import {
  getProvinceCanonicalPathValue,
  getSeatsCanonicalPathValue,
} from 'app/utils/path';

const provinceList = getAllProvinces();

export const isFilterVisible = (
  currentMin: number,
  currentMax: number,
  min: number,
  max: number,
): boolean => currentMin !== min || currentMax !== max;

export const isFilterListVisible = (currentFilterList: string[]): boolean =>
  currentFilterList && currentFilterList.length > 0;

export interface QueryParamsMap {
  [key: string]: string | string[] | boolean;
}

export const getFirstItemIfArray = (data: string | string[]): string =>
  Array.isArray(data) ? data[0] : data;

export const toggleListItemSelection = <T>(
  item: T,
  itemList: T[] = [],
  equalCheckFn: (a: T, b: T) => boolean = (a, b) => a === b,
): T[] => {
  if (!item) {
    return itemList;
  }

  return containsItem() ? removeItem() : addItem();

  function containsItem() {
    return itemList.some(i => equalCheckFn(i, item));
  }

  function removeItem() {
    return itemList.filter(i => !equalCheckFn(i, item));
  }

  function addItem() {
    return [...itemList, item];
  }
};

export const replaceItem = <T>(
  itemList: T[] = [],
  updatedItem: T,
  equalCheckFn: (a: T, b: T) => boolean = (a, b) => a === b,
): T[] => {
  if (!updatedItem) {
    return itemList;
  }

  return itemList.map(item =>
    equalCheckFn(item, updatedItem) ? updatedItem : item,
  );
};

export const upsertItem = <T>(
  itemList: T[] = [],
  item: T,
  equalCheckFn: (a: T, b: T) => boolean = (a, b) => a === b,
): T[] => {
  if (!item) {
    return itemList;
  }

  return containsItem() ? replaceItem(itemList, item, equalCheckFn) : addItem();

  function containsItem() {
    return itemList.some(i => equalCheckFn(i, item));
  }

  function addItem() {
    return [...itemList, item];
  }
};

export const updateQueryParams = (
  router: NextRouter,
  newQueryParams: QueryParamsMap,
  keysToRemove: Array<string> = [],
): void => {
  const newUrl = buildCanonicalForm(
    router,
    {
      ...newQueryParams,
    },
    ['page', ...keysToRemove],
  );

  router.push(newUrl, undefined, { scroll: false });
};

export const getFilterList = (router, filterName, FILTER_LIST) => {
  const { query: existingQuery } = qs.parseUrl(router.asPath, {
    arrayFormat: 'comma',
  });
  const { [filterName]: currentFilterList }: QueryParamsMap = existingQuery;
  const list = currentFilterList || FILTER_LIST;
  // We do the split here because the 'query-string' library has a bug that it doesn't properly
  // decode comma separated array values in SSR, so we manually do this here
  const filterList: string[] = Array.isArray(list)
    ? list
    : (list || '').split(',');

  return filterList;
};

/**
 * this function resolves canonical position of filters, because they are dynamic, as examples:
 * make/model/bodyType/fuelType - make/model - make - bodyType - make/bodyType - make/model/bodyType/fuelType
 * if a new filter is added, it should be updated.
 */
export const resolveCanonicalPosition = (query, options = { strict: true }) => {
  let positions = {
    bodyTypePosition: -1,
    makePosition: -1,
    modelPosition: -1,
    fuelTypePosition: -1,
    provincePosition: -1,
    gearTypePosition: -1,
    onlineReservationPosition: -1,
    allPromotionsPosition: -1,
    seatCountPosition: -1,
  };

  if (get(query, 'path')) {
    const unknownPathParameters = ['makePosition', 'modelPosition'];
    positions = query.path
      .map(segment => {
        const position = getBestMatchForSegment(segment, options);
        if (typeof position === 'string') {
          return position;
        }
        if (position === null) {
          return unknownPathParameters.shift();
        }
        return null;
      })
      .reduce((acc, position, index) => {
        if (position) {
          return {
            ...acc,
            [position]: index,
          };
        }
        return acc;
      }, positions);
  }
  return positions;

  function getBestMatchForSegment(
    segment: string,
    options = { strict: true },
  ): string | null | undefined {
    if (bodyTypeExistsInSegment(segment)) return 'bodyTypePosition';

    if (fuelTypeExistsInSegment(segment)) return 'fuelTypePosition';

    if (!options?.strict && segment.startsWith('provincia-')) {
      return 'provincePosition';
    }

    if (provinceExistsInSegment(segment)) return 'provincePosition';

    if (gearBoxExistsInSegment(segment)) return 'gearTypePosition';

    if (onlineReservationExistsInSegment(segment))
      return 'onlineReservationPosition';

    if (allPromotionsExistsInSegment(segment)) return 'allPromotionsPosition';

    if (!options?.strict && segment.startsWith('asientos-')) {
      return 'seatCountPosition';
    }
    if (seatCountExistsInSegment(segment)) return 'seatCountPosition';

    return null;
  }

  function bodyTypeExistsInSegment(segment: string): boolean {
    return bodyTypeList.map(({ urlName }) => urlName).includes(segment);
  }

  function fuelTypeExistsInSegment(segment: string): boolean {
    return fuelTypeList.map(({ urlName }) => urlName).includes(segment);
  }

  function provinceExistsInSegment(segment: string): boolean {
    return provinceList
      .map(({ key }) => getProvinceCanonicalPathValue(key as string))
      .includes(segment);
  }

  function gearBoxExistsInSegment(segment: string): boolean {
    return gearBoxList.map(({ urlName }) => urlName).includes(segment);
  }

  function onlineReservationExistsInSegment(segment: string): boolean {
    return ['compra-online'].includes(segment);
  }

  function allPromotionsExistsInSegment(segment: string): boolean {
    return ['promociones'].includes(segment);
  }

  function seatCountExistsInSegment(segment: string): boolean {
    return seatsCountList
      .map(({ urlName }) => getSeatsCanonicalPathValue(urlName as string))
      .includes(segment);
  }
};

export const parseNumberFilter = (
  queryParamValue: string | string[],
  defaultValue: number,
): number => {
  const parsedValue = parseInt(getFirstItemIfArray(queryParamValue), 10);
  if (parsedValue || parsedValue === 0) {
    return parsedValue;
  } else {
    return defaultValue;
  }
};
