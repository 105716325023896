/**
 * Get the enviroment variable. Variables set in the local .env file are set on the window
 * @param  {string} env enviroment variable name
 * @return {string} enviroment variable value
 */
export const getEnv = (env: string): string => {
  if (
    typeof process !== 'undefined' &&
    typeof process.env[env] !== 'undefined'
  ) {
    return process.env[env];
  }

  if (typeof window !== 'undefined' && typeof window[env] !== 'undefined') {
    return `${window[env]}`;
  }

  return null;
};
