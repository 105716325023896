import axios, { Method } from 'axios';
import qs from 'query-string';
import { API_URL } from 'next-app/app/config';
import { get as getFromCookie } from 'js-cookie';
import { v4 as uuid } from 'uuid';
import { guidCookieName } from 'next-app/server/middleware/guid';

export interface APIParams {
  apiUrl?: string;
  path: string;
  params?: any;
  data?: any;
  method?: Method;
  headers?: any;
}

const guid = getFromCookie(guidCookieName) || uuid();

const apiClient = ({
  apiUrl = API_URL,
  path,
  params,
  data,
  method = 'POST',
  headers,
}: APIParams) => {
  const url = `${apiUrl}${path}`;
  headers = headers ? headers : {};

  addHeaderIfNotNull('X-Tracking-Guid', (guid || '').replace(/-/g, ''));
  addHeaderIfNotNull('X-Heycar-Tenant', 'ES');

  return axios({
    url,
    method,
    params,
    paramsSerializer: params =>
      qs.stringify(params, { arrayFormat: 'comma', skipNull: true }),
    data,
    responseType: 'json',
    headers,
  }).then(({ data: resultData }) => resultData);

  function addHeaderIfNotNull(name, value): void {
    if (headers[name] === null) {
      delete headers[name];
      return;
    }
    headers[name] = value;
  }
};

export default apiClient;
