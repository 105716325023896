import { Request, Response, NextFunction } from 'express';
import { v4 as uuid } from 'uuid';

export const guidCookieName = 'guid';

export const guidMiddleware = (
  req: Partial<Request>,
  res: Partial<Response>,
  next: NextFunction,
): void => {
  // check if we already have a guid in a cookie, otherwise generate a new one
  const guidInit = req.cookies[guidCookieName] || uuid();
  const guid = guidInit.replace(/-/g, '');

  // on every request we make sure that the cookie will expire in 1 month
  const expiresDate = new Date();
  expiresDate.setMonth(expiresDate.getMonth() + 1);
  res.cookie(guidCookieName, guid, {
    expires: expiresDate,
  });

  next();
};
