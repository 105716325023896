import { VEHICLE_STATE, VehicleListingData } from 'app/apiCalls/vehicle';

export const getStatus = (vehicle: VehicleListingData) => {
  const disabled = vehicle.state !== VEHICLE_STATE.PUBLISHED;
  const booked = vehicle.booked;

  return { disabled, booked, available: !disabled && !booked };
};

export const getPrices = (vehicle: VehicleListingData) => {
  const { price, oldPrice } = vehicle || {};
  const isPriceReduced = oldPrice && oldPrice > price;
  const fullPrice = isPriceReduced ? oldPrice : price;
  const saving = priceReduction();
  const showPriceReduction =
    isPriceReduced && saving >= 100 && percentagePriceReduction() <= 50;

  return { price, showPriceReduction, fullPrice, saving };

  function priceReduction() {
    return Math.floor(fullPrice - price);
  }

  function percentagePriceReduction() {
    return ((fullPrice - price) / fullPrice) * 100;
  }
};

const CANARY_ISLANDS_POSTAL_CODE_PREFIX = [38, 35];

export function isVehicleFromCanaryIslands(
  vehicle: VehicleListingData,
): boolean {
  return CANARY_ISLANDS_POSTAL_CODE_PREFIX.some(item =>
    vehicle?.dealer?.location?.zip?.startsWith(`${item}`),
  );
}
