import { IconWrapperSt } from 'app/shared/components/IconWrapper/IconWrapper.css';
import { MouseEvent, ReactNode } from 'react';
import { colorValue } from 'app/styles/colors';

interface IIconWrapperProps {
  children: ReactNode;
  color?: colorValue;
  onClick?: (e: MouseEvent) => void;
  className?: string;
  ariaLabel?: string;
}

export const IconWrapper = ({
  children,
  color,
  onClick,
  className,
  ariaLabel,
}: IIconWrapperProps) => {
  return (
    <IconWrapperSt
      color={color}
      onClick={onClick}
      className={className}
      aria-label={ariaLabel}
    >
      {children}
    </IconWrapperSt>
  );
};
