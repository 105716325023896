import styled from 'styled-components';
import { Image } from 'app/shared/components/Image/Image';
import { HeartDefault } from '@heycar-uikit/icons';

export const BoldSt = styled.span`
  font-weight: bold;
`;
export const ItalicSt = styled.span`
  font-style: italic;
`;
export const UnderlineSt = styled.span`
  text-decoration: underline;
`;

export const WrapperSt = styled.span`
  & + & {
    display: block;
  }
`;

export const ImageSt = styled(Image)`
  margin: 0 0.5rem 0.25rem 0;
  max-height: 1rem;
`;

export const InspectTranslationSt = styled.div`
  display: inline;
  border: 1px dashed red;
`;

export const InlineIconSt = styled(HeartDefault)`
  display: inline;
`;

export const UnorderedListSt = styled.ul``;

export const ListItemSt = styled.li``;
