import { ReactNode, ReactElement, MouseEvent } from 'react';
import {
  ButtonColor,
  ButtonVariant,
} from 'app/shared/components/Button/constants';
import { HeycarButtonSt } from 'app/shared/components/Button/Button.css';

export type ButtonType = 'button' | 'submit' | 'reset';

export interface IButtonProps {
  qaID?: string;
  className?: string;
  color?: ButtonColor;
  variant?: ButtonVariant;
  size?: 'small' | 'large';
  alwaysFullWidth?: boolean;
  mobileFullWidth?: boolean;
  children: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  onClick?: (e: MouseEvent) => void;
  type?: ButtonType;
}
export const Button = ({
  qaID,
  className,
  children,
  color,
  variant,
  size,
  alwaysFullWidth,
  mobileFullWidth,
  loading,
  disabled,
  leftIcon,
  rightIcon,
  onClick,
  type,
}: IButtonProps) => {
  return (
    <HeycarButtonSt
      $mobileFullWidth={mobileFullWidth}
      type={type}
      dataTestId={qaID}
      className={className}
      color={color}
      variant={variant}
      size={size}
      fullWidth={alwaysFullWidth}
      loading={loading}
      disabled={disabled}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      onClick={onClick}
    >
      {children}
    </HeycarButtonSt>
  );
};
