import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import {
  updateQueryParams,
  isFilterVisible,
  QueryParamsMap,
  parseNumberFilter,
} from '../utils';
import { resolvePathParameters } from 'app/utils/path';

export const MILEAGE_MIN = 0;
export const MILEAGE_MAX = 150000;
export const MILEAGE_STEP = 1000;
export const LOW_MILEAGE_MIN = 0;
export const LOW_MILEAGE_MAX = 1000;

export const useMileage = () => {
  const router = useRouter();
  const { mileageMin, mileageMax }: QueryParamsMap = router.query;

  const isLowMileageEnabled = useMemo(
    () => !!resolvePathParameters(router.query, router.asPath).lowMileage,
    [router.query, router.asPath],
  );

  const clearMileage = useCallback(() => {
    updateQueryParams(router, {}, ['mileageMin', 'mileageMax']);
  }, [router, router.pathname, router.query]);

  const setMileage = useCallback(
    ({ min, max }) => {
      if (!isFilterVisible(min, max, MILEAGE_MIN, MILEAGE_MAX)) {
        clearMileage();
      } else {
        const isLowMileage = min === LOW_MILEAGE_MIN && max === LOW_MILEAGE_MAX;

        updateQueryParams(
          router,
          {
            mileageMin: isLowMileage ? undefined : min,
            mileageMax: isLowMileage ? undefined : max,
            lowMileage: isLowMileage || undefined,
          },
          isLowMileageEnabled ? ['lowMileage'] : [],
        );
      }
    },
    [router, router.pathname, router.query, isLowMileageEnabled],
  );

  const min = isLowMileageEnabled
    ? LOW_MILEAGE_MIN
    : parseNumberFilter(mileageMin, MILEAGE_MIN);
  const max = isLowMileageEnabled
    ? LOW_MILEAGE_MAX
    : parseNumberFilter(mileageMax, MILEAGE_MAX);

  const isMileageVisible = isFilterVisible(min, max, MILEAGE_MIN, MILEAGE_MAX);

  return {
    min,
    max,
    setMileage,
    clearMileage,
    isMileageVisible,
  };
};
