import { PageContent } from '@hey-car/intl-seo-hub.page-builder';

interface Path {
  params: { slug: string };
}

interface PathArray {
  params: { slug: string[] };
}

interface StaticPaths<T> {
  paths: T[];
  fallback: string;
}

export interface IPageData {
  pageData: PageContent;
  kenticoProjectId: string;
}

export async function getServerSideProps(
  params: { slug: string },
  getPageData: (slug: string) => Promise<PageContent>,
) {
  const pageData = await getPageData(params.slug);
  return pageData
    ? {
        props: {
          pageData,
          kenticoProjectId: process.env.KENTICO_OTP_PROJECT_ID,
        },
      }
    : { notFound: true };
}

export async function getStaticProps(
  params: { slug: string },
  getPageData: (slug: string) => Promise<PageContent>,
) {
  const pageData = await getPageData(params.slug);
  return pageData
    ? {
        props: {
          pageData,
          kenticoProjectId: process.env.KENTICO_OTP_PROJECT_ID,
        },
        revalidate: 60,
      }
    : { notFound: true, revalidate: 60 };
}

export async function getStaticPropsSlugArray(
  params: { slug: string[] },
  getPageData: (slug: string) => Promise<PageContent>,
) {
  return getStaticProps({ slug: params.slug.join('/') }, getPageData);
}

export async function getStaticPaths(
  getPageSlugs: () => Promise<string[]>,
): Promise<StaticPaths<Path>> {
  return getPageSlugs().then(() => ({
    paths: [],
    fallback: 'blocking',
  }));
}

export async function getStaticPathsSlugArray(
  getPageSlugs: () => Promise<string[]>,
): Promise<StaticPaths<PathArray>> {
  return getPageSlugs()
    .then(slugs => slugs.map(slug => slug.split('/')))
    .then(() => ({
      paths: [],
      fallback: 'blocking',
    }));
}

export function transformToBoolean(item) {
  return item?.value?.[0]?.codename === 'true';
}

export function transformToDropdownValue(item) {
  return item?.value?.[0];
}

export function transformToImageURL(item) {
  return item?.value?.[0]?.url;
}

export function transformToString(item, { removeParagraphs = false } = {}) {
  if (!removeParagraphs) {
    return item?.value;
  }
  return removeParagraph(item?.value);
}

export function transformToJSON(item) {
  return JSON.parse(item.value);
}

export function transformList(item, cb) {
  return item.linkedItems.map(usp => cb(usp));
}

function removeParagraph(text = '') {
  return text.replace('<p>', '').replace('</p>', '');
}
