import styled, { css } from 'styled-components';
import { media } from 'app/styles/variables';
import { Button } from 'app/shared/components/Button/Button';

export const CookieBannerWrapperSt = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  ${media.sm(css`
    width: 27.5rem;
  `)};
`;

export const CookieBannerComplaintTitleSt = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  padding: 10px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.h5};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const ContentSt = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.neutral800};
  text-align: center;
  ${media.sm(css`
    margin-bottom: 3rem;
  `)};
`;

export const ContentBodySt = styled.div`
  color: ${({ theme }) => theme.colors.neutral800};

  ${media.sm(css`
    margin-bottom: 3rem;
  `)};
`;

export const BottomBarSt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ConsentPersonalButtonSt = styled(Button)`
  margin-bottom: 1rem;
`;

export const ConsentAcceptAllButtonSt = styled(Button)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.blue};
`;

export const CustomizeSettingsLinkSt = styled.span`
  color: ${({ theme }) => theme.colors.primary600};
`;
