import styled, { createGlobalStyle, css } from 'styled-components';

import { BODY_OVERLAY_LOCK_CLASS } from 'app/shared/components/Overlay/Overlay';

import {
  media,
  fontWeights,
  GenerateCSSColorVariables,
} from './styles/variables';
import { attributeColor } from './styles/mixins';

export const GlobalStyle = createGlobalStyle`${css`
  ${GenerateCSSColorVariables()}

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    outline: none;
  }

  html {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    margin: 0;
    font-family: 'Objektiv', sans-serif, 'Helvetica Neue', Helvetica, Arial;
    line-height: 1.71;

    &.overlay {
      overflow: hidden;
      height: 100vh;
    }

    &.mobile-overlay {
      overflow: hidden;
      height: 100vh;
    }

    ${media.sm(css`
      line-height: 2;

      &.mobile-overlay {
        overflow: visible;
        height: auto;
      }
    `)};

    ${attributeColor('color', 'neutral700')}
    ${attributeColor('background-color', 'white')}
  }

  ${`body.${BODY_OVERLAY_LOCK_CLASS}`} {
    overflow: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: normal;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  a {
    ${attributeColor('color', 'primary500')}
    text-decoration: none;

    &:hover,
    &:focus {
      ${attributeColor('color', 'primary')}
      text-decoration: none;
    }

    &:focus {
      /* WebKit-specific. Other browsers will keep their default outline style.
    (Initially tried to also force default via 'outline: initial',
    but that seems to erroneously remove the outline in Firefox altogether.) */
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  b,
  strong {
    font-weight: ${fontWeights.bold};
  }

  img {
    max-width: 100%;
    vertical-align: middle;
  }

  input,
  button,
  select,
  textarea {
    font: inherit;
    line-height: inherit;
  }

  ::-webkit-input-placeholder {
    line-height: normal;
  }

  .button-reset {
    padding: 0;
    border: 0;
    background-color: transparent;
    border-radius: 0;
  }
  .PhoneInputInput {
    border: none;
    padding: 0;
    width: 100%;
  }
`}
`;

export const AppSt = styled.div`
  display: block;
`;
