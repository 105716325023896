import styled, { css, createGlobalStyle } from 'styled-components';
import { media } from 'app/styles/variables';
import { IconWrapper } from 'app/shared/components/IconWrapper/IconWrapper';
import { Typography } from 'app/shared/components/Typography/Typography';

export const GlobalStyle = createGlobalStyle`${css`
  html,
  body {
    overflow: hidden;
  }
`}
`;

type Direction = 'left' | 'right' | 'bottom';

interface IBackgroundStProps {
  disableShadow?: boolean;
  direction?: Direction;
  show: boolean;
  animated: boolean;
  animationDuration: number;
  zIndex?: number;
}

export const FullPageBackgroundSt = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;
  background: white;
`;

export const BackgroundSt = styled.div<IBackgroundStProps>`
  position: fixed;
  z-index: ${({ zIndex }) => zIndex};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;

  align-items: ${({ direction }) =>
    isLeftOrRight(direction) ? 'stretch' : 'center'};
  justify-content: ${({ direction }) =>
    isLeftOrRight(direction)
      ? direction === 'right'
        ? 'flex-end'
        : 'unset'
      : 'center'};
  background: ${({ disableShadow }) =>
    disableShadow ? 'none' : 'rgba(0, 0, 0, 0.8)'};
  ${({ animated, show, animationDuration }) =>
    animated && getAnimation(show, animationDuration)}

  ${media.sm(css`
    overflow: hidden;
  `)};

  @keyframes modalFadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes modalFadeOutAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

function getAnimation(show: boolean, animationDuration: number) {
  return `animation: ${
    show
      ? `modalFadeInAnimation ${animationDuration}ms ease-out;`
      : `modalFadeOutAnimation ${animationDuration + 10}ms ease-in;`
  }`;
}

interface IDialogStProps {
  width?: number;
  minHeight?: string;
  height?: number;
  maxHeight?: string;
  mobileAutoHeight: boolean;
  direction?: Direction;
}

export const MobileNonGutterFullPageDialogSt = styled.div`
  margin-right: -1rem;
  margin-left: -1rem;
  ${media.sm(css`
    margin-right: unset;
    margin-left: unset;
  `)};
`;

export interface IPaddingOptions {
  removePadding?: boolean;
}

const optionalPadding = padding => params =>
  params.removePadding ? '0' : padding;

export const GutterFullPageDialogSt = styled.div<IPaddingOptions>`
  padding-left: ${optionalPadding('1rem')};
  padding-right: ${optionalPadding('1rem')};

  ${media.sm(css`
    padding-left: ${optionalPadding('6rem')};
    padding-right: ${optionalPadding('6rem')};
  `)};
`;

export interface INavigationOption {
  includeNavigation: boolean;
}

export const FullPageDialogSt = styled(
  GutterFullPageDialogSt,
)<INavigationOption>`
  display: block;
  position: absolute;
  bottom: 0;
  top: ${({ includeNavigation }) => (includeNavigation ? '56px' : '0')};
  height: ${({ includeNavigation }) =>
    includeNavigation ? 'calc(100% - 56px)' : '100%'};
  width: 100%;
  flex-direction: column;
  padding-top: ${optionalPadding('1rem')};
  overflow: auto;
  scroll-behavior: smooth;
  ${media.sm(css`
    position: relative;
    align-items: center;
    padding-top: ${optionalPadding('2rem')};
    padding-bottom: ${optionalPadding('1.5rem')};
    width: 100%;
  `)};

  ${media.lg(css<INavigationOption>`
    top: ${({ includeNavigation }) => (includeNavigation ? '64px' : '0')};
    height: ${({ includeNavigation }) =>
      includeNavigation ? 'calc(100% - 64px)' : '100%'};
  `)};
`;

export const DialogSt = styled.div<IDialogStProps>`
  display: flex;
  margin: auto;
  width: ${({ direction }) => (isLeftOrRight(direction) ? 'auto' : '100%')};
  height: ${({ mobileAutoHeight }) => (mobileAutoHeight ? 'auto' : '100%')};
  flex-direction: column;
  padding: 1rem;
  background: white;
  overflow-y: auto;
  ${({ direction }) =>
    isLeftOrRight(direction) &&
    css`
      min-width: 314px;
      padding: 2rem 1rem;
    `}
  ${media.sm(css`
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    height: ${({ direction, height }) =>
      isLeftOrRight(direction) ? '100%' : height ? `${height}px` : 'auto'};
    max-height: ${({ height, maxHeight }: IDialogStProps) =>
      height || maxHeight
        ? `${maxHeight ? maxHeight : height + 'px'}`
        : '100%'};
    min-height: ${({ minHeight }) => (minHeight ? minHeight : 'auto')};
    padding: ${({ direction }) =>
      isLeftOrRight(direction) ? '2rem' : '2.5rem 2.5rem 1.5rem'};
  `)};
  -webkit-transform: translate3d(
    0,
    0,
    0
  ); /* a hacky solution for putting the scrollbar always on the top on Safari iPhone */
`;

export const NonGutterDialogContentSt = styled.div`
  margin: 0 -1rem;
  ${media.sm(css`
    margin: 0 -2.5rem;
  `)};
`;

export const GutterDialogContentSt = styled.div`
  padding: 0 1rem;
  ${media.sm(css`
    padding: 0 2.5rem;
  `)};
`;

interface IContentStProps {
  cropped?: boolean;
  direction?: Direction;
  fullPage?: boolean;
  isFromOCA?: boolean;
  containerStyles?: any;
}

export const FullPageContentSt = styled.div<IContentStProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: ${({ cropped }) => (cropped ? 'hidden' : 'visible')};
  ${media.sm(css<IContentStProps>`
    position: relative;
    width: 37.5rem;
    margin: 0 auto;
  `)};
  ${({ containerStyles }) => containerStyles}
`;

export const ContentSt = styled.div<IContentStProps>`
  display: flex;
  flex: ${({ direction }) => (direction === 'bottom' ? 'unset' : '1')};
  font-size: ${({ direction, theme }) =>
    direction === 'bottom' ? theme.fontSizes.p3 : theme.fontSizes.p};
  flex-direction: column;
  justify-content: space-between;
  overflow: ${({ cropped }) => (cropped ? 'hidden' : 'visible')};
  ${media.sm(css`
    font-size: ${({ theme }) => theme.fontSizes.p};
  `)};
`;

export const CloseButtonSt = styled.div`
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`;

export const FullPageTitleSt = styled.h3`
  margin-top: 0.5rem;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 200;
  line-height: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};

  ${media.sm(css`
    margin-top: 1.5rem;
  `)};
`;

export const TitleSt = styled(Typography).attrs({ variant: 'h4' })`
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};

  ${media.sm(css`
    margin-top: 1.5rem;
  `)};
`;

export const FullPageCloseButton = styled(IconWrapper)`
  cursor: pointer;
`;

function isLeftOrRight(direction) {
  return ['left', 'right'].includes(direction);
}
