import { ReactElement, useState, MouseEventHandler } from 'react';
import Lazy from '../Lazy/Lazy';
import { ImageSt } from './Image.css';

export interface ImageSrcData {
  src?: string;
  defaultSrc?: string;
  srcSet?: string;
}

export interface ImageProps extends ImageSrcData {
  className?: string;
  width?: number;
  height?: number;
  sizes?: string;
  alt?: string;
  ariaLabel?: string;
  lazy?: boolean;
  rootMargin?: string;
  url?: string;
  rootRef?: any;
  onClick?: MouseEventHandler<HTMLImageElement>;
  dataTestId?: string;
}

const Image = ({
  className,
  width,
  height,
  src,
  defaultSrc,
  srcSet,
  alt,
  ariaLabel,
  rootMargin,
  lazy,
  rootRef,
  onClick,
  dataTestId,
}: ImageProps): ReactElement => {
  const [error, setError] = useState(false);

  const loadFallback = () => {
    setError(true);
  };

  const content = (
    <>
      {error ? (
        <ImageSt
          onClick={onClick}
          className={className}
          width={width}
          height={height}
          alt={alt}
          src={defaultSrc}
          aria-label={ariaLabel}
          srcSet={defaultSrc}
          data-testid={dataTestId}
        />
      ) : (
        <ImageSt
          onClick={onClick}
          className={className}
          width={width}
          height={height}
          alt={alt}
          src={src}
          aria-label={ariaLabel}
          srcSet={srcSet}
          onError={() => loadFallback()}
          data-testid={dataTestId}
        />
      )}
    </>
  );

  return lazy ? (
    <Lazy rootRef={rootRef} rootMargin={rootMargin}>
      {content}
    </Lazy>
  ) : (
    content
  );
};

export { Image };
