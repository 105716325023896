import {
  css,
  FlattenInterpolation,
  ThemedStyledProps,
} from 'styled-components';
import {
  fontSizes,
  fontSizesMobile,
  lineHeightsMobile,
  media,
} from './variables';
import { colors, colorValue } from 'app/styles/colors';

interface ITextInputMixin {
  error: Error;
  theme: any /* To-do: create interface for theme object */;
}

export interface BaseStylesProps {
  error?: Error;
  hasUnit?: boolean;
}

export type ColorName = keyof typeof colors;
export type ColorAttribute =
  | 'color'
  | 'background-color'
  | 'background'
  | 'border-color';

/**
 * These mixins help to use the CSS variables for easier debugging and
 * also use the hex as fallback for IE11
 */
export const attributeColor = (
  attribute: ColorAttribute,
  colorName: ColorName,
): string =>
  `${attribute}: ${colors[colorName]};
${attribute}: var(--${colorName});`;

export type FontSizingElements = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

/**
 * This is a mixing to automatically add the font-size and line-height for
 * the provided element along with the responsive changes
 */
export const generateFontSizing = (element: FontSizingElements): string =>
  `font-size: ${fontSizesMobile[element]};
line-height: ${lineHeightsMobile[element]};

${media.sm(css`
  font-size: ${fontSizes[element]};
  line-height: ${({ theme }) => theme.lineHeights[element]};
`)}
`;

export type BrandColorNames =
  | 'heycar Mint'
  | 'Mica Blue'
  | 'Sunbeam Blue'
  | 'White';

type BrandColorMapType = Record<BrandColorNames, colorValue>;

export const BrandColorsMap: BrandColorMapType = {
  'heycar Mint': colors.secondary,
  'Mica Blue': colors.primary,
  'Sunbeam Blue': colors.tertiary,
  White: colors.white,
};

export const renderBrandColor = (color?: BrandColorNames) => {
  return css`
    ${() => BrandColorsMap[color]}
  `;
};

export const textInputStyle = (
  baseStyles: FlattenInterpolation<ThemedStyledProps<BaseStylesProps, any>>,
) => css`
  width: 100%;
  height: 2.125rem;
  ${baseStyles}

  &:not(:placeholder-shown) + label {
    transform: translate(0, 0) scale(0.75);
  }

  &:focus + label,
  &:focus-within + label {
    transform: translate(0, 0) scale(0.75);
    color: ${({ error, theme }: ITextInputMixin) =>
      error ? theme.colors.error500 : theme.colors.secondary};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  border-radius: 0;
`;
