import '@heycar-uikit/themes/default.css';
import { css } from 'styled-components';
import { colors } from './colors';

export const GenerateCSSColorVariables = () => {
  const allColours = Object.keys(colors);
  const generatedVariables = allColours.map(
    name => `--${name}: ${colors[name]};`,
  );
  return `:root {
    ${generatedVariables.join('\n')}
  }`;
};

export const breakpoints = {
  xxs: 360, // default
  xs: 480,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1390,
  xxl: 1440,
};

export const grid = {
  columns: 12,
  margin: {
    sm: 24, // default
    md: 28,
    lg: 36,
    xl: 56,
  },
  gutter: {
    sm: 8, // default
    md: 16,
    lg: 16,
    xl: 16,
  },
};

export const fontSizesMobile = {
  h1: '40px',
  h2: '32px',
  h3: '24px',
  h4: '20px',
  h5: '18px',
  h6: '16px',

  p: '16px',
};

export const fontSizes = {
  h1: '48px',
  h2: '40px',
  h3: '32px',
  h4: '24px',
  h5: '20px',
  h6: '16px',

  p: '16px',
  p2: '14px',
  p3: '12px',
  p4: '10px',

  footnotes: '8px',
  tight: '14px',
};

export const lineHeightsMobile = {
  h1: '1.4',
  h2: '1.25',
  h3: '1.333',
  h4: '1.4',
  h5: '1.444',
  h6: '1.5',

  p: '1.5',
  p2: '1.71',
  p3: '1.333',
  p4: '1.6',
};

export const lineHeights = {
  h1: '1.6',
  h2: '1.4',
  h3: '1.25',
  h4: '1.333',
  h5: '1.4',
  h6: '1.5',

  p: '1.5',
  p2: '1.71',
  p3: '1.333',
  p4: '1.6',

  footnotes: '1.25',
  tight: '1.14',
};

export const fontWeights = {
  regular: 200,
  medium: 300,
  bold: 500,
  bolder: 700,
  extraBold: 800,
};

export const media: any = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (cls, isIE = false) =>
      css`
        ${isIE
          ? css`
              @media all and (min-width: ${breakpoints[
                  label
                ]}px) and (-ms-high-contrast: none) {
                ${cls};
              }
            `
          : css`
              @media (min-width: ${breakpoints[label]}px) {
                ${cls};
              }
            `};
      `;

    return accumulator;
  },
  {},
);

const zIndexModal = 1050;
const slightlyAbove = 10;
const stickyButton = 1040;

export const zIndex = {
  navbar: 200,
  modal: zIndexModal,
  overlay: zIndexModal,
  sidebar: zIndexModal,
  cookieBanner: 1060,
  homeBenefitsTitle: slightlyAbove,
  sliderLabel: 3,
  sliderLabelLine: 4,
  sliderLabelText: 5,
  stickyButton,
  notificationBar: stickyButton,
  switchButton: 3,
  switchLabel: 4,
  footer: slightlyAbove,
  moreLikeThis: slightlyAbove,
};
