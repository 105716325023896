import styled, { css } from 'styled-components';
import { media } from 'app/styles/variables';
import { Button } from 'app/shared/components/Button/Button';
import Link from 'app/shared/components/Link/Link';

export const CookieBannerWrapperSt = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  ${media.sm(css`
    width: 40rem;
  `)};
`;

export const ContentSt = styled.div`
  text-align: justify;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.neutral800};

  ${media.sm(css`
    margin-bottom: 2rem;
  `)};
`;

export const BottomBarSt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ViewMoreLinkSt = styled(Link)`
  align-self: center;
  padding-top: 1rem;
`;

export const ConsentButtonSt = styled(Button)``;

export const CustomizeSettingsLinkSt = styled.span`
  color: ${({ theme }) => theme.colors.primary600};
`;
