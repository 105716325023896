var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"lLDcqXz3R4sPCGR1J1_iy"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import {
  ENV_PRODUCTION,
  ENV_PR_STAGING,
  ENV_STAGING,
  SENTRY_DSN,
} from './src/next/app/config';
import { detectEnv } from './src/next/app/detectEnv';

const environment = detectEnv();
const enableSentry = [ENV_PRODUCTION, ENV_STAGING, ENV_PR_STAGING].includes(
  environment,
);

Sentry.init({
  dsn: SENTRY_DSN || '',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.05,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  enabled: enableSentry,
  environment,
});
