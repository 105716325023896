import {
  useState,
  createContext,
  useContext,
  useCallback,
  ReactNode,
  ReactElement,
} from 'react';
import { VehicleListingData } from 'app/apiCalls/vehicle';
import { generateAdditionalTrackingParams } from 'app/utils/tracking';
import { D2C_DATASOURCE } from 'next-app/app/config';
import { useTrackingPageContext } from 'app/shared/components/PageWrapper/providers/TrackingPageProvider/TrackingPageProvider';

interface ServicesContextProps {
  interestedInFinancing: boolean;
  selectedVehicleId: string;
  handleInterestedInFinancing: (
    value: boolean,
    vehicle: VehicleListingData,
  ) => void;
  interestedInNewtonRecom: boolean;
  handleInterestedInNewtonRecom: (
    value: boolean,
    vehicle: VehicleListingData,
  ) => void;
}

const ServicesProviderContext = createContext<ServicesContextProps>({
  interestedInFinancing: false,
  selectedVehicleId: null,
  handleInterestedInFinancing: () => {},
  interestedInNewtonRecom: false,
  handleInterestedInNewtonRecom: () => {},
});

const ServicesProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [interestedInFinancing, setInterestedInFinancing] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [interestedInNewtonRecom, setInterestedInNewtonRecom] = useState(false);

  const { trackAction } = useTrackingPageContext();

  const handleInterestedInFinancing = useCallback(
    // we can get rid the whole function in here and only use and expose setSelectedVehicleId
    (value: boolean, vehicle: VehicleListingData) => {
      const { dataSource } = vehicle;
      const isD2C = dataSource && dataSource === D2C_DATASOURCE;
      if (value) {
        setSelectedVehicleId(vehicle?.id);
        trackAction(
          'interest_financing_offer_added',
          {
            category: isD2C && 'OR',
            description: 'financing offer option added',
          },
          generateAdditionalTrackingParams(vehicle),
        );
      } else {
        setSelectedVehicleId(null);
        trackAction(
          'interest_financing_offer_removed',
          {
            category: isD2C && 'OR',
            description: 'financing offer option removed',
          },
          generateAdditionalTrackingParams(vehicle),
        ); // we need to pass this method into a util function and handle it inside ExtraCheckbox component better that each Lead form
      }

      setInterestedInFinancing(value || !interestedInFinancing); // we won't need this anymore
    },
    [interestedInFinancing, selectedVehicleId, trackAction],
  );

  const handleInterestedInNewtonRecom = useCallback(
    (value: boolean, vehicle: VehicleListingData) => {
      trackAction(
        `interest_newton_recommendations_${value ? 'added' : 'removed'}`,
        {
          description: `Newton recommendations option ${
            value ? 'added' : 'removed'
          }`,
        },
        generateAdditionalTrackingParams(vehicle),
      );
      setInterestedInNewtonRecom(!interestedInNewtonRecom);
    },
    [interestedInNewtonRecom, trackAction],
  );

  return (
    <ServicesProviderContext.Provider
      value={{
        interestedInFinancing,
        handleInterestedInFinancing,
        interestedInNewtonRecom,
        handleInterestedInNewtonRecom,
        selectedVehicleId,
      }}
    >
      {children}
    </ServicesProviderContext.Provider>
  );
};

const useServices = () => useContext(ServicesProviderContext);

export { ServicesProvider, useServices };
