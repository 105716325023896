import { useIntl } from 'react-intl';

export interface IUsePlainTranslateProps {
  id: string;
  defaultMessage?: string;
  description?: string;
  values?: Record<string, any>;
}

export const usePlainTranslate = () => {
  const { formatMessage } = useIntl();
  return ({
    id,
    defaultMessage,
    description,
    values,
  }: IUsePlainTranslateProps) => {
    return formatMessage(
      {
        id,
        defaultMessage: defaultMessage || id,
        description,
      },
      {
        ...values,
      },
    );
  };
};
