import HeycarTypography from '@heycar-uikit/typography';
import styled, { css } from 'styled-components';
import { breakpoints, media } from 'app/styles/variables';

const brandedHeaderBar = css`
  position: relative;
  ::before {
    content: '';
    height: 0.25rem;
    width: 3.375rem;
    position: absolute;
    top: -0.5rem;
    left: 0;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

interface ITypographyStProps {
  isBranded?: boolean;
}

export const TypographySt = styled(HeycarTypography).withConfig({
  shouldForwardProp: prop => !['isBranded'].includes(prop),
})<ITypographyStProps>`
  ${({ isBranded }) => (isBranded ? brandedHeaderBar : null)}
`;

export const DesktopTypographySt = styled(TypographySt)`
  @media (max-width: ${breakpoints.sm - 1}px) {
    display: none !important;
  }
`;

export const MobileTypographySt = styled(TypographySt)`
  ${media.sm(css`
    display: none !important;
  `)};
`;
