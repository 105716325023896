import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { ReactElement, ReactNode, useMemo } from 'react';
import { LinkSt } from './Link.css';
import { getEnv } from 'next-app/app/utils';

interface ILinkProps extends NextLinkProps {
  children: ReactNode;
  onClick?: () => void;
  href: string;
  isBlock?: boolean;
  alwaysFullWidth?: boolean;
  mobileFullWidth?: boolean;
  qaID?: string;
  noHover?: boolean;
  className?: string;
  blank?: boolean;
  forceAbsoluteHref?: boolean;
  withHref?: boolean;
  rel?: string;
}

const Link = ({
  className,
  children,
  href = '',
  isBlock,
  alwaysFullWidth,
  mobileFullWidth,
  qaID,
  noHover,
  blank,
  forceAbsoluteHref = false,
  withHref = false,
  rel,
  onClick,
  ...rest
}: ILinkProps): ReactElement => {
  const linkHref = useMemo(() => {
    if (forceAbsoluteHref) {
      return `${getEnv('BASE_URL')}${href}`;
    }
    if (withHref) {
      return href;
    }
    return '';
  }, [forceAbsoluteHref, withHref, href]);

  const link = (
    <LinkSt
      data-qa={qaID}
      isBlock={isBlock}
      alwaysFullWidth={alwaysFullWidth}
      mobileFullWidth={mobileFullWidth}
      noHover={noHover}
      className={className}
      target={blank && '_blank'}
      href={linkHref}
      rel={rel}
      onClick={onClick}
    >
      {children}
    </LinkSt>
  );
  return forceAbsoluteHref || withHref ? (
    link
  ) : (
    <NextLink href={href} passHref prefetch={false} {...rest}>
      {link}
    </NextLink>
  );
};

export default Link;
