import {
  ReactElement,
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react';
import { set, get } from 'js-cookie';
import { useRouter } from 'next/router';

const TOGGLE_TRANSLATION = 'toggle-translation';

interface ContextType {
  isInspected: boolean;
}

const TranslateInspectionContext = createContext<ContextType>({
  isInspected: false,
});

const TranslateInspectionProvider = ({ children }): ReactElement => {
  const [isInspected, setIsInspected] = useState(false);
  const { query } = useRouter();
  const { inspectTranslations } = query;

  useEffect(() => {
    if (inspectTranslations) {
      const isInspectTranslationsEnabled = inspectTranslations === 'true';
      setIsInspected(isInspectTranslationsEnabled);
      set(TOGGLE_TRANSLATION, isInspectTranslationsEnabled);
    } else {
      const inspectTranslationsFromCookie = get(TOGGLE_TRANSLATION);
      inspectTranslationsFromCookie === 'true'
        ? setIsInspected(true)
        : setIsInspected(false);
    }
  }, [inspectTranslations]);

  return (
    <TranslateInspectionContext.Provider
      value={{
        isInspected,
      }}
    >
      {children}
    </TranslateInspectionContext.Provider>
  );
};

const useTranslateInspection = () => useContext(TranslateInspectionContext);

export { TranslateInspectionProvider, useTranslateInspection };
