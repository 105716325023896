import { ReactElement, useCallback } from 'react';
import { useCookiesSettings } from 'app/providers/CookiesSettingsProvider';
import { Modal } from 'app/shared/components/Modal/Modal';
import {
  ContentSt,
  BottomBarSt,
  ConsentPersonalButtonSt,
  ConsentAcceptAllButtonSt,
  ContentBodySt,
  CustomizeSettingsLinkSt,
  CookieBannerWrapperSt,
  CookieBannerComplaintTitleSt,
} from './CookieComplaintBanner.css';
import { useTranslate } from 'app/shared/components/Translation/hooks/useTranslate/useTranslate';

const CookieComplaintBanner = (): ReactElement => {
  const {
    agreeToAllCookies,
    agreeOnlyPersonalCookies,
    hasSeenCookieConsent,
    openCookieComplaintBanner,
    setOpenCookieComplaintBanner,
  } = useCookiesSettings();

  const translate = useTranslate();

  const handleClose = useCallback(() => {
    setOpenCookieComplaintBanner(false);
  }, []);

  const handleAgreePersonalClick = useCallback(() => {
    agreeOnlyPersonalCookies();
  }, []);

  const handleAgreeAllClick = useCallback(() => {
    agreeToAllCookies();
  }, []);

  if (hasSeenCookieConsent) {
    return null;
  }

  return (
    <>
      <Modal
        onClose={handleClose}
        show={openCookieComplaintBanner}
        direction="bottom"
      >
        <CookieBannerWrapperSt>
          <CookieBannerComplaintTitleSt>
            {translate({
              id: 'containers.cookieDashboard.cookieComplaintBanner.title',
            })}
          </CookieBannerComplaintTitleSt>

          <ContentSt>
            {translate({
              id: 'containers.cookieDashboard.cookieComplaintBanner.body',
            })}
          </ContentSt>

          <ContentBodySt>
            {translate({
              id: 'containers.cookieDashboard.cookieComplaintBanner.body2',
            })}
          </ContentBodySt>

          <BottomBarSt>
            <ConsentPersonalButtonSt
              qaID="accept-personalise-cookies-btn"
              onClick={handleAgreePersonalClick}
            >
              <CustomizeSettingsLinkSt data-qa="customize-settings-link">
                {translate({
                  id: 'containers.cookieDashboard.cookieComplaintBanner.personalise',
                })}
              </CustomizeSettingsLinkSt>
            </ConsentPersonalButtonSt>

            <ConsentAcceptAllButtonSt
              qaID="accept-cookies-btn"
              onClick={handleAgreeAllClick}
            >
              {translate({
                id: 'containers.cookieDashboard.cookieComplaintBanner.accept',
              })}
            </ConsentAcceptAllButtonSt>
          </BottomBarSt>
        </CookieBannerWrapperSt>
      </Modal>
    </>
  );
};

export { CookieComplaintBanner };
