import get from 'lodash.get';
import slugify from 'slugify';
import { IOperatingTimesData } from 'app/apiCalls/dealer';
import { IMAGE_PROXY_URL } from 'next-app/app/config';

const SEO_URL_REGEX =
  /(?:[a-zA-Z0-9-]*)([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/;

export const generateSEOUrl = vehicle => {
  if (!vehicle || !vehicle.id) {
    return '';
  }
  const make = get(vehicle, 'make.displayName');
  const model = get(vehicle, 'model.trim');
  // We try to combine the existing props and add the final space for creating the final
  // hyphen before the vehicleId
  const seoSlug = slugify(
    [make, model, vehicle.year].filter(i => !!i).join('-'),
    { lower: true, strict: true },
  );
  const slugUrl = seoSlug ? `${seoSlug}-${vehicle.id}` : `${vehicle.id}`;
  return `/vehicle/${slugUrl}`;
};

export const parseSEOUrl = slugid => {
  const matches = slugid.match(SEO_URL_REGEX);

  // Basically if we have an incorrect url we should just throw a 404
  if (!matches || matches.length < 2) {
    return '';
  }

  const [, vehicleId] = matches;

  return vehicleId;
};

export const resizeImage = (url, size) =>
  `${IMAGE_PROXY_URL}/unsafe/${size}x/filters:quality(90)/${url}`;

export interface Location {
  street?: string;
  houseNumber?: string;
  zip?: string;
  city?: string;
  nuts3?: string;
}

const joinNotEmpty = (data = [], sep = ' ') => data.filter(i => i).join(sep);

export const getShortAddress = ({ zip, city }: Location) =>
  joinNotEmpty([zip, city], ', ');

export const getOpeningTimesByWeekDay = (
  openingTimes: IOperatingTimesData[] = [],
  weekDay?: number,
): IOperatingTimesData[] =>
  openingTimes.filter(({ dayOfWeek }) => dayOfWeek === weekDay);

const OpeningDays = {
  WEEKDAY: 1,
  SATURDAY: 6,
  SUNDAY: 7,
};

const DayOfWeek = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

const DayOfWeekToOpeningDays = {
  // week days are all mapped to monday, see HS-789
  [DayOfWeek.MONDAY]: OpeningDays.WEEKDAY,
  [DayOfWeek.TUESDAY]: OpeningDays.WEEKDAY,
  [DayOfWeek.WEDNESDAY]: OpeningDays.WEEKDAY,
  [DayOfWeek.THURSDAY]: OpeningDays.WEEKDAY,
  [DayOfWeek.FRIDAY]: OpeningDays.WEEKDAY,
  [DayOfWeek.SATURDAY]: OpeningDays.SATURDAY,
  [DayOfWeek.SUNDAY]: OpeningDays.SUNDAY,
};

export const isDealershipOpen = (
  openingTimes: IOperatingTimesData[],
  dateTime: Date = new Date(),
) => {
  try {
    const relevantOpeningDay = DayOfWeekToOpeningDays[dateTime.getDay()];
    const relevantOpeningTimes = openingTimes.filter(
      o => o.dayOfWeek === relevantOpeningDay,
    );
    const timeAsNumber = dateToTimeAsNumber(dateTime);
    return !!relevantOpeningTimes.find(
      o =>
        timeAsNumber >= toTimeAsNumber(o.openFrom) &&
        timeAsNumber <= toTimeAsNumber(o.openTo),
    );
  } catch (e) {
    // in case if some strings in opening times fail
    return true;
  }
};

const toTimeAsNumber = (timeString: string) =>
  parseInt(timeString.replace(':', ''));

const dateToTimeAsNumber = (dateTime: Date) => {
  const hours = dateTime.getHours();
  const minutes = `${dateTime.getMinutes()}`.padStart(2, '0');
  return parseInt(`${hours}${minutes}`);
};
