import {
  fontSizes,
  fontSizesMobile,
  lineHeights,
  lineHeightsMobile,
  fontWeights,
} from './variables';
import { colors } from './colors';

const defaultTheme = {
  fontSizes,
  fontSizesMobile,
  lineHeights,
  lineHeightsMobile,
  fontWeights,
  colors,
  buttons: {
    primary: {
      default: colors.secondary500,
      hover: colors.secondary600,
      active: colors.primary,
      disabled: colors.neutral50,
      color: colors.white,
      hoverColor: colors.white,
      activeColor: colors.white,
      borderColor: colors.transparent,
      disabledColor: '',
      disabledBorderColor: '',
    },
    secondary: {
      default: colors.primary,
      hover: colors.secondary500,
      active: colors.primary500,
      disabled: colors.neutral50,
      color: colors.white,
      hoverColor: colors.white,
      activeColor: colors.white,
      borderColor: colors.transparent,
      disabledColor: colors.white,
    },
    tertiary: {
      default: colors.white,
      hover: colors.white,
      active: colors.white,
      disabled: colors.neutral50,
      color: colors.primary500,
      hoverColor: colors.secondary500,
      activeColor: colors.primary500,
      borderColor: colors.neutral200,
      disabledColor: colors.white,
      disabledBorderColor: colors.neutral50,
    },
  },
};

export { defaultTheme as default };
