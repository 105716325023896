import {
  PageContent,
  PageBuilderPageType as SupportedPageType,
} from '@hey-car/intl-seo-hub.page-builder';
import {
  Elements,
  IContentItem,
  createDeliveryClient,
} from '@kontent-ai/delivery-sdk';
import { IContentItemElements } from '@kontent-ai/delivery-sdk/lib/models/item-models';
import {
  KENTICO_OTP_LOCALE,
  KENTICO_OTP_PROJECT_ID,
} from 'next-app/app/config';

export enum UnsupportedPageType {
  reviewPage = 'page_builder_review_page',
}

type PageType = SupportedPageType | UnsupportedPageType;

const LANDING_PAGE_TYPES = [SupportedPageType.landingPage];
const BLOG_PAGE_TYPES = [SupportedPageType.blog, SupportedPageType.guides];
export const NEWS_PAGE_TYPES = [SupportedPageType.blog];
export const GUIDES_PAGE_TYPES = [SupportedPageType.guides];
const REVIEW_PAGE_TYPES = [UnsupportedPageType.reviewPage];
const MAIN_PAGE_TYPES = [
  SupportedPageType.landingPage,
  UnsupportedPageType.reviewPage,
];
// const HEADER_MENU_TYPES = ['header_menu'];
// const PROMOTIONS_LIST_TYPES = ['promotions_list'];
// const FOOTER_CONTENT_TYPES = ['footer_content'];

const HOME_CODENAME = 'home';
// const MAIN_HOME_SECTION_CODENAME = 'main_home_section__for_homepage_es_';

// const USP_LIST_WITH_IMAGE = ['usp_list_with_image'];

const clientOTP = createDeliveryClient({
  projectId: KENTICO_OTP_PROJECT_ID,
  defaultLanguage: KENTICO_OTP_LOCALE,
});

// const clientHCC = createDeliveryClient({
//   projectId: KENTICO_HCC_PROJECT_ID,
//   defaultLanguage: KENTICO_HCC_LOCALE,
// });

export const getLandingPagesSlugs = async (): Promise<string[]> => {
  return getItemsSlugs(LANDING_PAGE_TYPES);
};

export const getBlogPagesSlugs = async (): Promise<string[]> => {
  return getItemsSlugs(BLOG_PAGE_TYPES);
};

export const getGuidePagesSlugs = async (): Promise<string[]> => {
  return getItemsSlugs(GUIDES_PAGE_TYPES);
};

export const getReviewPagesSlugs = async (): Promise<string[]> => {
  return getItemsSlugs(REVIEW_PAGE_TYPES);
};

export const getMainPagesSlugs = async (): Promise<string[]> => {
  return getItemsSlugs(MAIN_PAGE_TYPES).then(slugs =>
    // Remove the '/' path from the Review and Landing pages
    // The '/' path goes to the homepage
    slugs.filter(slug => slug !== '/'),
  );
};

const getItemsSlugs = async (types: PageType[]): Promise<string[]> => {
  return clientOTP
    .items()
    .types(types)
    .depthParameter(1)
    .elementsParameter(['slug'])
    .toPromise()
    .then(r =>
      r.data.items
        .filter(page => !!page.elements.slug.value)
        .map(page => page.elements.slug.value),
    );
};

export const getLandingPageBySlug = async (slug: string) => {
  return getKenticoPageBySlug(LANDING_PAGE_TYPES, slug);
};

export const getBlogPageBySlug = async (slug: string) => {
  return getKenticoPageBySlug(BLOG_PAGE_TYPES, slug);
};

export const getGuidePageBySlug = async (slug: string) => {
  return getKenticoPageBySlug(GUIDES_PAGE_TYPES, slug);
};

export const getReviewPageBySlug = async (slug: string) => {
  return getKenticoPageBySlug(REVIEW_PAGE_TYPES, slug);
};

export const getMainPageBySlug = async (slug: string) => {
  return getKenticoPageBySlug(MAIN_PAGE_TYPES, slug);
};

const getKenticoPageBySlug = async (types: PageType[], slug: string) => {
  return clientOTP
    .items<PageContent>()
    .types(types)
    .limitParameter(1)
    .equalsFilter(`elements.slug`, encodeURI(slug))
    .toPromise()
    .then(r => r.data.items[0]);
};

export const getTopProvincesData = async () => {
  return getKenticoPageByCodename('top_province_links__fosr_homepage_es_');
};

export const getTopReviewsData = async () => {
  return getKenticoPageByCodename('top_review_links__for_homepage_es_');
};

export const geTopKm0CarsData = async () => {
  return getKenticoPageByCodename('top_km0_car_links__for_homepage_es_');
};

export const getTopSecondHandCarsData = async () => {
  return getKenticoPageByCodename(
    'top_second_hand_car_links__for_homepage_es_',
  );
};

export const getTopMakesData = async () => {
  return getKenticoPageByCodename('top_make_links__for_homepage_es_');
};

export const getFAQData = async () => {
  return getKenticoPageByCodename('faq__for_homepage_es_');
};

const getKenticoPageByCodename = async (
  codename: string,
  client = clientOTP,
) => {
  return client
    .item<IContentItem>(codename)
    .toPromise()
    .then(r => r.data.item);
};

export const getPromotionsData = async () => {
  return undefined;
};

// export const getFooterData = async () => {
//   return getKenticoPagesByTypesOrderByCreatedAt(
//     {
//       types: FOOTER_CONTENT_TYPES,
//       limit: 1,
//       depth: 5,
//     },
//     clientHCC,
//   )
//     .toPromise()
//     .then(r => r.data.items[0]);
// };

// export const getHeaderMenuData = async () => {
//   return getKenticoPagesByTypesOrderByCreatedAt(
//     {
//       types: HEADER_MENU_TYPES,
//       limit: 1,
//       depth: 5,
//     },
//     clientHCC,
//   )
//     .toPromise()
//     .then(r => r.data.items[0]);
// };

export const getHomepageData = async () => {
  return getItemByCodeName(HOME_CODENAME);
};

// export const getMainHomeData = async () => {
//   return getItemByCodeName(MAIN_HOME_SECTION_CODENAME, clientHCC);
// };

export const getLastArticlesData = async () => {
  return getKenticoPagesByTypesOrderByCreatedAt({
    types: BLOG_PAGE_TYPES,
    limit: 2,
  })
    .toPromise()
    .then(r => r.data.items);
};

// export const getDefaultUSPListForHomepage = async () => {
//   return getKenticoPagesByTypesOrderByCreatedAt(
//     {
//       types: USP_LIST_WITH_IMAGE,
//       limit: 2,
//       depth: 2,
//       contains: {
//         element: 'elements.type',
//         value: ['default'],
//       },
//     },
//     clientHCC,
//   )
//     .toPromise()
//     .then(r => r.data.items);
// };

// export const getInsuranceUSPListForHomepage = async () => {
//   return getKenticoPagesByTypesOrderByCreatedAt(
//     {
//       types: USP_LIST_WITH_IMAGE,
//       limit: 2,
//       depth: 2,
//       contains: {
//         element: 'elements.type',
//         value: ['insurance'],
//       },
//     },
//     clientHCC,
//   )
//     .toPromise()
//     .then(r => r.data.items);
// };

export const getFeaturedBlogArticles = async (types: string[]) => {
  return getKenticoPagesByTypesOrderByCreatedAt({
    types,
    limit: 4,
  })
    .containsFilter('elements.blog', ['featured'])
    .toPromise()
    .then(r => r.data.items);
};

export const getRecentBlogArticles = async (types: string[]) => {
  return getKenticoPagesByTypesOrderByCreatedAt({
    types,
    limit: 4,
  })
    .toPromise()
    .then(r => r.data.items);
};

// skip is used to skip some articles from pagination.
// Recent articles section already displays some articles, so we skip them here
export const getArticles = async (
  page: number,
  skip: number,
  types: string[],
) => {
  const itemsPerPage = 6;
  const zeroBasedPage = page - 1;
  const skipParameter = skip + itemsPerPage * zeroBasedPage;
  return getKenticoPagesByTypesOrderByCreatedAt({
    types,
    limit: itemsPerPage,
    skip: skipParameter,
  })
    .includeTotalCountParameter()
    .toPromise()
    .then(r => r.data);
};

interface ReviewPageKontentModel extends IContentItemElements {
  cars: Elements.TaxonomyElement;
  slug: Elements.TextElement;
}

export interface ICarReviewLink {
  slug: string;
  make: string;
  model: string;
}

export async function getPopularReviews(
  make: string,
): Promise<ICarReviewLink[]> {
  const request = getKenticoPagesByTypes<IContentItem<ReviewPageKontentModel>>({
    types: REVIEW_PAGE_TYPES,
  })
    .containsFilter('elements.cars', [make])
    .containsFilter('elements.reviews', ['popular_cars'])
    .elementsParameter(['cars', 'slug']);

  return request.toPromise().then(r =>
    r.data.items.map(({ elements }) => {
      const { cars, slug } = elements;
      const [popularMake, popularModel] = cars.value;
      return {
        slug: slug.value,
        make: popularMake.name,
        model: popularModel.name,
      };
    }),
  );
}

interface IPageQueryParams {
  types: PageType[] | string[];
  limit?: number;
  skip?: number;
  depth?: number;
  contains?: { element: string; value: string[] } | null | undefined;
}

const getKenticoPagesByTypesOrderByCreatedAt = (
  params: IPageQueryParams,
  client = clientOTP,
) => {
  return getKenticoPagesByTypes(params, client).orderByDescending(
    'elements.created_at',
  );
};

const getKenticoPagesByTypes = <T extends IContentItem>(
  { types, limit, skip = 0, depth = 0, contains = null }: IPageQueryParams,
  client = clientOTP,
) => {
  let result = client
    .items<T>()
    .types(types)
    .skipParameter(skip)
    .depthParameter(depth);

  if (contains) {
    result = result.containsFilter(contains.element, contains.value);
  }

  if (limit) {
    return result.limitParameter(limit);
  }

  return result;
};

export const getItemByCodeName = async (
  codename: string,
  client = clientOTP,
) => {
  return client
    .item(codename)
    .toPromise()
    .then(response => response.data.item);
};
