import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InspectTranslationSt } from './Translation.css';
import { useTranslateInspection } from 'app/providers/TranslateInspectionProvider';
import {
  staticValues,
  translatedLinks,
} from 'app/shared/components/Translation/hooks/useTranslate/useTranslate';

export interface ITranslationProps {
  id: string;
  defaultMessage?: string;
  description?: string;
  values?: Record<string, any>;
}

const Translation = ({
  id,
  defaultMessage = '',
  description = '',
  values = {},
}: ITranslationProps) => {
  const { locale } = useIntl();
  const { isInspected } = useTranslateInspection();
  const links = translatedLinks[locale];

  const content = (
    <FormattedMessage
      id={id}
      defaultMessage={defaultMessage || id}
      description={description}
      values={{ ...staticValues, ...links, ...values }}
    />
  );

  return isInspected ? (
    <InspectTranslationSt title={id}>{content}</InspectTranslationSt>
  ) : (
    content
  );
};

export default memo(Translation);
