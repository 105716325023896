import {
  createContext,
  MutableRefObject,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IProviderComponent } from 'app/providers/provider.interface';

interface IModalContext {
  closeModal: (e) => void;
  modalElement?: MutableRefObject<HTMLDivElement>;
  modalElementRef?: MutableRefObject<HTMLDivElement>;
}

const ModalContext = createContext<IModalContext>({
  closeModal: () => {},
  modalElement: null,
  modalElementRef: null,
});

interface IModalProviderProps extends IModalContext, IProviderComponent {}

const ModalProvider = ({
  children,
  closeModal,
}: IModalProviderProps): ReactElement => {
  const modalElementRef = useRef(null);
  const [modalElement, setModalElement] =
    useState<MutableRefObject<HTMLDivElement>>();

  useEffect(() => {
    setModalElement(modalElementRef);
  }, [modalElementRef]);

  return (
    <ModalContext.Provider
      value={{
        closeModal,
        modalElement,
        modalElementRef,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

const useModalContext = () => useContext(ModalContext);

export { ModalProvider, useModalContext };
