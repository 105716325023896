import { resolveCanonicalPosition } from 'app/hooks/filters/utils';
import bodyTypesList from 'app/containers/CLP/components/SideBarFilters/BodyTypeFilter/bodyTypes.json';
import fuelTypesList from 'app/containers/CLP/components/SideBarFilters/FuelBoxFilter/fuelTypes.json';
import gearBoxList from 'app/containers/CLP/components/SideBarFilters/GearBoxFilter/gearBoxes.json';
import seatsCountList from 'app/containers/CLP/components/SideBarFilters/SeatsFilter/seatCount.json';
import { getAllProvinces } from 'app/utils/getProvinces';
import { ParsedUrlQuery } from 'querystring';
import { BASE_ROUTES } from 'app/hooks/useUrl/useUrl';

const provinceList = getAllProvinces();

export interface IPathParameters {
  make: string;
  model: string;
  bodyType: string;
  fuelType: string;
  province: string;
  seats: string;
  gearType: string;
  onlineReservation: string;
  lowMileage: string;
  allPromotions: string;
}

export const resolvePathParameters = (
  query: ParsedUrlQuery,
  asPath: string,
  options = { strict: true },
): IPathParameters => {
  const {
    makePosition,
    modelPosition,
    bodyTypePosition,
    fuelTypePosition,
    provincePosition,
    seatCountPosition,
    gearTypePosition,
    onlineReservationPosition,
    allPromotionsPosition,
  } = resolveCanonicalPosition(query, options);

  return {
    make: isInPath(makePosition) ? getFromPath(makePosition) : undefined,
    model: isInPath(modelPosition) ? getFromPath(modelPosition) : undefined,
    bodyType: isInPath(bodyTypePosition)
      ? getFromPath(bodyTypePosition)
      : undefined,
    fuelType: isInPath(fuelTypePosition)
      ? getFromPath(fuelTypePosition)
      : undefined,
    province: isInPath(provincePosition)
      ? getProvinceFromPath(provincePosition)
      : undefined,
    seats: isInPath(seatCountPosition)
      ? getSeatsFromPath(seatCountPosition)
      : undefined,
    gearType: isInPath(gearTypePosition)
      ? getFromPath(gearTypePosition)
      : undefined,
    onlineReservation: isInPath(onlineReservationPosition)
      ? getFromPath(onlineReservationPosition)
      : undefined,
    lowMileage:
      asPath && asPath.startsWith(BASE_ROUTES.km0) ? 'coches-km0' : undefined,
    allPromotions: isInPath(allPromotionsPosition)
      ? getFromPath(allPromotionsPosition)
      : undefined,
  };

  function isInPath(position: number): boolean {
    return position != -1;
  }

  function getProvinceFromPath(position: number): string {
    return query.path[position].replace('provincia-', '');
  }
  function getSeatsFromPath(position: number): string {
    return query.path[position].replace('asientos-', '');
  }

  function getFromPath(position: number): string {
    return query.path[position];
  }
};

interface IProvinceFilterOption {
  key: string;
  value: string;
}

interface IFilterOption {
  displayName: string;
  key: string;
  urlName: string;
}

export const findBodyTypeByPathValue = (
  bodyTypeFromPath: string,
): IFilterOption => bodyTypesList.find(y => y.urlName === bodyTypeFromPath);

export const findFuelTypeByPathValue = (
  fuelTypeFromPath: string,
): IFilterOption => fuelTypesList.find(y => y.urlName === fuelTypeFromPath);

export const findProvinceByPathValue = (
  provinceFromPath: string,
): IProvinceFilterOption => provinceList.find(y => y.key === provinceFromPath);

export const findSeatsByPathValue = (seatsFromPath: string): IFilterOption =>
  seatsCountList.find(y => y.urlName === seatsFromPath);

export const findGearTypeByPathValue = (
  gearTypeFromPath: string,
): IFilterOption => gearBoxList.find(y => y.urlName === gearTypeFromPath);

export const getBodyTypeCanonicalPathValue = (
  bodyType: string | string[],
): string => `${bodyType}`;

export const getFuelTypeCanonicalPathValue = (
  fuelType: string | string[],
): string => `${fuelType}`;

export const getProvinceCanonicalPathValue = (
  province: string | string[],
): string => `provincia-${province}`;

export const getSeatsCanonicalPathValue = (seats: string | string[]): string =>
  `asientos-${seats}`;

export const getGearTypeCanonicalPathValue = (
  gearType: string | string[],
): string => `${gearType}`;

export const getMakeCanonicalPathValue = (make: string | string[]): string =>
  `${make}`;

export const getModelCanonicalPathValue = (model: string | string[]): string =>
  `${model}`;

export const getOnlineReservationPathValue = (): string => 'compra-online';

export const getAllPromotionsPathValue = (): string => 'promociones';
