import styled, { css } from 'styled-components';
import { media } from 'app/styles/variables';
import Button from '@heycar-uikit/button';

interface IHeycarButtonStProps {
  $mobileFullWidth: boolean;
}

export const HeycarButtonSt = styled(Button)<IHeycarButtonStProps>`
  ${({ $mobileFullWidth }) => ($mobileFullWidth ? `width: 100%;` : '')}

  ${media.sm(css`
    ${({ $mobileFullWidth }: IHeycarButtonStProps) =>
      $mobileFullWidth ? `width: auto;` : ''}
  `)};
`;
