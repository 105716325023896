// Snippet courtesy of (and fixed):
// https://usehooks.com/useOnScreen/

import { RefObject, useEffect, useState } from 'react';

export function useOnScreen(
  ref: RefObject<Element>,
  rootMargin = '0px',
  defaultState = false,
  rootRef?: RefObject<Element>,
): boolean {
  // State and setter for storing whether element is visible

  const [isIntersecting, setIntersecting] = useState(defaultState);

  useEffect(() => {
    // For unsupported browsers, do nothing
    const ioAvailable = 'IntersectionObserver' in window;
    if (!ioAvailable) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
        root: rootRef?.current,
      },
    );
    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref?.current]);

  return isIntersecting;
}
