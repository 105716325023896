import { searchByParams, SearchByParamsResult } from 'app/apiCalls/vehicle';
import {
  CLPQueryParameters,
  convertQueryParamsForSearchEndpoint,
} from 'app/containers/CLP/utils';
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

interface ICLPQueryParams {
  query: CLPQueryParameters;
  asPath?: string;
}
interface IPDPNavigationContextProps {
  searchResult: SearchByParamsResult;
  setSearchResult: (searchResults: SearchByParamsResult) => void;
  navigateToPage: (isPrevious?: boolean) => void;
  setCLPQueryParams: (query: ICLPQueryParams) => void;
}

const PDPNavigationContext = createContext<IPDPNavigationContextProps>({
  searchResult: null,
  setSearchResult: () => {},
  navigateToPage: () => {},
  setCLPQueryParams: () => {},
});

const PDPNavigationProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [searchResult, setSearchResult] = useState<SearchByParamsResult>(null);
  const [clpQueryParams, setCLPQueryParams] = useState<ICLPQueryParams>(null);

  const getSearchResults = async (
    query: CLPQueryParameters,
    asPath?: string,
  ) => {
    try {
      const result = await searchByParams(
        convertQueryParamsForSearchEndpoint(query, asPath),
      );
      setSearchResult(result);
    } catch (error) {
      console.error(error);
    }
  };

  const navigateToPage = useCallback(
    (isPrevious = false) => {
      const requestedPage = isPrevious
        ? searchResult.pagination.page
        : searchResult.pagination.page + 2;
      clpQueryParams.query.page = requestedPage.toString();
      getSearchResults(clpQueryParams.query, clpQueryParams.asPath);
    },
    [searchResult],
  );

  return (
    <PDPNavigationContext.Provider
      value={{
        searchResult,
        setSearchResult,
        navigateToPage,
        setCLPQueryParams,
      }}
    >
      {children}
    </PDPNavigationContext.Provider>
  );
};

const usePDPNavigation = () => useContext(PDPNavigationContext);

export { PDPNavigationProvider, usePDPNavigation };
