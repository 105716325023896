import { ForcedFlagMap } from 'app/providers/FeatureToggleProvider/FeatureToggleProvider';
import { COOKIE_PREFERENCE_EXPIRES } from 'next-app/app/config';
import { useCallback, useEffect, useState } from 'react';
import { get, set } from 'js-cookie';

const PERSISTED_FORCED_FLAGS_COOKIENAME = 'forced-flags';

export const useCookieFlagEntries = (): {
  cookieFlagEntries: ForcedFlagMap;
  updateCookieFlagEntries: (updatedEntries: ForcedFlagMap) => void;
} => {
  const [cookieFlagEntries, setCookieFlagEntries] = useState<ForcedFlagMap>({});
  useEffect(() => {
    try {
      setCookieFlagEntries(
        JSON.parse(get(PERSISTED_FORCED_FLAGS_COOKIENAME) || '{}'),
      );
    } catch (error) {
      // Ignore corrupt cookie data
    }
  }, []);

  const updateCookieFlagEntries = useCallback(
    updatedEntries => {
      set(PERSISTED_FORCED_FLAGS_COOKIENAME, updatedEntries, {
        expires: COOKIE_PREFERENCE_EXPIRES,
      });
    },
    [cookieFlagEntries],
  );

  return {
    cookieFlagEntries,
    updateCookieFlagEntries,
  };
};
