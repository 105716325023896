import { ReactElement, ReactNode } from 'react';
import { AnimatedContainerSt } from './ModalAnimatedContainer.css';

interface IModalAnimatedContainerProps {
  id?: string;
  children?: ReactNode;
  animated: boolean;
  animationDuration: number;
  show: boolean;
  direction?: 'left' | 'right' | 'bottom';
}

const ModalAnimatedContainer = ({
  id,
  children,
  animated,
  show,
  direction,
  animationDuration,
}: IModalAnimatedContainerProps): ReactElement => {
  return (
    <AnimatedContainerSt
      id={id}
      show={show}
      direction={direction}
      animated={animated}
      animationDuration={animationDuration}
    >
      {children}
    </AnimatedContainerSt>
  );
};

export { ModalAnimatedContainer };
