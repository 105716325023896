import { getIntlContent, LocaleIntlData } from './intl';
import { PrefetchOptions } from 'src/next/app/prefetchData';

const QUERY_NAME = 'intl-content';

interface IIntlDataQueryProps {
  locale: string;
}

interface IIntlDataQueryResult {
  intl: LocaleIntlData;
}

const DataQueryFn = ({
  locale,
}: IIntlDataQueryProps): Promise<IIntlDataQueryResult> =>
  getIntlContent({ locale });

const DataQueryOptions = {
  staleTime: 150000,
};

const DataQueryPrefetchOptions: PrefetchOptions = {
  throwOnError: true,
};

export default {
  name: QUERY_NAME,
  fn: DataQueryFn,
  options: DataQueryOptions,
  prefetchOptions: DataQueryPrefetchOptions,
};
