import { convertMinMaxToRange, includesValue } from 'app/utils/utils';
import { SortBy, SortDirection } from './components/SortControl/SortControl';
import { NextRouter } from 'next/router';
import qs from 'query-string';
import { BASE_ROUTES } from 'app/hooks/useUrl/useUrl';
import {
  D2C_DATASOURCE,
  DEALER_ECOMMERCE_DATASOURCE,
} from 'next-app/app/config';
import {
  findBodyTypeByPathValue,
  findFuelTypeByPathValue,
  findGearTypeByPathValue,
  findProvinceByPathValue,
  findSeatsByPathValue,
  getAllPromotionsPathValue,
  getBodyTypeCanonicalPathValue,
  getFuelTypeCanonicalPathValue,
  getGearTypeCanonicalPathValue,
  getMakeCanonicalPathValue,
  getModelCanonicalPathValue,
  getOnlineReservationPathValue,
  getProvinceCanonicalPathValue,
  getSeatsCanonicalPathValue,
  resolvePathParameters,
} from 'app/utils/path';
import { ParsedUrlQuery } from 'querystring';
import {
  LOW_MILEAGE_MAX,
  LOW_MILEAGE_MIN,
} from 'app/hooks/filters/useMileage/useMileage';
import { SearchByParamsProps } from 'app/apiCalls/vehicle';

export interface QueryParamsMap {
  [key: string]: string | string[] | boolean;
}

export interface CLPQueryParameters {
  page?: string;
  marca?: string | string[];
  modelo?: string | string[];
  cambio?: string;
  fuelType?: string;
  colors?: string | string[];
  bodyTypes?: string;
  feature?: string | string[];
  mileageMin?: string;
  mileageMax?: string;
  captive?: 'vwfs_es' | 'mbb_es';
  priceMin?: string;
  priceMax?: string;
  monthlyRateMin?: string;
  monthlyRateMax?: string;
  engineSizeMin?: string;
  engineSizeMax?: string;
  yearMin?: string;
  yearMax?: string;
  sortBy?: SortBy;
  sortDirection?: SortDirection;
  lat?: string;
  lon?: string;
  radius?: string;
  provincia?: string;
  dealerId?: string;
  modelFamily?: string | string[];
  emissionSticker?: string;
  specials?: string;
  enginePowerMin?: string;
  enginePowerMax?: string;
  campaign?: string;
  markerId?: string;
  hasFinancingOffer?: boolean;
  campaignId?: string | string[];
  path?: string[];
  booked?: boolean;
  asientos?: string;
}

export const convertQueryParamsForSeoInformationEndpoint = (
  query: CLPQueryParameters = {},
  asPath?: string,
  size?: number,
) => {
  const { filters } = convertQueryParamsForSearchEndpoint(query, asPath, size);

  const { onlineReservation, lowMileage } = resolvePathParameters(
    query as ParsedUrlQuery,
    asPath,
  );

  return {
    make: filters.make,
    model: filters.model,
    gearBox: filters.gearBox,
    category: filters.category,
    fuelType: filters.fuelType,
    nuts3: filters.nuts3,
    seats: filters.seats,
    km0: !!lowMileage || undefined,
    buyOnline: !!onlineReservation || undefined,
  };
};

export const convertQueryParamsForSearchEndpoint = (
  query: CLPQueryParameters = {},
  asPath?: string,
  size?: number,
): SearchByParamsProps => {
  const {
    page,
    marca,
    modelo,
    cambio,
    fuelType,
    colors,
    bodyTypes,
    feature,
    mileageMin,
    mileageMax,
    captive,
    priceMin,
    priceMax,
    monthlyRateMin,
    monthlyRateMax,
    engineSizeMin,
    engineSizeMax,
    yearMin,
    yearMax,
    sortBy,
    sortDirection,
    lat,
    lon,
    radius,
    provincia,
    dealerId,
    modelFamily,
    emissionSticker,
    specials,
    enginePowerMin,
    enginePowerMax,
    markerId,
    hasFinancingOffer,
    campaignId,
    booked,
    asientos,
  } = query;

  const priceRange = convertMinMaxToRange(priceMin, priceMax);
  const monthlyInstallmentRange = convertMinMaxToRange(
    monthlyRateMin,
    monthlyRateMax,
  );
  const yearRange = convertMinMaxToRange(yearMin, yearMax);
  const cubicCapacityRange = convertMinMaxToRange(engineSizeMin, engineSizeMax);
  const homeDeliveryAvailable = includesValue(specials, 'home_delivery');
  const receiveVideoLead = includesValue(specials, 'remote_visit');
  const acceptsReturns = includesValue(specials, 'return_policy');
  const performanceRange = convertMinMaxToRange(enginePowerMin, enginePowerMax);

  const {
    make,
    model,
    bodyType,
    fuelType: canonFuelType,
    province,
    seats,
    gearType,
    onlineReservation,
    lowMileage,
    allPromotions,
  } = resolvePathParameters(query as ParsedUrlQuery, asPath);

  const fuelTypeSearchText =
    translateFuelTypeFromPathValueToQueryValue(canonFuelType) ||
    splitMap(fuelType, translateFuelTypeFromPathValueToQueryValue);

  const bodyTypeSearchText =
    translateBodyTypeFromPathValueToQueryValue(bodyType) ||
    splitMap(bodyTypes, translateBodyTypeFromPathValueToQueryValue);

  const provinceSearchText =
    translateProvinceFromPathValueToQueryValue(province) ||
    splitMap(provincia, translateProvinceFromPathValueToQueryValue);

  const gearTypeSearchText =
    translateGearTypeFromPathValueToQueryValue(gearType) ||
    splitMap(cambio, translateGearTypeFromPathValueToQueryValue);

  const seatsSearchText =
    translateSeatsFromPathValueToQueryValue(seats) ||
    splitMap(asientos, translateSeatsFromPathValueToQueryValue);

  const dataSource = onlineReservation && [
    D2C_DATASOURCE,
    DEALER_ECOMMERCE_DATASOURCE,
  ];

  const mileageRange = lowMileage
    ? convertMinMaxToRange(LOW_MILEAGE_MIN, LOW_MILEAGE_MAX)
    : convertMinMaxToRange(mileageMin, mileageMax);

  const filters = {
    make: make || marca,
    model: !modelFamily ? model || modelo : undefined,
    modelFamily,
    gearBox: gearTypeSearchText,
    fuelType: fuelTypeSearchText,
    featureText: feature,
    bodyColor: colors,
    category: bodyTypeSearchText,
    captive,
    mileageRange,
    monthlyInstallmentRange,
    lat,
    lon,
    radius: radius ? `${radius}km` : undefined,
    priceRange,
    yearRange,
    cubicCapacityRange,
    nuts3: provinceSearchText,
    dealerId,
    emissionSticker,
    homeDeliveryAvailable,
    receiveVideoLead,
    acceptsReturns,
    performanceRange,
    dataSource,
    markerId,
    hasFinancingOffer,
    campaignId,
    booked,
    seats: seatsSearchText,
    allPromotionsFor: !!allPromotions ? 'es' : undefined,
  };

  return {
    page: parseInt(page, 10) || 1,
    size,
    filters,
    sortBy,
    sortDirection,
    reducedContent: true,
  };
};

export const buildCanonicalForm = (
  router: NextRouter,
  additionalQuery: QueryParamsMap,
  keysToRemove: Array<string> = [],
) => {
  let { query: existingQuery } = qs.parseUrl(router.asPath, {
    arrayFormat: 'comma',
  });

  const {
    make,
    model,
    bodyType,
    fuelType,
    province,
    gearType,
    seats,
    onlineReservation,
    lowMileage,
    allPromotions,
  } = resolvePathParameters(router.query, router.asPath);

  // feeding existing query parameters with canonical parameters
  // heycar.com/coches-segunda-mano is added to query existing query params, and then it will be resolved in the below section
  if (router.query.path) {
    existingQuery = {
      ...existingQuery,
      marca: make ? [make] : existingQuery.marca,
      modelo: model ? [model] : existingQuery.modelo,
      bodyTypes: bodyType ? [bodyType] : existingQuery.bodyTypes,
      fuelType: fuelType ? [fuelType] : existingQuery.fuelType,
      provincia: province ? [province] : existingQuery.provincia,
      cambio: gearType ? [gearType] : existingQuery.cambio,
      asientos: seats ? [seats] : existingQuery.asientos,
    };
  }

  existingQuery.lowMileage = lowMileage || existingQuery.lowMileage;
  existingQuery.onlineReservation =
    onlineReservation || existingQuery.onlineReservation;
  existingQuery.allPromotions = allPromotions || existingQuery.allPromotions;

  const resultQuery = {
    ...existingQuery,
    ...additionalQuery,
  };

  // remove keys.
  keysToRemove.length &&
    keysToRemove.forEach(key => {
      resultQuery[key] = undefined;
    });

  /** here we resolve all query parameters, deciding if they are canonical now, or not */
  let canonicalUrl = BASE_ROUTES.clp;

  if (resultQuery.lowMileage) {
    canonicalUrl = BASE_ROUTES.km0;
    resultQuery.lowMileage = undefined;
  }

  if (resultQuery.onlineReservation) {
    canonicalUrl = `${canonicalUrl}/${getOnlineReservationPathValue()}`;
    resultQuery.onlineReservation = undefined;
  }

  if (resultQuery.allPromotions) {
    canonicalUrl = `${canonicalUrl}/${getAllPromotionsPathValue()}`;
    resultQuery.allPromotions = undefined;
  }

  if (
    !allPromotions &&
    resultQuery.marca &&
    Array.isArray(resultQuery.marca) &&
    resultQuery.marca.length === 1
  ) {
    canonicalUrl = `${canonicalUrl}/${getMakeCanonicalPathValue(
      resultQuery.marca,
    )}`;
    if (
      !allPromotions &&
      resultQuery.modelo &&
      Array.isArray(resultQuery.modelo) &&
      resultQuery.modelo.length === 1
    ) {
      canonicalUrl = `${canonicalUrl}/${getModelCanonicalPathValue(
        resultQuery.modelo,
      )}`;
      resultQuery['modelo'] = undefined;
    }
    resultQuery['marca'] = undefined;
  }

  if (
    !allPromotions &&
    resultQuery.provincia &&
    Array.isArray(resultQuery.provincia) &&
    resultQuery.provincia.length === 1
  ) {
    canonicalUrl = `${canonicalUrl}/${getProvinceCanonicalPathValue(
      resultQuery.provincia,
    )}`;
    resultQuery['provincia'] = undefined;
  }

  if (
    !allPromotions &&
    resultQuery.asientos &&
    Array.isArray(resultQuery.asientos) &&
    resultQuery.asientos.length === 1
  ) {
    canonicalUrl = `${canonicalUrl}/${getSeatsCanonicalPathValue(
      resultQuery.asientos,
    )}`;
    resultQuery['asientos'] = undefined;
  }

  if (
    !allPromotions &&
    resultQuery.bodyTypes &&
    Array.isArray(resultQuery.bodyTypes) &&
    resultQuery.bodyTypes.length === 1
  ) {
    canonicalUrl = `${canonicalUrl}/${getBodyTypeCanonicalPathValue(
      resultQuery.bodyTypes,
    )}`;
    resultQuery['bodyTypes'] = undefined;
  }

  if (
    !allPromotions &&
    resultQuery.fuelType &&
    Array.isArray(resultQuery.fuelType) &&
    resultQuery.fuelType.length === 1
  ) {
    canonicalUrl = `${canonicalUrl}/${getFuelTypeCanonicalPathValue(
      resultQuery.fuelType,
    )}`;
    resultQuery['fuelType'] = undefined;
  }

  if (
    !allPromotions &&
    resultQuery.cambio &&
    Array.isArray(resultQuery.cambio) &&
    resultQuery.cambio.length === 1
  ) {
    canonicalUrl = `${canonicalUrl}/${getGearTypeCanonicalPathValue(
      resultQuery.cambio,
    )}`;
    resultQuery['cambio'] = undefined;
  }

  removeFirstPageParameter(resultQuery);

  return qs.stringifyUrl(
    {
      url: canonicalUrl,
      query: resultQuery,
    },
    { arrayFormat: 'comma' },
  );

  function removeFirstPageParameter(resultQuery: QueryParamsMap) {
    if (resultQuery.page && resultQuery.page === '1') {
      resultQuery.page = undefined;
    }
  }
};

const translateBodyTypeFromPathValueToQueryValue = (
  bodyTypeFromPath: string,
): string => bodyTypeFromPath && findBodyTypeByPathValue(bodyTypeFromPath).key;

const translateFuelTypeFromPathValueToQueryValue = (
  fuelTypeFromPath: string,
): string => fuelTypeFromPath && findFuelTypeByPathValue(fuelTypeFromPath).key;

const translateProvinceFromPathValueToQueryValue = (
  provinceFromPath: string,
): string =>
  provinceFromPath && findProvinceByPathValue(provinceFromPath).value;

const translateSeatsFromPathValueToQueryValue = (
  seatsFromPath: string,
): string => seatsFromPath && findSeatsByPathValue(seatsFromPath).urlName;

const translateGearTypeFromPathValueToQueryValue = (
  gearTypeFromPath: string,
): string => gearTypeFromPath && findGearTypeByPathValue(gearTypeFromPath).key;

const splitMap = (item: string, mapFn: (s: string) => string): string[] =>
  item && item.split(',').map(mapFn);
