import { ReactElement, ReactNode, useRef, RefObject } from 'react';
import { useLazy } from 'app/hooks/useLazy';
import { LazySt } from './Lazy.css';

interface LazyProps {
  children: ReactNode;
  onLoad?: () => void;
  rootMargin?: string;
  rootRef?: RefObject<Element>;
}

const Lazy = ({
  children,
  onLoad,
  rootMargin,
  rootRef,
}: LazyProps): ReactElement => {
  const ref = useRef(null);
  const loaded = useLazy(ref, onLoad, rootMargin, rootRef);

  return loaded ? <>{children}</> : <LazySt ref={ref} />;
};

export default Lazy;
