export interface AggregationsListDataItem {
  key: string;
  displayName: string;
  count: number;
}

export interface ModelDataItem extends AggregationsListDataItem {
  make: string;
}

export interface ModelFamilyDataItem extends AggregationsListDataItem {
  make: string;
  modelsPerModelFamily: AggregationsListDataItem[];
}

export interface MakeAndModelProps {
  make?: AggregationsListDataItem[];
  model?: ModelDataItem[];
  modelFamily?: ModelFamilyDataItem[];
}

export interface MakeAndModelDataItem extends AggregationsListDataItem {
  models: ModelDataItem[];
  modelFamily?: ModelFamilyDataItem[];
}

export interface AggregationData {
  makeAndModelData?: MakeAndModelDataItem[];
  gearBoxData?: AggregationsListDataItem[];
  bodyColorData?: AggregationsListDataItem[];
  bodyTypeData?: AggregationsListDataItem[];
  priceData?: AggregationsListDataItem[];
  fuelTypeData?: AggregationsListDataItem[];
  emissionStickerData?: AggregationsListDataItem[];
  enginePowerData?: AggregationsListDataItem[];
  seatsCountData?: AggregationsListDataItem[];
}

type GenerateMakeAndModelDataFunction = (
  aggregations: MakeAndModelProps,
) => MakeAndModelDataItem[];

export const generateMakeAndModelData: GenerateMakeAndModelDataFunction = (
  aggregations: MakeAndModelProps,
) => {
  if (
    typeof aggregations === 'undefined' ||
    typeof aggregations.make === 'undefined' ||
    typeof aggregations.model === 'undefined'
  ) {
    return [];
  }

  const {
    make: makeList,
    model: modelList,
    modelFamily: modelFamilyList,
  } = aggregations;

  return makeList.map(({ key, displayName, count }) => ({
    displayName,
    key,
    count,
    modelFamily: modelFamilyList.filter(({ make }) => make === key),
    models: modelList.filter(({ make }) => make === key),
  }));
};
