import { useCallback, useState } from 'react';
import qs from 'query-string';
import { breakpoints } from 'app/styles/variables';
import { DateTime } from 'luxon';
import { VehicleListingData } from 'app/apiCalls/vehicle';
import { MOST_RECENT_DAYS } from 'next-app/app/config';

interface IMarker {
  id: string;
}

export const formatNumber = v =>
  v?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

export const capitalize = (str, lower = false) => {
  if (typeof str !== 'string') return '';
  str = lower ? str.toLowerCase() : str;
  return str.replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
};

export const makeEmptyStringNull = obj => {
  if (!obj) {
    return null;
  }

  const entries = Object.entries(obj);
  const allEntriesAreInvalid = entries.every(
    entry => entry[1] === null || entry[1] === undefined,
  );

  if (allEntriesAreInvalid) {
    return null;
  }

  return entries.reduce((acc, [key, value]) => {
    acc[key] = value === '' ? null : value;
    return acc;
  }, {});
};

export const makeNullsUndefined = obj => {
  if (!obj) {
    return null;
  }

  const entries = Object.entries(obj);
  const allEntriesAreInvalid = entries.every(
    entry => entry[1] === null || entry[1] === undefined,
  );

  if (allEntriesAreInvalid) {
    return null;
  }

  return entries.reduce((acc, [key, value]) => {
    acc[key] = value === null ? undefined : value;
    return acc;
  }, {});
};

// Helper function to make sure that query param is always in an array format so it's
// easier to use with the built-in next-js router functionality. Not the prettiest
// solution but let's see how we can improve this in the future.
export const convertStringToArray = (data?: number | string | string[]) => {
  if (Array.isArray(data)) {
    return data;
  }
  if (typeof data !== 'string') {
    return [];
  }
  return [data];
};

export const convertMinMaxToRange = (
  min?: number | string,
  max?: number | string,
): string | undefined =>
  typeof min !== 'undefined' && typeof max !== 'undefined'
    ? `${min}-${max}`
    : undefined;

export const splitObjectValuesByComma = obj => {
  const vals = {};

  for (const key in obj) {
    if (obj[key].includes(',')) {
      vals[key] = obj[key].split(',');
    } else {
      vals[key] = obj[key];
    }
  }

  return vals;
};

export const includesValue = (param = '', value: string) =>
  param?.includes(value) ? true : undefined;

export const replaceAll = (str: string, obj: Record<string, string>) => {
  for (const key in obj) {
    str = str.replace(new RegExp('{' + key + '}', 'g'), obj[key]);
  }

  return str;
};

// here what we are doing, we are checking if there are make and model selected.
// if so we add them as canonical, in the end, we add other filters which dont require
// canonical form.
export const getCanonicalUrl = copiedFilter => {
  let canonicalUrl = '';

  if (copiedFilter.make && !Array.isArray(copiedFilter.make)) {
    canonicalUrl += `${copiedFilter.make}/`;
    if (copiedFilter.model && !Array.isArray(copiedFilter.model)) {
      canonicalUrl += `${copiedFilter.model}`;
      delete copiedFilter['model'];
    }
    delete copiedFilter['make'];
  }

  // TODO: For now, we need to replace make with marca
  //  and model with modelo. This needs to be improved in another
  //  ticket. Maybe removing this getCanonicalUrl and use the buildCanonicalForm
  //  to avoid duplicating logic.

  if (copiedFilter.make) {
    copiedFilter.marca = copiedFilter.make;
    delete copiedFilter['make'];
  }

  if (copiedFilter.model) {
    copiedFilter.modelo = copiedFilter.model;
    delete copiedFilter['model'];
  }

  canonicalUrl +=
    Object.keys(copiedFilter).length === 0
      ? ''
      : `?${qs.stringify(copiedFilter, {
          arrayFormat: 'comma',
        })}`;

  return canonicalUrl;
};

export const dateToSpanishFriendlyFormat = (dateInput: string): string => {
  return dateInput?.split('-').reverse().join('-');
};

export const dateWithZeroPadding = (): string => {
  return `${new Date().toISOString().split('T')[0]}T00:00:00Z`;
};

export const isAPopularMakeKey = (makeKey: string): boolean =>
  ['vw', 'audi', 'mercedes-benz', 'seat', 'renault'].indexOf(makeKey) >= 0;

export const isMobileScreen = (windowWidth: number): boolean =>
  windowWidth <= breakpoints.sm;

export const hasMercedesClaimer = (markers: IMarker[]): boolean => {
  return (
    markers &&
    markers.findIndex(({ id }) => ['heymercedesbenzfi'].indexOf(id) >= 0) >= 0
  );
};

export const useAsyncError = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setError] = useState();
  return useCallback(
    e => {
      setError(() => {
        throw e;
      });
    },
    [setError],
  );
};

export const hasFreeMaintenanceDiscountClaimer = (
  markers: IMarker[],
): boolean => {
  return (
    markers &&
    markers.findIndex(
      ({ id }) =>
        [
          'hey2ymdiscountvwaudi',
          'hey2ymdiscountseatcupra',
          'hey2ymdiscountvwskoda',
          'hey2ymdiscountvw',
        ].indexOf(id) >= 0,
    ) >= 0
  );
};

export const hasMaintenanceClaimer = (markers: IMarker[]): boolean => {
  return (
    markers &&
    markers.findIndex(
      ({ id }) =>
        ['heyvw', 'heyvwaudi', 'heyseatcupra', 'heyvwskoda'].indexOf(id) >= 0,
    ) >= 0
  );
};

export const hasRenaultClaimer = (markers: IMarker[]): boolean => {
  return (
    markers && markers.findIndex(({ id }) => 'heyrenaultdacia' === id) >= 0
  );
};

export const hasAtLeastOneMarker = (
  markers: IMarker[],
  targetMarkers = [],
): boolean => {
  return (
    markers &&
    markers.findIndex(({ id }) => targetMarkers.indexOf(id) >= 0) >= 0
  );
};

export const getFirstFreeMaintenanceDiscountClaimer = (
  markers: IMarker[],
): IMarker => {
  return (
    markers &&
    markers.find(
      ({ id }) =>
        [
          'hey2ymdiscountvwaudi',
          'hey2ymdiscountseatcupra',
          'hey2ymdiscountvwskoda',
          'hey2ymdiscountvw',
        ].indexOf(id) >= 0,
    )
  );
};

export const getFirstMaintenanceClaimer = (markers: IMarker[]): IMarker => {
  return (
    markers &&
    markers.find(
      ({ id }) =>
        ['heyvw', 'heyvwaudi', 'heyseatcupra', 'heyvwskoda'].indexOf(id) >= 0,
    )
  );
};

export const getFirstRenaultClaimer = (markers: IMarker[]): IMarker => {
  return markers && markers.find(({ id }) => 'heyrenaultdacia' === id);
};

export const getFirstMarker = (
  markers: IMarker[],
  targetMarkers = [],
): IMarker => {
  return markers && markers.find(({ id }) => targetMarkers.indexOf(id) >= 0);
};

export const slicer = target => target.slice(0, target.length - 1);

export const scrollIntoView = (id: string, options?: ScrollIntoViewOptions) => {
  const nextElement = document.querySelector(`#${id}`);
  if (nextElement) {
    nextElement.scrollIntoView(
      options || { behavior: 'smooth', block: 'start' },
    );
  }
};

export const isNewVehicle = (vehicle: VehicleListingData): boolean => {
  const createdAt = DateTime.fromISO(vehicle?.createdAt);
  const maxDate = DateTime.now().minus({
    days: +MOST_RECENT_DAYS || 5,
  });
  return createdAt >= maxDate;
};

export const DISPLAYABLE_ERROR = 'DISPLAYABLE_ERROR';
export const DisplayableError = function (message) {
  this.name = DISPLAYABLE_ERROR;
  this.message = message;
};

export const snakeToCamel = (str: string) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, group =>
      group.toUpperCase().replace('-', '').replace('_', ''),
    );
