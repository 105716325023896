import { TRACKING_PREFIX } from 'next-app/app/config';
import { get } from 'js-cookie';
import { getPrices } from 'app/utils/vehicle';

// Store tracking events until scripts/consent
let pageStack = [];
let actionStack = [];

export const createGUID = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  return `${s4()}${s4()}${s4()}${s4()}${s4()}${s4()}${s4()}${s4()}`;
};

const isAnalyticsAvailable = () => {
  return typeof window !== 'undefined' && window.analytics;
};

export const setTrackingVersion = () => {
  if (isAnalyticsAvailable()) {
    window.analytics.ready(() => {
      window.ga?.('set', 'appName', 'heycar');
      window.ga?.('set', 'appVersion', '1.0.0');
    });
  }
};

export const generateAdditionalTrackingParams = (
  vehicle,
  paramsOverride = {},
) => {
  if (!vehicle) {
    return {};
  }

  const eventId = createGUID();
  const trackingVehicle = getAdditionalVehicleTrackingParams(vehicle);
  return {
    eventId,
    ...trackingVehicle,
    ...paramsOverride,
  };
};

const getAdditionalVehicleTrackingParams = vehicle => {
  const { price, showPriceReduction, fullPrice } = getPrices(vehicle);
  return {
    content_ids: [vehicle.id],
    products: [
      {
        product_id: vehicle.id,
        price: `${vehicle.price}`,
        value: `${vehicle.price}`,
        quantity: '1',
      },
    ],
    content_type: 'vehicle',
    make: `${vehicle.make.id}`,
    model: `${vehicle.model.id}`,
    vehicleCategory: `${vehicle.categories.join(', ')}`,
    category: `${vehicle.categories.join(', ')}`,
    price: `${price}`,
    currency: 'EUR',
    value: `${price}`,
    listingId: `${vehicle.id}`,
    dealerId: `${vehicle.dealer.id}`,
    booked: vehicle.booked,
    live: vehicle.live,
    exterior_color: vehicle.bodyColor,
    fuel_type: vehicle.fuelType,
    transmission: vehicle.gearBox,
    body_style: `${vehicle.categories.join(', ')}`,
    full_price: `${fullPrice}`,
    price_reduced: showPriceReduction,
  };
};

export const trackPage = (guid, page?) => {
  const date = new Date();
  const pageProperties = page || {
    url: window.location.href,
    path: window.location.pathname,
    search: window.location.search,
    title: document.title,
    referrer: document.referrer,
    hitTimestamp: date.getTime(),
    eventId: guid,
    sessionId: guid,
    userAccountId: null,
  };
  if (isAnalyticsAvailable() && pageStack.length === 0) {
    window.analytics.page('', pageProperties);
  } else if (CLIENT && PRODUCTION) {
    pageStack.push(pageProperties);
  }
};

const normalizeValue = value => {
  if (typeof value === 'number') {
    return value;
  }
  if (!value) {
    return null;
  }
  return value;
};

export interface IUserData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export const trackAction = (
  actionName: string,
  basicProperties: any = {},
  additionalProperties: any = {},
  formData: IUserData = null,
  logTracker = false,
) => {
  const eventProperties = generateEventProperties(
    basicProperties,
    additionalProperties,
  );
  const eventContext = generateEventContext(formData);
  if (isAnalyticsAvailable()) {
    _trackAction(actionName, eventProperties, eventContext);
  } else if (CLIENT && PRODUCTION) {
    actionStack.push({ actionName, basicProperties, additionalProperties });
  } else if (DEVELOPMENT && logTracker) {
    console.log(eventProperties); // eslint-disable-line no-console
  }
};

const generateEventProperties = (basicProperties, additionalProperties) => {
  const { category, label, value, interestIn } = basicProperties;
  const guid = createGUID();

  return Object.assign(additionalProperties, {
    category: addPrefixIfNeeded(category),
    label: label || null,
    value: normalizeValue(value),
    interestIn: interestIn,
    hitTimestamp: new Date().getTime(),
    eventId: additionalProperties.eventId || guid,
    sessionId: additionalProperties.sessionId || guid,
    interactedWith: additionalProperties.interactedWith || null,
    fbp: get('_fbp'),
    fbc: get('_fbc'),
  });

  function addPrefixIfNeeded(category: any) {
    return category?.includes(TRACKING_PREFIX)
      ? category
      : `${TRACKING_PREFIX}${category}`;
  }
};

const generateEventContext = (formData: IUserData) => {
  const userTraits = generateUserTraits(formData);
  return {
    ip: window.anonIp,
    page: {
      url: window.location.href,
      path: window.location.pathname,
      search: window.location.search,
      title: document.title,
      referrer: document.referrer,
    },
    ...(userTraits && { traits: userTraits }),
  };
};

function generateUserTraits(formData: IUserData) {
  return formData
    ? {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phoneNumber,
      }
    : null;
}

/**
 * // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
 * @param actionName
 * @param eventProperties
 * @param eventContext
 */
const _trackAction = (actionName, eventProperties, eventContext) => {
  const options = { context: eventContext };
  window.analytics.track(actionName, eventProperties, options);
};

export const trackTimeToInteraction = () => {
  if (CLIENT && PRODUCTION) {
    /*
     there are two conditions here, that i could not test to full coverage, because jest in our setup uses jsdom as
     the default environment which has an unoverrideable 'window.performance' object. this means the first line of each
     of these two conditions cannot be covered. Changing the environment to the 'node' environment does throw so many
     errors that i don't know where to start.
      */
    const domInteractive =
      (window.performance &&
        window.performance.timing &&
        window.performance.timing.domInteractive) ||
      0;
    const requestStart =
      (window.performance &&
        window.performance.timing &&
        window.performance.timing.requestStart) ||
      0;
    const tti = domInteractive - requestStart;
    const option = {
      category: 'performance',
      description: 'performance properties this user',
      label: 'time_to_interaction',
      value: tti || null,
    };
    trackAction('performance_tti', option, {});
  }
};

interface INetworkInformation {
  downlink: number;
  effectiveType: 'slow-2g' | '2g' | '3g' | '4g';
  rtt: number;
}

export const trackConnectionQuality = () => {
  if (CLIENT) {
    let connection: INetworkInformation =
      window &&
      window.navigator &&
      (window.navigator.connection as unknown as INetworkInformation);
    if (!connection) {
      connection = {} as INetworkInformation;
    }
    const option = {
      category: 'connection',
      description: 'connection properties of the user',
      label: `downlink:${connection.downlink || null};effectiveType:${
        connection.effectiveType || null
      };rtt:${connection.rtt || null}`,
      value: connection.downlink || 0,
    };
    trackAction('connection_performance', option, {});
  }
};

/**
 * When the scripts are loaded, identify user to Segment. This is run only after the user agrees to tracking.
 * @param  {String} userId
 */
let identifyInterval = null;
//TODO There's some weirdness here
export const trackIdentify = guid => {
  if (isAnalyticsAvailable()) {
    // Segment identify
    window.analytics.identify(guid, {
      trackingId: guid,
      userAccountId: null,
      createdAt: 0,
      email: guid,
      language: 'DE_DE_X_INFORMAL',
    });
    flushPageStack();
    flushActionStack();
    clearRetryIdentifyIfExists();
  } else if (!identifyInterval && CLIENT && PRODUCTION) {
    retryIdentify(guid);
  }
};

function flushPageStack() {
  pageStack.map(page => trackPage(null, page));
  pageStack = [];
}

function flushActionStack() {
  actionStack.map(action =>
    trackAction(
      action.actionName,
      action.basicProperties,
      action.additionalProperties,
    ),
  );
  actionStack = [];
}

function retryIdentify(guid) {
  identifyInterval = setInterval(trackIdentify, 500, guid);
}

function clearRetryIdentifyIfExists() {
  if (identifyInterval) {
    clearInterval(identifyInterval);
    identifyInterval = null;
  }
}

export enum SpainTrackingActionName {
  /** Insurance MFE Events */
  homePageRendered = 'home_page_rendered',
  insuranceHomeCtaClick = 'get_a_quote_modal_open',
  getAQuoteCarFormSent = 'get_a_quote_car_form_sent',
  getAQuoteDecideLaterSimplesurance = 'get_a_quote_decide_later_simplesurance',
  getAQuoteUserFormSent = 'get_a_quote_user_form_sent',
  connectionPerformance = 'connection_performance',
  getAQuoteProceedUserDetails = 'get_a_quote_proceed_user_details',
  getAQuoteDecideLaterInsurance = 'get_a_quote_decide_later_insurance',
  getAQuoteProceedInsurance = 'get_a_quote_proceed_insurance',
  getUserDetailsProceedSuccessStep = 'get_user_details_proceed_success_step',
}
