import { useIntl } from 'react-intl';
import { useTranslateInspection } from 'app/providers/TranslateInspectionProvider';
import {
  BoldSt,
  ImageSt,
  InlineIconSt,
  InspectTranslationSt,
  ItalicSt,
  ListItemSt,
  UnderlineSt,
  UnorderedListSt,
  WrapperSt,
} from 'app/shared/components/Translation/Translation.css';
import Link from 'app/shared/components/Link/Link';
import { ReactElement } from 'react';

export interface IUseTranslateProps {
  id: string;
  defaultMessage?: string;
  description?: string;
  values?: Record<string, any>;
}

export const linkTranslations = {
  hotjarLink: {
    href: 'https://www.hotjar.com/privacy',
    es: 'Hotjar',
    en: 'Hotjar',
  },
  freshworksLink: {
    href: 'https://www.freshworks.com/privacy/',
    es: 'Freshworks',
    en: 'Freshworks',
  },
  segmentLink: {
    href: 'https://segment.com/docs/legal/privacy/',
    es: 'Segment',
    en: 'Segment',
  },
  hubspotLink: {
    href: 'https://legal.hubspot.com/privacy-policy',
    es: 'HubSpot',
    en: 'HubSpot',
  },
  cookiePolicyLink: {
    href: '/politica-de-cookies',
    es: 'politica de cookies',
    en: 'cookie policy',
  },
  contactEmailLink: {
    href: 'mailto:hey@heycar.com',
    es: 'hey@heycar.com',
    en: 'hey@heycar.com',
  },
  privacyPolicyLink: {
    href: '/privacy',
    es: 'política de privacidad',
    en: 'privacy policy',
  },
  heycarWebsite: {
    href: 'https://www.heycar.com/',
    es: 'heycar.com',
    en: 'heycar.com',
  },
};

export const generateLinkTranslations = (
  locale = 'es',
): Record<string, ReactElement> =>
  locale
    ? Object.keys(linkTranslations).reduce((acc, item, index) => {
        acc[item] = (
          <a
            key={`${index}_${item}`}
            href={linkTranslations[item].href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkTranslations[item][locale]}
          </a>
        );
        return acc;
      }, {})
    : {};

export const translatedLinks = {
  en: generateLinkTranslations('en'),
  es: generateLinkTranslations('es'),
};

// TODO: icon-migration migrate gift box .svg
export const staticValues = {
  b: chunks => <BoldSt>{chunks}</BoldSt>,
  i: chunks => <ItalicSt>{chunks}</ItalicSt>,
  u: chunks => <UnderlineSt>{chunks}</UnderlineSt>,
  ul: chunks => <UnorderedListSt>{chunks}</UnorderedListSt>,
  li: chunks => <ListItemSt>{chunks}</ListItemSt>,
  p: chunks => <WrapperSt>{chunks}</WrapperSt>,
  heartIcon: <InlineIconSt fontSize={20} />,
  giftBoxIcon: (
    <ImageSt
      width={16}
      height={16}
      src="/assets/icons/icon-gift-box.svg"
      alt="giftbox icon"
    />
  ),
  googlePrivacyLink: (
    <Link href={'https://policies.google.com/privacy'} blank>
      datos
    </Link>
  ),
  googleTermsLink: (
    <Link href={'https://policies.google.com/terms'} blank>
      términos de Uso
    </Link>
  ),
  br: <br />,
};

export const useTranslate = () => {
  const { formatMessage, locale } = useIntl();
  const { isInspected } = useTranslateInspection();
  const links = translatedLinks[locale];

  return ({ id, defaultMessage, description, values }: IUseTranslateProps) => {
    const content = formatMessage(
      {
        id,
        defaultMessage: defaultMessage || id,
        description,
      },
      {
        ...staticValues,
        ...links,
        ...values,
      },
    );
    return isInspected ? (
      <InspectTranslationSt title={id}>{content}</InspectTranslationSt>
    ) : (
      content
    );
  };
};
