import { ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import get from 'lodash.get';
import { NextApiResponse } from 'next';
import { AppProps } from 'next/app';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { Hydrate, dehydrate } from 'react-query/hydration';

import { App } from 'app/App';
import intlDataQuery from 'app/apiCalls/intl.dataQuery';

import { guidCookieName } from '../next/server/middleware/guid';
import { prefetchAppData } from '../next/app/prefetchData';
import { translationsConfig } from '../next/config';
import client from '../next/app/client';
import { LocaleIntlData } from 'app/apiCalls/intl';
import { BASE_ROUTES } from 'app/hooks/useUrl/useUrl';

import '@hey-car/gds/index.css';

const queryCache = new QueryCache();

const LOCALE_CACHE_KEY = 'current-locale';
const USERID_CACHE_KEY = 'userId';

interface HeyAppProps extends AppProps {
  intl: LocaleIntlData;
  isPreview: boolean;
}

const HeyApp = ({
  Component,
  pageProps,
  intl,
  isPreview,
}: HeyAppProps): ReactElement => {
  const { locale, messages } = intl;

  useEffect(() => {
    client();
  }, []);

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Hydrate state={pageProps.dehydratedState}>
        <Helmet />
        <IntlProvider
          defaultLocale={locale}
          locale={locale}
          messages={messages}
        >
          <App statusCode={pageProps.statusCode} isPreview={isPreview}>
            <Component {...pageProps} />
          </App>
        </IntlProvider>
        <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
      </Hydrate>
    </ReactQueryCacheProvider>
  );
};

const handleErrorRedirect = (res: NextApiResponse) => {
  res.statusCode = 500;
  res.redirect(BASE_ROUTES.downtime);
};

HeyApp.getInitialProps = async ({ Component, ctx }) => {
  const { res, req, pathname } = ctx;

  const locale = get(req, 'locale', translationsConfig.defaultLocale);
  const userId = get(req, `cookies[${guidCookieName}]`);
  if (userId) {
    queryCache.setQueryData(USERID_CACHE_KEY, userId, { cacheTime: Infinity });
  }
  if (locale) {
    queryCache.setQueryData(LOCALE_CACHE_KEY, locale, { cacheTime: Infinity });
  }
  const isPreview = get(req, 'query.isContentfulPreview') === 'true';
  const cachedLocale: string = queryCache.getQueryData(LOCALE_CACHE_KEY);
  const cachedUserId: string = queryCache.getQueryData(USERID_CACHE_KEY);

  try {
    await prefetchAppData({
      locale: cachedLocale,
      isPreview,
      queryCache,
      userId: cachedUserId,
    });
  } catch (error) {
    // If one of our prefetchQueries mandatory for our app fails we will redirect
    // to the error page so the current page doesn't break
    // If we already redirected to the downtime page, skip redirection
    if (res && pathname !== BASE_ROUTES.downtime) {
      handleErrorRedirect(res);
    }
  }

  const intlData: { intl?: LocaleIntlData } = queryCache.getQueryData(
    intlDataQuery.name,
  );

  // Prepare default fallback value in case of an error
  let intl: LocaleIntlData = {
    locale: translationsConfig.defaultLocale,
    messages: {},
  };

  if (intlData) {
    intl = intlData.intl;
  } else if (res && pathname !== BASE_ROUTES.downtime) {
    // If the intl data didn't get loaded for some reason we should redirect to the error page
    // @todo: for some reason this `throwOnError` in the prefetchOptions isn't throwing
    // an error in the earlier try/catch block, we should look into this so we can have a bit
    // cleaner solution
    handleErrorRedirect(res);
  }

  const ctxWithExtras = {
    ...ctx,
    locale: cachedLocale,
    queryCache,
  };

  // Here we separate the component props so we can snoop/scoop
  // out global data such as error handling
  const componentProps = Component.getInitialProps
    ? await Component.getInitialProps(ctxWithExtras)
    : {};

  // Create the dehydrated state for the app
  const dehydratedState = dehydrate(queryCache);

  // Get the prefetched react-query state from the component
  const componentDehydratedState = get(componentProps, 'dehydratedState', {});
  // If we have queries in the cache, add these to the existing hydratedState queries
  if (componentDehydratedState.queries) {
    dehydratedState.queries.push(...componentDehydratedState.queries);
  }

  return {
    pageProps: {
      // Call page-level getInitialProps
      ...componentProps,
      // Some custom thing for all pages
      appProp: ctx.pathname,
      dehydratedState,
    },
    intl,
    isPreview,
  };
};

export default HeyApp;
