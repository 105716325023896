import { ReactElement, useMemo } from 'react';
import { createPortal } from 'react-dom';

interface IPortalProps {
  children: ReactElement;
  containerId: string;
}

const Portal = ({ children, containerId }: IPortalProps): ReactElement => {
  if (typeof window === 'undefined') {
    return <>{children}</>;
  }

  const container = useMemo(() => {
    let container = document.body.querySelector(`#${containerId}`);
    if (!container) {
      container = document.createElement('div');
      container.setAttribute('id', containerId);
      document.body.appendChild(container);
    }
    return container;
  }, [containerId]);

  return createPortal(children, container);
};

export default Portal;
