import { QueryCache, QueryConfig } from 'react-query';
import hash from 'object-hash';
import intlDataQuery from 'app/apiCalls/intl.dataQuery';
import footerDataQuery from 'app/providers/FooterProvider/FooterProvider.dataQuery';
import flagrDataQuery from 'app/providers/FeatureToggleProvider/FeatureToggleProvider.dataQuery';
import headerMenuDataQuery from 'app/providers/HeaderMenuProvider/HeaderMenuProvider.dataQuery';
import { ENVIRONMENT, ENV_TEST } from './config';
import campaignsDataQuery from 'app/providers/CampaignProvider/CampaignProvider.dataQuery';

export interface PrefetchAppData {
  locale: string;
  isPreview: boolean;
  userId: string;
}

const prefetchAppQueryHash = 'prefetch-app-data-hash';

export interface PrefetchOptions {
  force?: boolean;
  throwOnError?: boolean;
}

interface PrefetchAppDataProps extends PrefetchAppData {
  queryCache: QueryCache;
}

export interface DataQuery<TResult = unknown> {
  name: string;
  fn: (props: Partial<PrefetchAppData>) => TResult | Promise<TResult>;
  options: QueryConfig<TResult>;
  prefetchOptions?: PrefetchOptions;
}

const queriesToPrefetch: Partial<DataQuery>[] = [
  campaignsDataQuery,
  footerDataQuery,
  intlDataQuery,
  headerMenuDataQuery,
];

export const prefetchAppData = async ({
  locale,
  isPreview,
  userId,
  queryCache,
}: PrefetchAppDataProps): Promise<void> => {
  // Only add flagr data for non e2e environments
  if (ENVIRONMENT !== ENV_TEST) {
    queriesToPrefetch.push(flagrDataQuery);
  }

  const queryProps: PrefetchAppData = {
    locale,
    isPreview,
    userId,
  };

  // Here we check whether the prefetchAppData args have changed since the
  // last time we ran all these queries. If so we have to invalidate them
  // because otherwise the prefetch query will just ignore them.
  const queryHash = hash(queryProps);
  const prevQueryHash = queryCache.getQueryData(prefetchAppQueryHash);
  if (queryHash !== prevQueryHash) {
    queriesToPrefetch.forEach(query => {
      queryCache.invalidateQueries(query.name);
    });
  }

  // Here we run all the queries to prefetch, map them into a promise array
  // that we await for so the app has all the data it needs to run.
  const queryPromises = queriesToPrefetch.map(query =>
    queryCache.prefetchQuery(
      query.name,
      () => query.fn(queryProps),
      query.options,
      query.prefetchOptions || {},
    ),
  );

  queryCache.setQueryData(prefetchAppQueryHash, queryHash, {
    cacheTime: 600000,
  });

  await Promise.all(queryPromises);
};
